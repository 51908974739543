import React, { useContext, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, IconButton, Menu, Typography } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { Box } from '@mui/system'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import language from '../../../../language/language'
import Colors from '../../../../assets/Colors'
import { emptyBuildRouteStyles } from './EmptyBuildRouteStyles'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { useUsersByStore } from '../../../../queries/Users/useUsersByStore'
import { editRouteStyles } from '../EditRoute/editRouteStyles'
import { styled } from '@mui/material/styles'
import { InvoicesResponse } from '../../../../utilities/types/DeliveryRouteTypes'
import CreateRoutePreviewMap from '../CreateRoute/CreateRoutePreviewMap'
import { DeliveryRouteService } from '../../../../utilities/services/DeliveryRouteService'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'

type EmptyBuildRouteProps = {
    selectedDriverId: string
    setSelectedDriverId: (selectedDriverId: string) => void
    openEmptyModal: boolean
    setOpenEmptyModal: (value: boolean) => void
    setOpenAddInvoices: (value: boolean) => void
    setOpenCustomStop: (value: boolean) => void
    editAddInvoiceData: InvoicesResponse[]
    customStopDetails: any,
    setEditAddInvoiceData: any,
    setCustomStopDetails: any,
    setEditAddInvoices: any,
    refetchInvoices: any,
    handleCloseCustomModal: () => void,
    setOpenClearModal: (value: boolean) => void
}

const EmptyBuildRoute = ({
    selectedDriverId,
    setSelectedDriverId,
    openEmptyModal,
    setOpenEmptyModal,
    setOpenAddInvoices,
    setOpenCustomStop,
    editAddInvoiceData,
    customStopDetails,
    setEditAddInvoiceData,
    setCustomStopDetails,
    setEditAddInvoices,
    refetchInvoices,
    handleCloseCustomModal,
    setOpenClearModal
}: EmptyBuildRouteProps) => {
    const { data: drivers } = useUsersByStore()
    const { storeAddress, currentStore } = useContext(StoreContext)
    const { currentLanguage } = useContext(LanguageContext)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { addSnack } = useContext(SnackbarContext)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenu = () => {
        setAnchorEl(null)
    }

    const handleChangeDriver = (e: { target: { value: string } }) => {
        setSelectedDriverId(e.target.value)
    }

    const handleAddInvoice = () => {
        setOpenAddInvoices(true)
        handleMenu()
    }

    const handleCustomStop = () => {
        setOpenCustomStop(true)
        handleMenu()
    }

    const getDriverName = (deliveryDriver: string) => {
        const driver = drivers?.filter((driver) => driver.username === deliveryDriver)
        if (driver && driver.length > 0) {
          if (driver[0].givenName && driver[0].familyName) {
            const fullName = driver[0].givenName + ' ' + driver[0].familyName
            return fullName
          }
        }
        return ''
      }

    const selectDriver = () => {
        return (
            <Grid item xs={12} sx={[emptyBuildRouteStyles.driversFormGrid, { paddingLeft: 0 }]}>
                <FormControl sx={emptyBuildRouteStyles.driversForm}>
                    <Typography sx={emptyBuildRouteStyles.driverType}>
                        {language[currentLanguage].driver}*
                    </Typography>
                    <Select
                        id='drivers_list_invoice'
                        inputProps={{ 'data-testid': 'user-type-select' }}
                        displayEmpty
                        value={selectedDriverId}
                        required={true}
                        onChange={handleChangeDriver}
                        placeholder={language[currentLanguage].selectType}
                        style={{ maxHeight: '40px' }}>
                        <MenuItem value='None'>
                            <em>{language[currentLanguage].none}</em>
                        </MenuItem>
                        {drivers &&
                            drivers?.map((driver) => {
                                return (
                                    <MenuItem key={driver.username} value={driver.username}>
                                        <em>
                                            {driver.givenName} {driver.familyName}
                                        </em>
                                    </MenuItem>
                                )
                            })}
                    </Select>
                </FormControl>
            </Grid>
        )
    }

    const renderInvoiceAndPO = (item: any) => {
        return (
            <Grid container columns={{ xs: 12, md: 44 }}>
                <Grid item xs={12}>
                    <AllItemText sx={editRouteStyles.invoiceNumber}>{`${(language as any)[currentLanguage].invoiceNumber
                        }${item.invoiceNumber}`}</AllItemText>
                </Grid>
            </Grid>
        )
    }

    const ItemHeader = () => {
        return (
            <Box>
                <Grid container spacing={1} paddingBottom={0}>
                    <Grid item xs={3}>
                        <AllItemText sx={editRouteStyles.headerTitle}>
                            {(language as any)[currentLanguage].itemNumber}
                        </AllItemText>
                    </Grid>
                    <Grid item xs={3}>
                        <AllItemText sx={editRouteStyles.headerTitle}>
                            {(language as any)[currentLanguage].lineAbbreviation}
                        </AllItemText>
                    </Grid>
                    <Grid item xs={3}>
                        <AllItemText sx={editRouteStyles.headerTitle}>
                            {(language as any)[currentLanguage].description}
                        </AllItemText>
                    </Grid>
                    <Grid item xs={3}>
                        <AllItemText sx={editRouteStyles.headerTitle}>
                            {(language as any)[currentLanguage].quantity}
                        </AllItemText>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const Item = (item: any) => {
        return (
            <Box>
                <Grid container spacing={1} paddingBottom={1}>
                    <Grid item xs={3}>
                        <AllItemText sx={editRouteStyles.headerTitle}>{item.part.partNumber}</AllItemText>
                    </Grid>
                    <Grid item xs={3}>
                        <AllItemText sx={editRouteStyles.headerTitle}>{item.part.lineAbbreviation}</AllItemText>
                    </Grid>
                    <Grid item xs={3}>
                        <AllItemText sx={editRouteStyles.headerTitle}>{item.part.description}</AllItemText>
                    </Grid>
                    <Grid item xs={3}>
                        <AllItemText sx={editRouteStyles.headerTitle}>
                            {item.part.requestedQuantity}
                        </AllItemText>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const Notes = ({ notes }: any) => {
        return (
            <Box>
                <Grid container spacing={2} paddingBottom={2}>
                    <Grid item xs={12}>
                        <AllItemText sx={editRouteStyles.notesTitle}>
                            {(language as any)[currentLanguage].notes}:
                        </AllItemText>
                        <Typography sx={{
                            color: Colors.napaBlack,
                            fontSize: '12px',
                            width: '100%',
                            wordWrap: 'break-word'
                        }}>{notes}</Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const handleCreateRoute = async () => {
        const invoicess = editAddInvoiceData?.map(obj => obj.invoiceNumber)
        const request = {
            invoices: invoicess,
            storeNumber: currentStore,
            deliveryDriver: selectedDriverId,
            routeStatus: selectedDriverId === 'None' ? 'created': 'pending',
            customStops: customStopDetails
        }

        try {
            const response: any = await DeliveryRouteService.createRoute(request)
            addSnack({
                severity: 'success',
                message: `Route #${response.data.routeId}${(language as any)[currentLanguage].routeCreated
                    }`,
                action: null,
                duration: 3000
            })
            handleCloseCustomModal()
        } catch (e: any) {
            addSnack({
                severity: 'error',
                message: e.message.contains('Invoice status is')
                    ? language[currentLanguage].errorRouteCreationInvoiceOnHold
                    : language[currentLanguage].errorRouteCreation,
                duration: 3000,
                action: { prompt: 'REFRESH', callback: () => window.location.reload() }
            })
        }
        finally {
            refetchInvoices()
        }
    }

    const handleRemoveInvoice = (invoiceNumber: string) => {
        if (editAddInvoiceData?.length === 1 && customStopDetails?.length === 0) {
            setOpenEmptyModal(false)
            setOpenClearModal(true)
        }
        else
            setEditAddInvoiceData(editAddInvoiceData?.filter(obj => obj.invoiceNumber !== invoiceNumber))
    }

    const handleRemoveStop = (remove: string) => {
        if (editAddInvoiceData?.length === 0 && customStopDetails?.length === 1) {
            setOpenEmptyModal(false)
            setOpenClearModal(true)
        }
        else
            setCustomStopDetails(customStopDetails?.filter((obj: any) => obj.addressLine1 !== remove))
    }

    const renderInvoice = (invoiceData: any, invoiceType: string) => {
        return invoiceData?.map((invoice: any) => {
            return (
                <Accordion style={{ boxShadow: 'none', background: Colors.white }}>
                    <AccordionSummary
                        expandIcon={<GridArrowDownwardIcon style={{ cursor: 'pointer' }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={editRouteStyles.accordionSummary}
                    >
                        <Box style={editRouteStyles.renderInvoiceBox}>
                            <NameContainer sx={editRouteStyles.invoiceDeliveryAddress}>
                                {invoiceType === 'customStop' ? invoice.stopName : invoice.deliveryAddress?.name}
                            </NameContainer>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: '8px' }}>
                        <Box style={editRouteStyles.renderAddressBox}>
                            <AddressContainer sx={editRouteStyles.invoiceAddressLineType}>
                                {invoiceType === 'customStop' ? invoice.addressLine1 : invoice?.deliveryAddress?.addressLine1}
                            </AddressContainer>
                            {invoiceType !== 'customStop' ? (
                                <Box sx={editRouteStyles.removeButtonBox}>
                                    <Button
                                        sx={editRouteStyles.removeInvoiceType}
                                        onClick={() => handleRemoveInvoice(invoice.invoiceNumber)}>
                                        {(language as any)[currentLanguage].removeInvoice}
                                    </Button>
                                </Box>
                            ) : null}
                            {invoiceType === 'customStop' ? (
                                <Box sx={editRouteStyles.removeButtonBox}>
                                    <Button
                                        sx={editRouteStyles.removeInvoiceType}
                                        onClick={() => handleRemoveStop(invoice.addressLine1)}>
                                        Remove Stop
                                    </Button>
                                </Box>
                            ) : null}
                        </Box>
                        {invoiceType !== 'customStop' && renderInvoiceAndPO(invoice)}
                        {invoiceType !== 'customStop' && <ItemHeader />}
                        {invoiceType !== 'customStop' && invoice.parts?.map((part: any) => {
                            return (
                                <>
                                    <Item part={part} />
                                    {!!invoice.invoiceNotes && invoice.invoiceNotes.length > 0 &&
                                        <Notes notes={invoice.invoiceNotes.find((note: any) => note?.sequenceNumber === part?.sequenceNumber)?.actualNote || 'NA'} />}
                                    <Divider sx={{ marginRight: 1 }} />
                                </>
                            )
                        })}
                        {invoiceType === 'customStop' && <Notes notes={invoice.stopNotes} />}
                    </AccordionDetails>
                </Accordion>
            )
        })
    }

    return (
        <Dialog open={openEmptyModal} fullWidth={true} onClose={handleCloseCustomModal}>
            <Grid container sx={emptyBuildRouteStyles.marginT10}>
                <Grid item xs={11}>
                    <Typography sx={emptyBuildRouteStyles.buildRouteText}>
                        {language[currentLanguage].buildRouteText}
                    </Typography>
                </Grid>
                <Grid item xs={1} textAlign={'right'}>
                    <IconButton onClick={handleCloseCustomModal}>
                        <Clear fontSize='medium' />
                    </IconButton>
                </Grid>
            </Grid>
            <Typography sx={emptyBuildRouteStyles.driverText}>
                {`${language[currentLanguage].driver}: ${selectedDriverId !== 'None' ? getDriverName(selectedDriverId) : ''}`}
            </Typography>
            <Grid container sx={emptyBuildRouteStyles.paddingRL}>
                <Grid item xs={10}>
                    <Typography fontSize={'14px'} >
                        {`${language[currentLanguage].totalStops}  ${editAddInvoiceData?.length + customStopDetails?.length + 1}`}
                    </Typography>
                </Grid>
                <Grid item xs={2} textAlign={'right'}>
                    <>
                        <Button
                            variant='primary'
                            onClick={handleClick}
                            sx={emptyBuildRouteStyles.addStop}
                        >
                            {language[currentLanguage].addStop}
                            <KeyboardArrowDownSharpIcon sx={emptyBuildRouteStyles.downIcon} />
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenu}
                            style={emptyBuildRouteStyles.marginT5}
                        >
                            <MenuItem onClick={handleAddInvoice} sx={emptyBuildRouteStyles.invoiceText} style={{ paddingTop: '0px' }}>
                                <AddOutlinedIcon fontSize='small' sx={emptyBuildRouteStyles.marginR5} />
                                {language[currentLanguage].addInvoice}
                            </MenuItem>
                            <MenuItem onClick={handleCustomStop} sx={emptyBuildRouteStyles.invoiceText} style={{ paddingBottom: '0px' }}>
                                <EditOutlinedIcon fontSize='small' sx={emptyBuildRouteStyles.marginR5} />
                                {language[currentLanguage].customStop}
                            </MenuItem>
                        </Menu>
                    </>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={emptyBuildRouteStyles.RouteMapPreview}>
                <CreateRoutePreviewMap
                    routeDetails={null}
                    customDetails={customStopDetails}
                />
            </Grid>
            <CustomisedDialogContent style={{ overflowY: 'unset', marginTop: '-50px' }}>
                {editAddInvoiceData?.length > 0 ? (
                    <div style={editRouteStyles.renderInvoice}>
                        {renderInvoice(editAddInvoiceData, 'addInvoice')}
                    </div>
                ) : null}
                {customStopDetails?.length > 0 ? (
                    <div style={editRouteStyles.renderInvoice}>
                        {renderInvoice(customStopDetails, 'customStop')}
                    </div>
                ) : null}
            </CustomisedDialogContent>
            <DialogContent sx={emptyBuildRouteStyles.backToStore}>
                <Box sx={emptyBuildRouteStyles.returnStore}>
                    <Typography sx={emptyBuildRouteStyles.invoiceDeliveryAddress}>
                        {language[currentLanguage].returnToStore}
                    </Typography>
                    <DialogContentText sx={emptyBuildRouteStyles.invoiceAddressLineType}>
                        {storeAddress?.addressLine1}, {storeAddress?.addressLine2 ?? ''}{' '}
                        {storeAddress?.city}, {storeAddress?.state} {storeAddress?.zipCode}
                    </DialogContentText>
                </Box>
            </DialogContent>
            <Box sx={emptyBuildRouteStyles.buttonContainer}>
                {selectDriver()}
                <DialogActions sx={emptyBuildRouteStyles.dialogActions}>
                    <>
                        <Button
                            variant='secondary'
                            onClick={handleCloseCustomModal}
                            fullWidth
                        >
                            {language[currentLanguage].close}
                        </Button>
                        <Button
                            variant='primary'
                            disabled={editAddInvoiceData?.length === 0 && customStopDetails?.length === 0}
                            onClick={handleCreateRoute}
                            fullWidth
                        >
                            {language[currentLanguage].buildRouteText}
                        </Button>
                    </>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

const CustomisedDialogContent = styled(DialogContent)`
  color: green;
  border-width: 1px;
  border-color: red;
  border-width-color: blue;
`

const NameContainer = styled(DialogContentText)`
  font-size: 14px;
  color: blue;
`

const AddressContainer = styled(DialogContentText)`
  font-size: 14px;
`

const AllItemText = styled(DialogContentText)`
  font-size: 16px;
  margin-top: 12px;
`

export default EmptyBuildRoute
