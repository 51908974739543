import React, { useContext } from 'react'
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material'
import {
  AccountCircle,
  ChatBubbleOutline,
  Clear,
  DriveEtaOutlined,
  ExitToApp,
  HelpOutline,
  LocationOn
} from '@mui/icons-material'
import { AuthContext } from '../../utilities/contexts/AuthContext'
import NapaLogo from '../../assets/napa_logo.png'
import ApacLogo from '../../assets/apac_logo.png'
import returnLogo from '../../assets/digital_returns.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { navigationBarStyles as styles } from './navigationBarStyles'
import StoreSelector from '../StoreSelector/StoreSelector'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import Colors from '../../assets/Colors'
import LanguageIcon from '@mui/icons-material/Language'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SupportedLanguage } from '../../language/LanguageTypes'
import { isApac } from '../../utilities/helpers/RegionHelper'
import UserLogo from '../../assets/user_man.png'
import NSSAChat from '../../screens/CometChat/NSSAChat'
import { FlagTypes } from '../../utilities/types/FlagTypes'

type menuItem = {
  menuName: string | null | undefined
  icon: JSX.Element
  onClick?: (arg0: any) => void
}

const NavigationBar = () => {
  const { currentStore } = useContext(StoreContext)
  const { currentLanguage, changeLanguage } = useContext(LanguageContext)
  let navigate = useNavigate()
  const location = useLocation()
  const { isFrenchEnabled, isReturnsEnabled} =
    useFlags<FlagTypes>()
  const [anchorElStore, setAnchorElStore] = React.useState<null | HTMLElement>(null)
  const [anchorElAccount, setAnchorElAccount] = React.useState<null | HTMLElement>(null)
  const [anchorElLanguage, setAnchorElLanguage] = React.useState<null | HTMLElement>(null)
  const [anchorElMessage, setAnchorElMessage] = React.useState<null | HTMLElement>(null)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [dialogName, setDialogName] = React.useState('')
  const { currentUser, signOut } = useContext(AuthContext)
  const pages = [
    { name: `${(language as any)[currentLanguage].whiteboard}`, nav: '/Whiteboard' },
    { name: `${(language as any)[currentLanguage].routeBuilder}`, nav: '/RouteBuilder' },
    { name: `${(language as any)[currentLanguage].userManagement}`, nav: '/UserManagement' }
  ]
  const { isCompanyOwned } = useContext(StoreContext)
  const handleClose = () => setOpenDialog(false)

  const handleOpen = (event: any) => {
    setAnchorElAccount(null)
    setOpenDialog(true)
    setDialogName(event.target.innerText)
  }

  const handleMyFleet = () => {
    setAnchorElAccount(null)
    navigate('/MyFleet')
  }

  const handleDigitalReturns = () => {
    setAnchorElAccount(null)
    navigate('/DigitalReturns')
  }

  const handleLangChange = (value: SupportedLanguage) => {
    setAnchorElLanguage(null)
    changeLanguage(value)
  }

  const handleStoreSelectorOpen = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
  ) => {
    setAnchorElStore(event.currentTarget)
  }

  const handleAccountMenuOpen = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
  ) => {
    setAnchorElAccount(event.currentTarget)
  }

  const handleLanguageChange = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
  ) => {
    setAnchorElLanguage(event.currentTarget)
  }

  const handleCloseStore = () => {
    setAnchorElStore(null)
  }

  const handleCloseAccount = () => {
    setAnchorElAccount(null)
  }

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null)
  }

  const handleCustomerMgmt = () => {
    setAnchorElAccount(null)
    navigate("/CustomerMgmt")
  }

  const handleMessageMenuOpen = (
      event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
  ) => {
    setAnchorElMessage(event.currentTarget)
  }
  const handleCloseMessage = () => {
    setAnchorElMessage(null)
  }

  const MessageChange = () => {
    return (
        <Menu
            id="message-menu"
            anchorEl={anchorElMessage}
            open={Boolean(anchorElMessage)}
            onClose={handleCloseMessage}
            sx={styles.chatBox}
            MenuListProps={{
              'aria-labelledby': 'message-button'
            }}>
          <NSSAChat/>
        </Menu>
    )
  }


  const isSignOut = () => dialogName === `${(language as any)[currentLanguage].signOut}`

  const AccountMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorElAccount}
        open={Boolean(anchorElAccount)}
        onClose={handleCloseAccount}
        sx={styles.menuList}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={handleMyFleet}>
          <DriveEtaOutlined fontSize="medium" style={{ marginRight: 10 }} />
          <ListItemText>{(language as any)[currentLanguage].myFleet}</ListItemText>
        </MenuItem>
        <Divider variant="middle" />
        {isCompanyOwned && !isApac && (
          <>
            <MenuItem onClick={handleCustomerMgmt}>
              <img src={UserLogo} alt="" style={styles.navBarDigital} />
              <ListItemText>{(language as any)[currentLanguage].customerMgmt}</ListItemText>
            </MenuItem>
            <Divider variant="middle" />
          </>
        )}
        {isReturnsEnabled && (
          <>
            <MenuItem onClick={handleDigitalReturns}>
              <img src={returnLogo} alt="" style={styles.navBarDigital} />
              <ListItemText>Returns</ListItemText>
            </MenuItem>
            <Divider variant="middle" />
          </>
        )}
        <MenuItem onClick={handleOpen}>
          <HelpOutline fontSize="medium" style={{ marginRight: 10 }} />
          <ListItemText>{(language as any)[currentLanguage].storeSupport}</ListItemText>
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={handleOpen}>
          <ExitToApp fontSize="medium" style={{ marginRight: 10 }} />
          <ListItemText>{(language as any)[currentLanguage].signOut}</ListItemText>
        </MenuItem>
      </Menu>
    )
  }

  const LanguageChange = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        defaultValue={currentLanguage}
        onClose={handleCloseLanguage}
        sx={styles.menuList}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={() => handleLangChange('en')}>
          <ListItemText>English</ListItemText>
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem onClick={() => handleLangChange('fr')}>
          <ListItemText>French</ListItemText>
        </MenuItem>
      </Menu>
    )
  }

  const DialogMenu = () => {
    return (
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle sx={styles.titleWrapper}>
          <Typography sx={styles.dialogTitle}>
            {isSignOut()
              ? `${(language as any)[currentLanguage].signOut}`
              : `${(language as any)[currentLanguage].storeSupport}`}
          </Typography>
          <IconButton onClick={handleClose}>
            <Clear fontSize="medium" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          {isSignOut() ? (
            <DialogContentText>{(language as any)[currentLanguage].signOutDesc}</DialogContentText>
          ) : isApac ? (
            <DialogContentText>
              {(language as any)[currentLanguage].contactUsapac}
              <Box component="span" color={Colors.napaBlueLink}>
                {` 1300 550 085`}
              </Box>
            </DialogContentText>
          ) : (
            <DialogContentText>
              {(language as any)[currentLanguage].contactUs}
              <Box component="span" color={Colors.napaBlueLink}>
                NAPA_StoreSupport@genpt.com
              </Box>{'. '}
              {(language as any)[currentLanguage].urgentCalls}
              <Box component="span" color={Colors.napaBlueLink}>
                800-GPC-TAMS (800-472-8267)
              </Box>{'.'}
              <br></br>
              {(language as any)[currentLanguage].doordashContact}{' '}
              <Box component="span" color={Colors.napaBlueLink}>
                mxpsupport@doordash.com.
              </Box>{' '}
              {(language as any)[currentLanguage].ddUrgentCall}
              <Box component="span" color={Colors.napaBlueLink}>
                (855)-222-8111.
              </Box>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions sx={styles.dialogAction}>
          {isSignOut() ? (
            <React.Fragment>
              <Button variant="secondary" sx={styles.signOutDialogButtons} onClick={handleClose}>
                {(language as any)[currentLanguage].cancel}
              </Button>
              <Button variant="primary" sx={styles.signOutDialogButtons} onClick={signOut}>
                {(language as any)[currentLanguage].signOut}
              </Button>
            </React.Fragment>
          ) : (
            <Button sx={styles.doneButton} variant="primary" onClick={handleClose}>
              {(language as any)[currentLanguage].done}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }

  let menus = [
    {
      menuName: `${(language as any)[currentLanguage].storeNumber}${currentStore}`,
      icon: <LocationOn sx={styles.navBarMenuIcon} />,
      onClick: handleStoreSelectorOpen
    },
    {
      menuName: currentUser?.givenName || "",
      icon: <AccountCircle sx={styles.navBarMenuIcon} />,
      onClick: handleAccountMenuOpen
    }
  ]

  if(!isApac) {
    menus = [
      {
        menuName: "Chat",
        icon: <ChatBubbleOutline sx={styles.navBarMenuIcon} />,
        onClick: handleMessageMenuOpen
      }
    ].concat(menus)
  }

  const LanguageMenu = {
    menuName: '',
    icon: (
      <>
        <LanguageIcon sx={styles.navBarMenuIcon} />
        <KeyboardArrowDownSharpIcon sx={styles.navBarMenuIcon} />
      </>
    ),
    onClick: handleLanguageChange
  }

  let modifiedMenu = isFrenchEnabled ? [...menus, LanguageMenu] : menus
  if (
    !isApac
  ) {
    pages.push({ name: `${(language as any)[currentLanguage].reports}`, nav: '/Reports' })
  }

  return (
    <Box sx={styles.navBarContainer}>
      <AppBar component="nav" position="static">
        <Toolbar>
          <img
            src={isApac ? ApacLogo : NapaLogo}
            style={styles.navBarLogo}
            alt={isApac ? "GPC_logo" : "Napa_logo"}
            onClick={() => navigate('/')}
          />
          <Typography variant="h6" component="div" sx={styles.navbarTitleStyle}>
            {isApac ? (language as any)[currentLanguage].genuineDelivery : 'PROTracker'}
          </Typography>
          <ButtonGroup sx={styles.navBarMenuGroup} variant="text" disableRipple disableElevation>
            {pages.map((item, idx) => (
              <React.Fragment key={idx}>
                {idx ? (
                  <Divider
                    style={{ height: 8, alignSelf: 'center' }}
                    orientation="vertical"
                    color={Colors.napaBlue6}
                  />
                ) : null}
                <Link component="button" key={idx} onClick={() => navigate(item.nav)}>
                  <Typography
                    sx={{
                      ...styles.navbarButtonStyle,
                      ...((location.pathname === item.nav ||
                        (location.pathname === '/' && item.nav === '/Whiteboard')) &&
                        styles.navBarActiveButton)
                    }}>
                    {item.name}
                  </Typography>
                </Link>
              </React.Fragment>
            ))}
            {modifiedMenu.map((menu: menuItem, idx) => (
              <React.Fragment key={idx}>
                <Divider
                  style={{ height: 8, alignSelf: 'center' }}
                  orientation="vertical"
                  color={Colors.napaBlue6}
                />
                <Button
                  sx={{ ...styles.navbarButtonStyle, fontWeight: 'bold', textTransform: 'none' }}
                  startIcon={menu.icon}
                  onClick={menu.onClick}>
                  {menu.menuName}
                </Button>
              </React.Fragment>
            ))}
          </ButtonGroup>
          <StoreSelector anchorEl={anchorElStore} handleClose={handleCloseStore} />
          <AccountMenu />
          <DialogMenu />
          <MessageChange/>
          <LanguageChange />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default NavigationBar
