import axios from 'axios'
import { isApac } from '../helpers/RegionHelper'

type getMapRouteCoordinatesRequest = {
  coordinates: string
}

export class RouteBuilderService {
  static MAPBOX_ROUTES_DIRECTION_ENDPOINT = 'https://api.mapbox.com/directions/v5'
  static MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''
  static STORE_LOCATION_ENDPOINT = process.env.REACT_APP_DELIVERYAPI_URL + '/napaStores'
  static MAPBOX_GEOCODING_ENDPOINT = 'https://api.mapbox.com/geocoding/v5'

  static async getMapRouteCoordinates(request: getMapRouteCoordinatesRequest) {
    try {
      const response = await axios.get(
        `${this.MAPBOX_ROUTES_DIRECTION_ENDPOINT}/mapbox/driving/${request.coordinates}?steps=true&geometries=geojson&access_token=${this.MAPBOX_ACCESS_TOKEN}`
      )
      if (response.status === 200) {
        return response.data
      }
      return Promise.reject(`[${response.status}]: ${response.data}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getGeocodingAddress(query: string) {
    const country = isApac ? 'AU' : 'US'
    try {
      const response = await axios.get(`${this.MAPBOX_GEOCODING_ENDPOINT}/mapbox.places/${encodeURIComponent(query)}.json?country=${country}&access_token=${this.MAPBOX_ACCESS_TOKEN}&autocomplete=true`)
      if (response.status === 200) {
        return response.data
      }
      return Promise.reject(`[${response.status}]: ${response.data}`)
    }
    catch (e) {
      console.error("Error fetching geocoding suggestions:", e)
      return Promise.reject(e)
    }
  }
}
