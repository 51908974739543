import Colors from '../../../../assets/Colors'

const addCustomStopStyles = {
  modalTitle: {
    height: '32px',
    fontFamily: 'NAPA SANS',
    fontWeight: '700',
    fontSize: '25px',
    lineHeight: '32px',
    letterSpacing: '0.4px',
    color: Colors.napaBlue
  },
  customStopModalContainer: {
    width: '480px',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: Colors.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px'
  },
  customStopModal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  customStopTextField: {
    width: '100%',
    marginBottom: '10px'
  },
  customStopLabel: {
    fontWeight: '400',
    fontSize: '14px',
    marginBottom: '5px',
    color: Colors.black,
    marginTop: '5px'
  },
  phoneNumberField: {
    border: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',
    width: '100%',
    padding: '12px 0',
    textAlign: 'center',
    svg: {
      height: '20px'
    }
  },
  newAddress: {
    padding: '8px 20px',
    width: '50%',
    backgroundColor: Colors.white,
    border: 'none',
    textTransform: 'capitalize',
    fontSize: '16px',
    fontWeight: '700'
  },
  closeButton: {
    width: '100%',
    textTransform: 'capitalize',
    marginTop: '5px'
  },
  mapboxResponse: {
    position: 'absolute',
    zIndex: 2000,
    backgroundColor: Colors.white,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    maxHeight: '200px',
    overflowY: 'auto',
    margin: '0 16px 0 0'
  },
  addressContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: Colors.napaGreyDisabled,
    padding: '10px 20px'
  },
  helperText: {
    textAlign: 'right'
  }
}

export default addCustomStopStyles
