import NapaLogo from '../../assets/napa_logo.png'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import { LandingStyles } from './LandingStyles'
import { Button } from '@mui/material'
import LanguageChange from '../../components/LanguageChange/LanguageChange'
import { getAuthCode } from '../../utilities/services/PingAuthService/PingAuthService'
import { setCookie, clearCustomCookies } from '../../utilities/helpers/CookieHelper'

const Landing = () => {
  const navigate = useNavigate()
  const { currentLanguage } = useContext(LanguageContext)

  const handleCos = () => {
    setCookie('isCOS', 'true')
    getAuthCode()
  }

  const handleIso = () => {
    navigate('/SignIn')
    clearCustomCookies()
  }

  return (
    <div>
      <LanguageChange />
      <div style={LandingStyles.main}>
        <div style={LandingStyles.content}>
          <img src={NapaLogo} alt="NAPA Logo" style={LandingStyles.logo} />
          <h1 style={LandingStyles.brand}>PROTracker</h1>
          <div>
            <Button variant="primary" data-testid="cos-btn" onClick={handleCos}>
              {(language as any)[currentLanguage].cos}
            </Button>
          </div>
          <div>
            <Button sx={{ mt: 2 }} variant="primary" data-testid="iso-btn" onClick={handleIso}>
              {(language as any)[currentLanguage].iso}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
