import { DeliveryItem, Invoice } from '../types/DeliveryRouteTypes'

export const getItemsList = (invoices: Invoice[]): DeliveryItem[] => {
  const deliveryItems: DeliveryItem[] = []
  !!invoices && invoices.length > 0 && invoices.forEach((invoice) => {
    !!invoice.parts && invoice.parts.length > 0 && invoice.parts.forEach((part) => {
      if(part.partNumber && part.lineAbbreviation && part.requestedQuantity) {
        const deliveryItem: DeliveryItem = {
          partNumber: part.partNumber,
          productLine: part.lineAbbreviation,
          partDescription: part.description,
          quantity: part.requestedQuantity
        }
        deliveryItems.push(deliveryItem)
      }
    })
  })
  return deliveryItems;
}