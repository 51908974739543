import React, { useContext, useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { AppUser } from '../../utilities/services/UserManagementService'
import userManagementStyles from './userManagementStyles'
import DriverList from './components/DriverList/DriverList'
import UserDetailsCard from './components/UserDetails/UserDetailsCard'
import AddDriver from './components/AddDriver/AddDriver'
import { AuthContext } from '../../utilities/contexts/AuthContext'
import { useUsersByStore } from '../../queries/Users/useUsersByStore'
import DisableUser from './components/UserDetails/DisableUser/DisableUser'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import { isApac } from '../../utilities/helpers/RegionHelper'

const UserManagement = () => {
  const { currentUser } = useContext(AuthContext)
  const { currentLanguage } = useContext(LanguageContext)
  const { data: storeUsers, isLoading: loadingStoreUsers } = useUsersByStore()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openImportModal, setOpenImportModal] = useState<boolean>(false)
  const [selectedDriver, setSelectedDriver] = useState<AppUser | null>(null)
  const [openDisableUserModal, setDisableUserModal] = useState<boolean>(false)
  const [openEnableUserModal, setEnableUserModal] = useState<boolean>(false)

  useEffect(() => {
    setSelectedDriver(null)
  }, [loadingStoreUsers])

  useEffect(() => {
    if (storeUsers?.length) setSelectedDriver(storeUsers.filter(user => user.userType !== 'superuser')[0])
  }, [storeUsers])

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleUserImport = () => {
    setOpenImportModal(true)
  }

  return (
    <Grid container spacing={3} padding={2}>
      <Grid item container xs={12} style={userManagementStyles.gridContainer}>
        <Grid>
          <Typography style={userManagementStyles.header}>{(language as any)[currentLanguage].myDrivers}</Typography>
        </Grid>
        <Grid sx={userManagementStyles.addImportButtonContainer}>
          {currentUser?.userType === 'superuser' ?
            <Button
              sx={{ marginX: '5px', maxWidth: '50%' }}
              variant={'secondary'}
              onClick={handleUserImport}>
              {(language as any)[currentLanguage].import}
            </Button>
            : null}
          {!isApac && <Button
            sx={{ marginX: '5px', maxWidth: '50%' }}
            variant={'primary'}
            onClick={handleOpen}
          >
            {(language as any)[currentLanguage].addUser}
          </Button>}
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <DriverList
          drivers={storeUsers || []}
          setSelectedDriver={setSelectedDriver}
          loading={loadingStoreUsers}
        />
      </Grid>
      <Grid item xs={4}>
        <AddDriver
          openModal={openModal}
          setOpenModal={setOpenModal}
          openImportModal={openImportModal}
          setOpenImportModal={setOpenImportModal}
        />
        {selectedDriver ? <UserDetailsCard selectedDriver={selectedDriver} setDisableUserModal={setDisableUserModal} setEnableUserModal={setEnableUserModal} /> : null}
        <DisableUser selectedDriver={selectedDriver}  openDisable={openDisableUserModal} setOpenDisable={setDisableUserModal} openEnable={openEnableUserModal} setOpenEnable={setEnableUserModal} />
      </Grid>
    </Grid>
  )
}

export default UserManagement
