import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ArrowBack, Clear, StoreMallDirectoryOutlined } from '@mui/icons-material'
import { Box } from '@mui/system'
import React, { useContext, useState } from 'react'
import { styled } from '@mui/material/styles'
import { externalDeliveryStyles } from './externalDeliveryStyles'
import doordash_image from '../../../../assets/doordash_modal.png'
import { getStringToTime } from '../../../../utilities/helpers/DateTimeFormatters'
import { DeliveryRouteService } from '../../../../utilities/services/DeliveryRouteService'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import Colors from '../../../../assets/Colors'

const ExternalDelivery = ({
  handleBackArrow,
  handleClose,
  setOpenCancelRoute,
  routeDetails,
  createQuoteResponse,
  ItemHeader,
  Item
}: any) => {
  const [pickupNote, setPickupNote] = useState<string>('')
  const currentDropoffNotes = (routeDetails?.stops?.at(0)?.stopType === 'store_transfer') ? (routeDetails?.stops?.at(1)?.stopNotes || '') : (routeDetails?.stops?.at(0)?.invoices?.at(0)?.customerDeliveryNotes || '')
  const [deliveryNote, setDeliveryNote] = useState<string>(currentDropoffNotes)
  const [disableThirdParty, setDisableThirdParty] = useState<boolean>(false)
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)

  const handleAcceptQuote = async (pickupNote: string, deliveryNote: string) => {
    setDisableThirdParty(true)
    let invoiceNumbers = routeDetails?.stops[0]?.invoices?.map((invoice: any) => invoice.invoiceNumber)
    const external_delivery_id = `${createQuoteResponse.routeId}`
    const request = {
      pickupInstructions: pickupNote,
      dropoffInstructions: deliveryNote,
      invoiceNumbers: invoiceNumbers ?? null,
      storeNumber: routeDetails?.stops?.at(0)?.storeNumber || routeDetails?.storeNumber,
      routeId: routeDetails?.routeId
    }
    try {
      const response: any = await DeliveryRouteService.acceptExternalDelivery(
        request,
        external_delivery_id
      )
      addSnack({
        severity: 'success',
        message: `Route# ${response?.data?.routeId}${language[currentLanguage].routeCreated}`,
        action: null,
        duration: 3000
      })
      handleClose()
      setDisableThirdParty(false)
    } catch (e: any) {
      var mes = ''
      if (e.message.includes('duplicate_delivery_id')) mes = language[currentLanguage].duplicateDoordash
      addSnack({
        severity: 'error',
        message: mes ? mes : language[currentLanguage].errorRouteCreation,
        duration: 3000,
      })
      setDisableThirdParty(false)
    }
  }

  const isDirectStoreTransfer = () => routeDetails?.stops?.some((stop: any) => stop?.stopType === 'store_transfer')

  const trackYourOrder = () => {
    window.open(routeDetails?.externalDelivery.trackingUrl, '_blank');
  };

  const renderInvoiceCards = (invoiceData: any) => {
    return invoiceData?.map((invoice: any) => {
      return (
        <>
          <Box style={externalDeliveryStyles.renderInvoiceBox}>
            <NameContainer sx={externalDeliveryStyles.invoiceDeliveryAddress}>
              {invoice.deliveryAddress.name}
            </NameContainer>
            <Box></Box>
          </Box>
          <AddressContainer sx={externalDeliveryStyles.invoiceAddressLineType}>
            {invoice?.deliveryAddress?.addressLine1}
          </AddressContainer>
          <Grid container columns={{ xs: 12, md: 44 }}>
            <Grid item xs={12}>
              <AllItemText
                sx={
                  externalDeliveryStyles.invoiceNumber
                }>{`${language[currentLanguage].invoiceNumber}${invoice.invoiceNumber}`}</AllItemText>
            </Grid>
          </Grid>
          <ItemHeader />
          {invoice.parts?.map((part: any) => {
            return (
              <>
                <Item part={part} />
                <Divider sx={{ marginRight: 2 }} />
              </>
            )
          })}
        </>
      )
    })
  }

  const renderPickupDropoff = (stop: any, isSource: boolean) => {
    return (
      <>
        <Box sx={externalDeliveryStyles.storeContainer}>
          <>
            {isSource && <NameContainer sx={externalDeliveryStyles.invoiceDeliveryAddress}>
              {<StoreMallDirectoryOutlined fontSize='small' style={{ verticalAlign: 'middle' }} />}{' '}{language[currentLanguage].source}
            </NameContainer>}
            <NameContainer sx={externalDeliveryStyles.invoiceDeliveryAddress} marginLeft={'25px'}>
              {stop.customStopName || createQuoteResponse?.pickupBusinessName}
            </NameContainer>
          </>
        </Box>
        <Box sx={externalDeliveryStyles.storePadding}>
          <AddressContainer sx={externalDeliveryStyles.invoiceAddressLineType}>
            {stop.addressLine1}
          </AddressContainer>
          <AddressContainer sx={externalDeliveryStyles.invoiceAddressLineType}>
            {`${stop.city}, ${stop.state}, ${stop.zipcode}`}
          </AddressContainer>
        </Box>
      </>
    )
  }

  return (
    <>
      <DialogTitle sx={externalDeliveryStyles.dialogTitle}>
        {createQuoteResponse && (
          <ArrowBack onClick={() => handleBackArrow()} cursor={'pointer'}>
            <Clear fontSize="medium" />
          </ArrowBack>
        )}
        <Typography sx={externalDeliveryStyles.routeReviewTypography}>
          {language[currentLanguage].thirdParty}
        </Typography>
        {createQuoteResponse && (
          <Button
            onClick={() => {
              setOpenCancelRoute(true)
              handleClose()
            }}
            sx={externalDeliveryStyles.editButton}>
            {language[currentLanguage].cancelRoute}
          </Button>
        )}
      </DialogTitle>
      <CustomizedTitle sx={externalDeliveryStyles.paddingZero}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box>
            <img
              src={doordash_image}
              alt={'doordash'}
              style={{
                width: '124px',
                height: '24px'
              }}
            />
            <DialogContentText sx={[externalDeliveryStyles.thirdPartyETA, externalDeliveryStyles.blackText]}>
              {language[currentLanguage].totalStops} 1
            </DialogContentText>
            <DialogContentText sx={[externalDeliveryStyles.thirdPartyETA, externalDeliveryStyles.blackText]}>
              {language[currentLanguage][(routeDetails?.routeStatus === 'created' || routeDetails?.routeStatus === 'pending') ? 'estimatedPickUp' : 'pickedUp']}
              {getStringToTime(routeDetails?.departureTimestamp || routeDetails?.stops?.at(0)?.departureTimestamp || routeDetails?.externalDelivery?.pickupTimeEstimated || createQuoteResponse?.pickupTimeEstimated)}
            </DialogContentText>
            <DialogContentText sx={[externalDeliveryStyles.thirdPartyETA, (routeDetails?.routeStatus === 'complete' ? externalDeliveryStyles.greenText : externalDeliveryStyles.blackText)]}>
              {language[currentLanguage][routeDetails?.routeStatus === 'complete' ? 'delivered' : 'estimatedDelivered']}
              {getStringToTime(routeDetails?.stops?.at(0)?.deliveryTimestamp || routeDetails?.stops?.at(1)?.deliveryTimestamp || routeDetails?.externalDelivery?.dropoffTimeEstimated || createQuoteResponse?.dropoffTimeEstimated)}
            </DialogContentText>
            {routeDetails?.routeStatus !== 'created' && routeDetails?.routeStatus !== 'pending' && (
              <Box>
                <DialogContentText sx={[externalDeliveryStyles.thirdPartyETA, externalDeliveryStyles.blackText]}>
                  {language[currentLanguage].doordashDriver} {routeDetails?.externalDelivery?.driverName}
                </DialogContentText>
                <DialogContentText sx={[externalDeliveryStyles.thirdPartyETA, externalDeliveryStyles.blackText]}>
                  {language[currentLanguage].doordashPhoneNumber} {routeDetails?.externalDelivery?.driverPhoneNumber}
                </DialogContentText>
              </Box>
            )}
            <DialogContentText sx={externalDeliveryStyles.costText}>
              {language[currentLanguage].deliveryCost} $
              {routeDetails?.externalDelivery?.fee || createQuoteResponse?.fee * 0.01}
            </DialogContentText>
          </Box>
          {!createQuoteResponse && routeDetails?.externalDelivery?.vendorStatus === 'created' && (
            <Box>
              <Button onClick={() => {
                handleClose()
                setOpenCancelRoute(true)
              }}
                sx={externalDeliveryStyles.editButton}>
                {language[currentLanguage].cancelDoordash}
              </Button>
            </Box>
          )}
        </Box>
      </CustomizedTitle>
      <CustomisedDialogContent>
        {!isDirectStoreTransfer() && routeDetails?.stops?.map((stop: any, stopIndex: number) => {
          return (
            <>
              <div style={externalDeliveryStyles.renderInvoice}>
                {renderInvoiceCards(stop.invoices)}
              </div>
            </>
          )
        })}
        {isDirectStoreTransfer() && routeDetails?.stops?.map((stop: any, stopIndex: number) => {
          return (
            <>
              <div style={externalDeliveryStyles.renderInvoice}>
                {renderPickupDropoff(stop, stopIndex === 0)}
              </div>
            </>
          )
        })}
        {routeDetails?.routeStatus === 'complete' &&
          <>
            <Box
              sx={externalDeliveryStyles.signatureContainer}>
              <Box>
                <Typography
                  sx={externalDeliveryStyles.printNameHeader}>
                  {language[currentLanguage].print}*
                </Typography>
                <Typography
                  sx={externalDeliveryStyles.printName}>
                  {!!routeDetails?.stops[0]?.signatureCaptureName ? routeDetails.stops[0].signatureCaptureName : (!!routeDetails?.stops[1]?.signatureCaptureName ? routeDetails.stops[1].signatureCaptureName : "Doordash")}
                </Typography>
              </Box>
              <Box>
                <Typography sx={externalDeliveryStyles.signatureHeader}>
                  {language[currentLanguage].signature}
                </Typography>
                {!!routeDetails?.stops?.at(0)?.signatureCaptureImage || !!routeDetails?.stops?.at(1)?.signatureCaptureImage
                  ? <img
                    style={externalDeliveryStyles.signature}
                    alt={'Delivery Signature'}
                    src={routeDetails.stops[0].signatureCaptureImage || routeDetails.stops[1].signatureCaptureImage}
                  />
                  : <Typography sx={externalDeliveryStyles.signatureText}>NA</Typography>
                }
              </Box>
            </Box>
          </>
        }
      </CustomisedDialogContent>
      <DialogContentText>
        <Box marginX={'24px'} marginBottom={'24px'}>
          <DialogContentText sx={[externalDeliveryStyles.thirdPartyETA, externalDeliveryStyles.blackText]}>
            {language[currentLanguage].deliveryHandling}
          </DialogContentText>
          <TextField
            sx={externalDeliveryStyles.pickupInstructions}
            inputProps={{ 'data-testid': 'pickupNote' }}
            type="text"
            placeholder={language[currentLanguage].pickNote}
            disabled={routeDetails?.routeStatus !== 'created'}
            value={routeDetails?.externalDelivery?.pickupInstructions || pickupNote}
            onChange={(pickupNote) => setPickupNote(pickupNote.target.value)}
          />
          <TextField
            sx={externalDeliveryStyles.deliveryInstructions}
            inputProps={{ 'data-testid': 'dropoffNote' }}
            type="text"
            placeholder={language[currentLanguage].deliveryNote}
            disabled={routeDetails?.routeStatus !== 'created'}
            value={routeDetails?.externalDelivery?.dropoffInstructions || deliveryNote}
            onChange={(deliveryNote) => setDeliveryNote(deliveryNote.target.value)}
          />
        </Box>
      </DialogContentText>
      <Box marginX={'24px'} paddingBottom={0}>
        {(routeDetails?.routeStatus === 'complete' || routeDetails?.routeStatus === 'pending'
          || routeDetails?.routeStatus === 'in-progress'
          || routeDetails?.externalDelivery?.trackingUrl === null
          || routeDetails?.externalDelivery?.trackingUrl === '')
          && routeDetails?.externalDelivery?.vendorStatus !== 'DASHER_PICKED_UP'
          && routeDetails?.externalDelivery?.vendorStatus !== 'DASHER_DROPPED_OFF'
          && routeDetails?.externalDelivery?.vendorStatus !== 'DASHER_CONFIRMED_DROPOFF_ARRIVAL'
          && (
            <DialogActions sx={externalDeliveryStyles.externalDialogbuttons}>
              <Button
                variant="primary"
                data-testid={'close-button'}
                onClick={() => handleClose()}
                sx={{ width: '100%' }}>
                {language[currentLanguage].close}
              </Button>
            </DialogActions>
          )}
        {(routeDetails?.routeStatus === 'complete' || routeDetails?.routeStatus === 'pending' || routeDetails?.routeStatus === 'in-progress'
        ) && (routeDetails?.externalDelivery?.vendorStatus === 'DASHER_PICKED_UP'
          || routeDetails?.externalDelivery?.vendorStatus === 'DASHER_DROPPED_OFF'
          || routeDetails?.externalDelivery?.vendorStatus === 'DASHER_CONFIRMED_DROPOFF_ARRIVAL')
          && (routeDetails?.externalDelivery?.trackingUrl === null || routeDetails?.externalDelivery?.trackingUrl === '')
          && (
            <DialogActions sx={externalDeliveryStyles.externalDialogbuttons}>
              <Button
                variant="primary"
                data-testid={'close-button'}
                onClick={() => handleClose()}
                sx={{ width: '100%' }}>
                {language[currentLanguage].close}
              </Button>
            </DialogActions>
          )}
        {(routeDetails?.routeStatus === 'complete' || routeDetails?.routeStatus === 'pending' || routeDetails?.routeStatus === 'in-progress')
          && (routeDetails?.externalDelivery?.vendorStatus === 'DASHER_PICKED_UP'
            || routeDetails?.externalDelivery?.vendorStatus === 'DASHER_CONFIRMED_DROPOFF_ARRIVAL'
            || routeDetails?.externalDelivery?.vendorStatus === 'DASHER_DROPPED_OFF')
          && (routeDetails?.externalDelivery?.trackingUrl !== null && routeDetails?.externalDelivery?.trackingUrl !== '') && (
            <DialogActions sx={externalDeliveryStyles.externalDialogbuttons}>
              <Button
                variant="secondary"
                data-testid={'close-button'}
                onClick={() => handleClose()}
                sx={{ width: '50%' }}>
                {language[currentLanguage].close}
              </Button>

              <Button
                variant="primary"
                data-testid="track-order-button"
                onClick={trackYourOrder}
                sx={{ width: '50%' }}>
                {language[currentLanguage].trackMyOrder}
              </Button>

            </DialogActions>
          )}
        {createQuoteResponse && (
          <DialogActions sx={externalDeliveryStyles.externalDialogbuttons}>
            <Button
              variant="secondary"
              onClick={() => handleBackArrow()}
              data-testid={'cancel-button'}
              sx={{ width: '50%' }}>
              {language[currentLanguage].back}
            </Button>
            <Button
              variant="primary"
              data-testid={'accept-quote'}
              disabled={disableThirdParty}
              onClick={() => handleAcceptQuote(pickupNote, deliveryNote)}
              sx={{ width: '50%', paddingLeft: 0 }}>
              {language[currentLanguage].acceptQuote}
            </Button>
          </DialogActions>
        )}
      </Box>
      <DialogContent>
        <DialogContentText style={{ fontSize: '12px', textAlign: 'center' }}>
          {language[currentLanguage].doordashContact} <Box component="span" color={Colors.napaBlueLink}>mxpsupport@doordash.com.</Box> {language[currentLanguage].ddUrgentCall}
          <Box component="span" color={Colors.napaBlueLink}>(855)-222-8111.</Box>
        </DialogContentText>
      </DialogContent>
    </>
  )
}

const CustomizedTitle = styled(DialogTitle)`
  color: blue;
  display: flex;
  justify-content: space-between;
`

const CustomisedDialogContent = styled(DialogContent)`
  color: green;
  border-width: 1px;
  border-color: red;
  border-width-color: blue;
`
const NameContainer = styled(DialogContentText)`
  font-size: 14px;
  color: blue;
`

const AddressContainer = styled(DialogContentText)`
  font-size: 14px;
`

const AllItemText = styled(DialogContentText)`
  font-size: 16px;
  margin-top: 12px;
`


export default ExternalDelivery
