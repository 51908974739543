import React, { useContext } from 'react'
import language from '../../../../language/language'
import { AutoDispatchDetailsStyles } from './AutoDispatchDetailsStyles'
import styled from '@emotion/styled'
import { Box, DialogContentText, Grid, Typography } from '@mui/material'
import { InvoicesResponse } from '../../../../utilities/types/DeliveryRouteTypes'
import { FiberManualRecord } from '@mui/icons-material'
import {
  convertEpochToTimezoneFormat,
  msToTime
} from '../../../../utilities/helpers/DateTimeFormatters'
import { AutoDispatchInfo } from '../AutoDispatchInfo/AutoDispatchInfo'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { ReactComponent as PinIcon } from '../../../../assets/pin.svg'
import { ReactComponent as NotesIcon } from '../../../../assets/notes.svg'
import { isApac } from '../../../../utilities/helpers/RegionHelper'
import DeliveryLocationSelect from '../../../../components/DeliveryLocationSelect/DeliveryLocationSelect'
import { UseQueryResult } from 'react-query'
import { CustomerResponse } from '../../../../utilities/services/CustomerService/CustomerService'

type InvoiceDetailsBodyProps = {
  invoiceDetails: InvoicesResponse | null
  serviceLevel: string
  invoiceDateTime: number
  autoDispatch: boolean | undefined
  timeElapsed: number
  lookupResults: UseQueryResult<CustomerResponse, any>[]
  refetchInvoices: () => {}
}
export const AutoDispatchDetails = ({
  invoiceDetails,
  serviceLevel,
  invoiceDateTime,
  autoDispatch,
  timeElapsed,
  lookupResults,
  refetchInvoices
}: InvoiceDetailsBodyProps) => {
  const { currentLanguage } = useContext(LanguageContext)
  const {autoDispatchEnabled } = useContext(StoreContext);
  const { isCompanyOwned } = useContext(StoreContext)

  const getColorBasedOnServiceLevel = (serviceLevel: string | number) => {
    let colorCode = '#ADADAD'
    switch (true) {
      case Number(serviceLevel) <= 30:
        colorCode = '#D6D6D6'
        break
      case Number(serviceLevel) > 30 && Number(serviceLevel) <= 45:
        colorCode = '#FFE395'
        break
      case Number(serviceLevel) > 45 && Number(serviceLevel) <= 60:
        colorCode = '#ADADAD'
        break        
      case serviceLevel === 'PLATINUM':
        colorCode = '#D6D6D6'
        break
      case serviceLevel === 'GOLD':
        colorCode = '#FFE395'
        break
      case serviceLevel === 'SILVER':
        colorCode = '#ADADAD'
        break
      case serviceLevel === 'BRONZE':
        colorCode = '#E49600'
        break
      case serviceLevel === 'AUTODASH':
        colorCode = '#fe5600'
        break
    }
    return colorCode
  }

  const ItemHeader = () => {
    return (
      <Box>
        <Grid container spacing={1} paddingBottom={0}>
          <Grid item xs={2}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>Part #</AllItemText>
          </Grid>
          <Grid item xs={2}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>
              {(language as any)[currentLanguage].lineAbbreviation}
            </AllItemText>
          </Grid>
          <Grid item xs={4}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>
              {(language as any)[currentLanguage].description}
            </AllItemText>
          </Grid>
          <Grid item xs={1}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>
              {(language as any)[currentLanguage].quantity}
            </AllItemText>
          </Grid>
          <Grid item xs={2}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>Price</AllItemText>
          </Grid>
          <Grid item xs={1}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>Total</AllItemText>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const PartDetails = (item: any) => {
    return (
      <Box>
        <Grid container spacing={1} paddingBottom={1}>
          <Grid item xs={2}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>{item.part.partNumber}</AllItemText>
          </Grid>
          <Grid item xs={2}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>
              {item.part.lineAbbreviation}
            </AllItemText>
          </Grid>
          <Grid item xs={4}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>{item.part.description}</AllItemText>
          </Grid>
          <Grid item xs={1}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>
              {item.part.requestedQuantity}
            </AllItemText>
          </Grid>
          <Grid item xs={2}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>${item.part.price}</AllItemText>
          </Grid>
          <Grid item xs={1}>
            <AllItemText sx={AutoDispatchDetailsStyles.headerTitle}>${item.part.total}</AllItemText>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const NoteDetails = ({ notes }: any) => {
    return (
      <Box style={AutoDispatchDetailsStyles.renderAddressBox}>
        <Typography sx={AutoDispatchDetailsStyles.notesTitle}>
          {(language as any)[currentLanguage].notes}:
        </Typography>
        <Typography sx={AutoDispatchDetailsStyles.notesContent}>{notes}</Typography>
      </Box>
    )
  }

  return (
    <>
      <Box style={AutoDispatchDetailsStyles.renderInvoiceBox}>
        <NameContainer sx={AutoDispatchDetailsStyles.invoiceDeliveryAddress}>
          {invoiceDetails?.deliveryAddress?.name}
        </NameContainer>
      </Box>
      {
        (!isCompanyOwned || isApac || !invoiceDetails?.customerAddressId) && <Box style={AutoDispatchDetailsStyles.renderAddressBox}>
          <AddressContainer sx={AutoDispatchDetailsStyles.invoiceAddressLineType}>
            {invoiceDetails?.deliveryAddress?.addressLine1},{' '}
            {invoiceDetails?.deliveryAddress?.addressLine2 ?? ''}{' '}
            {invoiceDetails?.deliveryAddress?.city}, {invoiceDetails?.deliveryAddress?.state}{' '}
            {invoiceDetails?.deliveryAddress?.zipCode}
          </AddressContainer>
        </Box>
      }
      {invoiceDetails?.referencePONumber !== null && invoiceDetails?.referencePONumber !== "" && 
        <Box style={AutoDispatchDetailsStyles.renderAddressBox}>
          <AddressContainer sx={AutoDispatchDetailsStyles.invoiceAddressLineType}>
            PO: {invoiceDetails?.referencePONumber}
          </AddressContainer>
        </Box>
      }
      <Box style={AutoDispatchDetailsStyles.renderAddressBox}>
        <FiberManualRecord style={{ color: getColorBasedOnServiceLevel(serviceLevel) }} />
        <Typography sx={AutoDispatchDetailsStyles.title}>
          {(language as any)[currentLanguage].priorityInMins}:
        </Typography>
        <Typography sx={AutoDispatchDetailsStyles.description}>
          {isCompanyOwned 
            ? (!!serviceLevel ? serviceLevel : 'SILVER') 
            : (serviceLevel === 'BRONZE' ? serviceLevel : serviceLevel + ' mins')}
        </Typography>
      </Box>
      <Box style={AutoDispatchDetailsStyles.renderAddressBox}>
        <Typography sx={AutoDispatchDetailsStyles.title}>
          {(language as any)[currentLanguage].orderedAt}:
        </Typography>
        <Typography sx={AutoDispatchDetailsStyles.description} textTransform={'uppercase'}>
          {convertEpochToTimezoneFormat(invoiceDateTime)}
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={7}>
          <Box style={AutoDispatchDetailsStyles.renderAddressBox}>
            <Typography sx={AutoDispatchDetailsStyles.title}>
              {(language as any)[currentLanguage].timeElapsed}:
            </Typography>
            <Typography sx={AutoDispatchDetailsStyles.description}>
              {msToTime(Date.now() - invoiceDateTime)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={5} sx={AutoDispatchDetailsStyles.autoDispatchTimeGrid}>
          {autoDispatchEnabled && (autoDispatch || serviceLevel === 'AUTODASH') && <AutoDispatchInfo timeElapsed={timeElapsed} isBackGround invoiceDateTime={invoiceDateTime} wasHeld={!!invoiceDetails?.hold} autodashCancelled={!autoDispatch && serviceLevel === 'AUTODASH'}/>}
        </Grid>
      </Grid>
      <Box style={AutoDispatchDetailsStyles.renderInvoice}>
        <ItemHeader />
        {invoiceDetails?.parts?.map((part: any) => {
          return (
            <Box key={part.partNumber}>
              <PartDetails part={part} />
              {!!invoiceDetails.invoiceNotes && invoiceDetails.invoiceNotes.length > 0 && (
                <NoteDetails notes={invoiceDetails.invoiceNotes.find((note: any) => note?.sequenceNumber === part?.sequenceNumber)?.actualNote || 'NA'}/>
              )}
            </Box>
          )
        })}
      </Box>
      {(isCompanyOwned && !isApac && !!invoiceDetails?.customerAddressId) &&
        <Box sx={AutoDispatchDetailsStyles.addressSection}>
          {!!invoiceDetails?.multipleAddresses ?
            <DeliveryLocationSelect
              invoiceDetails={invoiceDetails}
              lookupResponse={invoiceDetails?.multipleAddresses && !!lookupResults && lookupResults.length>0 ? lookupResults[0] : null}
              refetchInvoices={refetchInvoices}
            /> :
            <Box>
              <Box sx={AutoDispatchDetailsStyles.addressHeader}>
                <PinIcon />
                <Typography sx={AutoDispatchDetailsStyles.addressHeaderBlock}>
                  Delivery Location
                </Typography>
              </Box>
              <Box>
                <Box style={AutoDispatchDetailsStyles.addressComponent}>
                  {!!invoiceDetails?.locationName && invoiceDetails?.locationName !== "" ? 
                    invoiceDetails?.locationName :
                    invoiceDetails?.deliveryAddress.addressLine1    
                  }
                </Box> 
                {!!invoiceDetails?.customerDropoffName && invoiceDetails?.customerDropoffName !== "" 
                  && <Box style={AutoDispatchDetailsStyles.addressComponent}>Drop Off Name ({invoiceDetails?.customerDropoffName})</Box>}
              </Box>
              <Box>
                <AddressContainer style={AutoDispatchDetailsStyles.addressComponentTwo}>
                  {invoiceDetails?.deliveryAddress?.addressLine1},{' '}
                  {invoiceDetails?.deliveryAddress?.addressLine2 ?? ''}{' '}
                  {invoiceDetails?.deliveryAddress?.city}, {invoiceDetails?.deliveryAddress?.state}{' '}
                  {invoiceDetails?.deliveryAddress?.zipCode}
                </AddressContainer>
              </Box>
              {!!invoiceDetails?.customerDeliveryNotes && invoiceDetails?.customerDeliveryNotes !== "" 
                && 
                <Box style={AutoDispatchDetailsStyles.addressSection}>
                  <Box style={AutoDispatchDetailsStyles.addressHeader}>
                    <NotesIcon/> Delivery Notes
                  </Box>
                  <Box style={AutoDispatchDetailsStyles.addressComponent}>
                    {invoiceDetails?.customerDeliveryNotes}
                  </Box>
                </Box>
              }
            </Box>
            }
        </Box>
      }
    </>
  )
}

const NameContainer = styled(DialogContentText)`
  font-size: 22px;
`
const AddressContainer = styled(DialogContentText)`
  font-size: 14px;
`

const AllItemText = styled(DialogContentText)`
  font-size: 12px;
  margin-top: 12px;
`
