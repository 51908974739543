import { DeliveryRoute } from '../types/DeliveryRouteTypes'
import axiosInstance from '../helpers/AxiosHelper'

export type AddUserRequest = {
  username?: string,
  email: string
  familyName: string
  givenName: string
  phoneNumber?: string
  defaultStoreNumber: string
  userType?: string
  storeList?: string[]
}

export type AppUser = {
  username: string
  defaultStoreNumber: string
  email: string
  givenName: string
  familyName: string
  userType: string
  enabled: boolean
  phoneNumber?: string
  storeList?: string[]
}

export type Driver = AppUser & {
  userStatus: string
  deliveryRoutes: DeliveryRoute[]
  userLocation: any
}

export class UserManagementService {
  static USER_ENDPOINT = '/users/v2'

  static async getUsers(storeNumber: string): Promise<AppUser[]> {
    
    const response = await axiosInstance.get<AppUser[]>(
      `${this.USER_ENDPOINT}/storeNumber/${storeNumber}`
    )
    return response.data as AppUser[]
  }

  static async getActiveDrivers(storeNumber: string): Promise<Driver[]> {
    const response = await axiosInstance.get<AppUser[]>(
      `${this.USER_ENDPOINT}/active/v2/storeNumber/${storeNumber}`
    )
    return response.data as Driver[]
  }

  static async addUser(addUserRequest: AddUserRequest): Promise<void> {
    try {
      await axiosInstance.post(`${this.USER_ENDPOINT}/adminCreate`, addUserRequest)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async disableUser(username: string): Promise<void> {
    try {
      await axiosInstance.delete(`${this.USER_ENDPOINT}/disable/${username}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async enableUser(username: string): Promise<void> {
    try {
      await axiosInstance.post(`${this.USER_ENDPOINT}/enable/${username}`, null)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async updateUser(updateUserRequest: AddUserRequest, username: string, isStatusConfirmed?: boolean): Promise<void> {
    try {
      await axiosInstance.put(`${this.USER_ENDPOINT}/username/${username}?isStatusConfirmed=${isStatusConfirmed}`, updateUserRequest)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async batchImportUsers(file: File) : Promise<void> {
    //Convert File type to form data
    let formData = new FormData()
    formData.append('file', file)

    const config = {
      headers: {
        'Content-Type': ' multipart/form-data'
      }
    }
    try {
      const response:any = await axiosInstance.post(
        `${this.USER_ENDPOINT}/batchAdminCreate`,
        formData,
        config
      )
      return response.data.message;
    } catch (e) {
      return Promise.reject(e)
    }
  }
}