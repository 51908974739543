import { Box, Button, CircularProgress, IconButton, Radio, TextField, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import language from '../../../../language/language'
import { TransferStoreSearchStyles } from './TransferStoreSearchStyles'
import { invoiceListStyles } from '../InvoiceList/invoiceListStyles'
import CloseIcon from '@mui/icons-material/Close'
import { TransferStop } from '../../../../utilities/types/DeliveryRouteTypes'
import { getDcStores } from '../../../../utilities/services/StoreService/StoreService'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'

type DCStoreProps = {
  closeSearchCallback: () => void
  setAddress: (value: TransferStop) => void
}

const TransferStoreSearch = ({ closeSearchCallback, setAddress }: DCStoreProps) => {
  const { currentLanguage } = useContext(LanguageContext)
  const { addSnack } = useContext(SnackbarContext)
  const [dcStore, setDcStore] = useState<string>('')
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null)
  const [selectedAddress, setSelectedAddress] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const saveStoreDetails = () => {
    const customerStop: TransferStop = {
      businessName: selectedAddress?.name || '',
      addressLine1: selectedAddress?.addresses?.length > 0 ? selectedAddress.addresses[0].firstLine : '',
      addressLine2: null,
      city: selectedAddress?.addresses?.length ? selectedAddress.addresses[0].cityName : '',
      state: selectedAddress.addresses[0].stateCode || '',
      zipCode: selectedAddress.addresses[0].postalCode || '',
      latitude: Number(selectedAddress.addresses[0].latitude),
      longitude: Number(selectedAddress.addresses[0].longitude),
      stopNotes: '',
      phoneNumber: selectedAddress?.retailPhoneNumber,
      storeNumber: selectedAddress?.macsId
    }
    setAddress(customerStop)
    closeSearchCallback()
  }

  const handleStoreSearch = async (arSearch: string) => {
    try {
      const response = await getDcStores(arSearch)
      if (response) {
        setSelectedAddress(response)
        setIsLoading(false)
      }
    }
    catch (error: any) {
      addSnack({ severity: 'error', message: error?.message, duration: 3000 })
      setSelectedAddress(null)
      setIsLoading(false)
    }
  }

  return (
    <Box>
      <Box sx={TransferStoreSearchStyles.rootContainer}>
        <Box style={TransferStoreSearchStyles.rootTitleContainer}>
          <Typography style={TransferStoreSearchStyles.title}>
            {language[currentLanguage].storeSearch}
          </Typography>
          <IconButton aria-label="close">
            <CloseIcon sx={invoiceListStyles.closeIconImg} onClick={closeSearchCallback} />
          </IconButton>
        </Box>

        <Box sx={TransferStoreSearchStyles.searchContainer} >
          <TextField
            data-testid="dc_store_text"
            label={language[currentLanguage].dcStorePlaceholder}
            variant="outlined"
            fullWidth
            margin="none"
            value={dcStore}
            onChange={(event) => setDcStore(event.target.value)}
          />
          <Button variant="contained" color="primary" style={TransferStoreSearchStyles.searchButton}
            data-testid="dc_store_button"
            disabled={!dcStore || dcStore === ''}
            onClick={() => handleStoreSearch(dcStore)}
          >
            {language[currentLanguage].search}
          </Button>
        </Box>

        <Box style={TransferStoreSearchStyles.rootBodyContainer}>
          {isLoading && (
            <CircularProgress data-testid={'spinner'} size={100} thickness={2} />
          )}
          {!isLoading && !!selectedAddress &&
            <Box sx={TransferStoreSearchStyles.storeAddressContainer}>
              <Box style={TransferStoreSearchStyles.nameContainer}>
                <Typography style={TransferStoreSearchStyles.name}>{selectedAddress?.name || ''}</Typography>
              </Box>
              <Box sx={TransferStoreSearchStyles.addressContainer}>
                {selectedAddress.addresses && selectedAddress.addresses.length > 0 ?
                  <Box sx={TransferStoreSearchStyles.location}>
                    <Radio style={TransferStoreSearchStyles.locationButtons}
                      checked={selectedAddressId === selectedAddress.name}
                      onChange={() => setSelectedAddressId(selectedAddress.name)}
                      data-testid={selectedAddress.name}
                    />
                    <Box sx={TransferStoreSearchStyles.locationDetails}>
                      <Typography style={TransferStoreSearchStyles.locationInfo}>{selectedAddress.addresses[0].firstLine}</Typography>
                      <Typography style={TransferStoreSearchStyles.locationInfo}>{`${selectedAddress.addresses[0].cityName}, ${selectedAddress.addresses[0].stateCode}, ${selectedAddress.addresses[0].postalCode}`}</Typography>
                    </Box>
                  </Box>
                  :
                  null
                }
              </Box>
            </Box>
          }
        </Box>

        <Box sx={TransferStoreSearchStyles.buttonContainer}>
          <Button
            variant="secondary"
            sx={TransferStoreSearchStyles.buttonStyle}
            onClick={closeSearchCallback}>
            {language[currentLanguage].cancel}
          </Button>
          <Button
            variant="primary"
            onClick={saveStoreDetails}
            sx={TransferStoreSearchStyles.buttonStyle}
            disabled={!selectedAddressId}>
            {language[currentLanguage].save}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default TransferStoreSearch
