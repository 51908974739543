import { useContext } from 'react'
import NavBarMenu from './NavBarMenu'
import { CircularProgress } from '@mui/material'
import { StoreProvider } from '../../utilities/contexts/StoreContext'
import NavigationBar from '../NavigationBar/NavigationBar'
import { AuthContext } from '../../utilities/contexts/AuthContext'
import TermsAndConditions from '../../screens/TermsAndConditions/TermsAndConditions'
import { BusinessUnitConfigProvider } from '../../utilities/contexts/businessUnitConfigContext'
import { getAuthCode } from '../../utilities/services/PingAuthService/PingAuthService'

const ApacNavigator = () => {
  const { termsAccepted, isInitializingTerms, isInitializingCurrentUser, currentUser } = useContext(AuthContext)

  if (isInitializingTerms || isInitializingCurrentUser) {
    return (
      <div className="Loading-container">
        <CircularProgress />
      </div>
    )
  } else if (currentUser) {
    if (termsAccepted) {
      return (
        <StoreProvider>
          <BusinessUnitConfigProvider>
            <NavigationBar />
            <NavBarMenu />
          </BusinessUnitConfigProvider>
        </StoreProvider>
      )
    } else {
      return <TermsAndConditions />
    }
  } else {
    getAuthCode()
    return null
  }
}
export default ApacNavigator
