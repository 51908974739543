import React, { createContext, ReactElement, useEffect, useState } from 'react'
import { SupportedLanguage } from '../../language/LanguageTypes'

export const LanguageContext = createContext<LanguageContextType>({
  currentLanguage: 'en',
  changeLanguage: () => {}
})

type LanguageContextType = {
  currentLanguage: SupportedLanguage
  changeLanguage: (language: SupportedLanguage) => void
}

export const LanguageProvider = ({ children }: any): ReactElement => {
  const [currentLanguage, setCurrentLanguage] = useState<SupportedLanguage>('en')

  useEffect(() => {
    if (currentLanguage) {
      setCurrentLanguage(currentLanguage)
    }
  }, [currentLanguage])

  const changeLanguage = async (language: SupportedLanguage) => {
    setCurrentLanguage(language)
  }

  return (
    <LanguageContext.Provider
      value={{
        currentLanguage,
        changeLanguage
      }}>
      {children}
    </LanguageContext.Provider>
  )
}
