import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import { Clear, WarningAmberOutlined } from '@mui/icons-material'
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { GridArrowDownwardIcon } from '@mui/x-data-grid'
import { completedRouteStyles } from './completedRouteStyles'
import Colors from '../../../../assets/Colors'
import { useUsersByStore } from '../../../../queries/Users/useUsersByStore'
import { Stop } from '../../../../utilities/types/DeliveryRouteTypes'
import { checkBackToStoreStop } from '../../../../utilities/helpers/checkStopTypes'
import PdfGenerator from '../PdfGenerator/PdfGenerator'
import ExternalDelivery from '../ExternalDelivery/ExternalDelivery'
import CreateRoutePreviewMap from '../CreateRoute/CreateRoutePreviewMap'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { DateTime } from 'luxon'
import { convertEpochToTimezoneFormat } from '../../../../utilities/helpers/DateTimeFormatters'

type CompletedRouteProps = {
  completedRouteData: any
  openCompletedRoute: boolean
  setOpenCompletedRoute: (value: boolean) => void
  isModal?: boolean
}

const CompletedRoute = ({
  completedRouteData,
  openCompletedRoute,
  setOpenCompletedRoute,
  isModal = true,
}: CompletedRouteProps) => {
  const routeInProgress = completedRouteData && completedRouteData[0]?.routeStatus === 'in-progress'
  const delayTime = completedRouteData ? completedRouteData[0]?.stops?.reduce((acc: any, obj: any) => acc + (obj['delayTimeInMinutes'] ?? 0), 0) : 0

  const { data: drivers } = useUsersByStore()
  const { currentLanguage } = useContext(LanguageContext)
  const { storeAddress } = useContext(StoreContext)

  const renderInvoiceAndPO = (item: any) => {
    return (
      <Grid container columns={{ xs: 12, md: 44 }}>
        <Grid item xs={12}>
          <AllItemText sx={completedRouteStyles.invoiceNumber}>{`${(language as any)[currentLanguage].invoiceNumber
            }${item.invoiceNumber}`}</AllItemText>
        </Grid>
      </Grid>
    )
  }

  const getDriverName = (deliveryDriver: string) => {
    const driver = drivers?.filter((driver) => driver.username === deliveryDriver)
    if (driver && driver.length > 0) {
      if (driver[0].givenName && driver[0].familyName) {
        const fullName = driver[0].givenName + ' ' + driver[0].familyName
        return fullName
      }
    }
    return ''
  }

  const transformedRouteData = completedRouteData
    ? completedRouteData.reduce((result: any, item: any) => {
      const { ...transformedItem } = item
      result = transformedItem
      return result
    }, {})
    : {}

  const ItemHeader = () => {
    return (
      <Box>
        <Grid container spacing={2} paddingBottom={2}>
          <Grid item xs={3}>
            <AllItemText sx={completedRouteStyles.headerTitle}>
              {(language as any)[currentLanguage].itemNumber}
            </AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={completedRouteStyles.headerTitle}>
              {(language as any)[currentLanguage].lineAbbreviation}
            </AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={completedRouteStyles.headerTitle}>
              {(language as any)[currentLanguage].description}
            </AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={completedRouteStyles.headerTitle}>
              {(language as any)[currentLanguage].quantity}
            </AllItemText>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const Item = (item: any) => {
    return (
      <Box>
        <Grid container spacing={2} paddingBottom={2}>
          <Grid item xs={3}>
            <AllItemText sx={completedRouteStyles.headerTitle}>{item.part.partNumber}</AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={completedRouteStyles.headerTitle}>
              {item.part.lineAbbreviation}
            </AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={completedRouteStyles.headerTitle}>{item.part.description}</AllItemText>
          </Grid>
          <Grid item xs={3}>
            <AllItemText sx={completedRouteStyles.headerTitle}>
              {item.part.requestedQuantity}
            </AllItemText>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const Notes = ({notes} : any) => {
    return (
      <Box>
        <Grid container spacing={2} paddingBottom={2}>
          <Grid item xs={12}>
            <AllItemText sx={completedRouteStyles.notesTitle}>
              {(language as any)[currentLanguage].notes}:
            </AllItemText>
            <Typography sx={{
              color: Colors.napaBlack,
              fontSize: '12px',
              width: '100%'
            }}>{notes}</Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const isCustomStop = (stopType: string) => stopType === 'custom'

  const renderInvoice = (invoiceData: any, stopIndex: number) => {
    const invoice = invoiceData?.map((invoice: any) => {
      return (
        <Accordion style={{ boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={<GridArrowDownwardIcon style={{ cursor: 'pointer' }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={completedRouteStyles.accordionSummary}
          >
            <Box style={completedRouteStyles.renderInvoiceBox}>
              <NameContainer sx={completedRouteStyles.invoiceDeliveryAddress}>
                {isCustomStop(invoice.stopType) ? invoice?.customStopName : invoice.deliveryAddress.name}
              </NameContainer>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '8px' }}>
            <AddressContainer sx={completedRouteStyles.invoiceAddressLineType}>
              {isCustomStop(invoice.stopType) ? invoice?.addressLine1 : invoice?.deliveryAddress?.addressLine1}
            </AddressContainer>
            <Box sx={{ display: 'flex', justifyContent: 'row', marginRight: '20px' }}>
              <Typography
                data-testId="deliveredTime"
                sx={{ fontSize: '14px', color: !invoice.deliveryStatus ? Colors.napaGrey : '' }}>
                {routeInProgress && !(invoice.deliveryStatus || invoice.delivered)
                  ? (language as any)[currentLanguage].statusInprogress
                  : `${(language as any)[currentLanguage].deliveredAt} ` +
                  convertEpochToTimezoneFormat(invoice?.deliveryTimestamp)}
              </Typography>
            </Box>
            {!isCustomStop(invoice.stopType) && renderInvoiceAndPO(invoice)}
            {!isCustomStop(invoice.stopType) && <ItemHeader />}
            {!isCustomStop(invoice.stopType) && invoice.parts?.map((part: any) => {
              return (
                <>
                  <Item part={part} />
                  {!!invoice.invoiceNotes && invoice.invoiceNotes.length > 0 &&
                    <Notes notes={invoice.invoiceNotes.find((note: any) => note?.sequenceNumber === part?.sequenceNumber)?.actualNote || 'NA'}/>}
                  <Divider sx={{ marginRight: 2 }} />
                </>
              )
            })}
            {invoice.deliveryStatus && (
              <>
                <Box
                  sx={{
                    width: '80%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                    justifyContent: 'space-between'
                  }}>
                  <Box>
                    <Typography
                      sx={{
                        color: Colors.napaBlack,
                        fontSize: '14px',
                        fontWeight: 600,
                        width: '100%'
                      }}>
                      {(language as any)[currentLanguage].print}*
                    </Typography>
                    <Typography
                      sx={{
                        color: Colors.napaBlack,
                        fontSize: '14px'
                      }}>
                      {completedRouteData[0]?.stops[stopIndex]?.signatureCaptureName}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ color: Colors.napaBlack, fontSize: '14px' }}>
                      {(language as any)[currentLanguage].signature}
                    </Typography>
                    <img
                      style={{ width: '100px' }}
                      alt={'Delivery Signature'}
                      src={completedRouteData[0]?.stops[stopIndex]?.signatureCaptureImage}
                    />
                  </Box>
                </Box>
                {completedRouteData[0]?.stops[stopIndex]?.photoProofOfDelivery && (
                  <Box>
                    <Typography sx={{ color: Colors.napaBlack, fontSize: '16px' }}>
                      {(language as any)[currentLanguage].deliveryPhoto}
                    </Typography>
                    <Button
                      onClick={() =>
                        enlargeImage(completedRouteData[0]?.stops[stopIndex]?.photoProofOfDelivery)
                      }
                      style={{ paddingLeft: '0px' }}>
                      <img
                        style={{ width: '82px', height: '84px', border: '1px', borderRadius: '3px' }}
                        alt={'Delivery Proof'}
                        src={`data:image/jpeg;base64,${completedRouteData[0]?.stops[stopIndex]?.photoProofOfDelivery}`}
                      />
                    </Button>
                  </Box>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      )
    })
    return invoice
  }

  const enlargeImage = (zoom: any) => {
    let image = new Image()
    image.src = `data:image/jpeg;base64,${zoom}`
    image.width = 540
    var newTab = window.open('')
    newTab?.document.write(image.outerHTML)
  }

  const handleClose = () => {
    setOpenCompletedRoute(false)
  }

  const renderContent = () => (
      <>
        {completedRouteData && completedRouteData[0].deliveryDriver === 'DOORDASH' ? (
            <ExternalDelivery
                handleClose={handleClose}
                routeDetails={completedRouteData[0]}
                ItemHeader={ItemHeader}
                Item={Item}
            />
        ) : (
            <>
              <DialogTitle sx={completedRouteStyles.dialogTitle}>
                <Typography sx={completedRouteStyles.delayTitle}>
                  {delayTime > 0 ? `Delay Reported: ${delayTime} mins` : ''}
                </Typography>
                {isModal && <IconButton onClick={handleClose}>
                  <Clear fontSize="medium" />
                </IconButton> }
              </DialogTitle>
              <Typography sx={completedRouteStyles.routeReviewTypography}>
                {(language as any)[currentLanguage].routeNumber}
                {completedRouteData && completedRouteData[0].routeId}
              </Typography>
              <CustomizedTitle style={{ padding: '16px 10px' }}>
                <Box sx={{ marginTop: '-20px' }}>
                  <DialogContentText sx={completedRouteStyles.totalStops}>
                    {(language as any)[currentLanguage].driver}:{' '}
                    {completedRouteData && getDriverName(completedRouteData[0].deliveryDriver)}
                  </DialogContentText>
                  <DialogContentText sx={completedRouteStyles.totalStops}>
                    {(language as any)[currentLanguage].totalStops}{' '}
                    {completedRouteData && completedRouteData[0].stops?.length}
                  </DialogContentText>
                </Box>
              </CustomizedTitle>
              <Grid item xs={12} sx={completedRouteStyles.RouteMapPreview}>
                <CreateRoutePreviewMap routeDetails={transformedRouteData} />
              </Grid>
              <CustomisedDialogContent sx={{ marginTop: '-20px', overflowY: 'unset' }}>
                {completedRouteData &&
                    completedRouteData[0].stops?.map((stop: Stop, stopIndex: number) => (
                        stop.stopType !== 'back_to_store' && (
                            <div style={completedRouteStyles.renderInvoice} key={stopIndex}>
                              {stop.delay && (
                                  <Box sx={completedRouteStyles.delayWarning}>
                                    <DialogContentText sx={completedRouteStyles.delayContent}>
                                      <WarningAmberOutlined fontSize='small' style={{ color: Colors.napaRed }} />
                                      {` Delayed ${stop.delayTimeInMinutes} mins: ${stop.delayReason?.join(', ')}`}
                                    </DialogContentText>
                                    {stop.delayNotes && (
                                        <DialogContentText sx={completedRouteStyles.delayNotes}>
                                          Notes from driver: {stop.delayNotes}
                                        </DialogContentText>
                                    )}
                                  </Box>
                              )}
                              {renderInvoice(stop.stopType === 'custom' ? [stop] : stop.invoices, stopIndex)}
                            </div>
                        )
                    ))}
              </CustomisedDialogContent>
              <DialogActions sx={completedRouteStyles.dialogActions}></DialogActions>
              {completedRouteData && checkBackToStoreStop(completedRouteData[0]) && (
                  <CustomisedDialogContent
                      sx={{ marginTop: '10px', overflowY: 'visible', paddingBottom: '0px' }}>
                    <Box sx={completedRouteStyles.returnStore}>
                      {completedRouteData &&
                          completedRouteData[0].stops?.map((stop: Stop, stopIndex: number) => (
                              stop.stopType === 'back_to_store' && (
                                  <Box key={stopIndex}>
                                    {stop.delay && (
                                        <Box sx={completedRouteStyles.backToStoreDelay}>
                                          <DialogContentText sx={completedRouteStyles.delayContent}>
                                            <WarningAmberOutlined fontSize='small' style={{ color: Colors.napaRed }} />
                                            {` Delayed ${stop.delayTimeInMinutes} mins: ${stop.delayReason?.join(', ')}`}
                                          </DialogContentText>
                                          {stop.delayNotes && (
                                              <DialogContentText sx={completedRouteStyles.delayNotes}>
                                                Notes from driver: {stop.delayNotes}
                                              </DialogContentText>
                                          )}
                                        </Box>
                                    )}
                                    <NameContainer sx={completedRouteStyles.invoiceDeliveryAddress}>
                                      {(language as any)[currentLanguage].returnToStore}
                                    </NameContainer>
                                    <DialogContentText sx={completedRouteStyles.invoiceAddressLineType}>
                                      {storeAddress?.addressLine1}, {storeAddress?.addressLine2 ?? ''}{' '}
                                      {storeAddress?.city}, {storeAddress?.state} {storeAddress?.zipCode}
                                    </DialogContentText>
                                    <DialogContentText sx={completedRouteStyles.invoiceAddressLineType}>
                                      {routeInProgress
                                          ? ``
                                          :  completedRouteData[0].routeCompletionTimestamp && `${(language as any)[currentLanguage].arrivedAt} ${DateTime.fromMillis(
                                              completedRouteData[0].routeCompletionTimestamp
                                          ).toLocaleString(DateTime.TIME_SIMPLE)}`}
                                    </DialogContentText>
                                  </Box>
                              )
                          ))}
                    </Box>
                  </CustomisedDialogContent>
              )}
              {completedRouteData && getDriverName(completedRouteData[0].deliveryDriver) !== '' && (
                  <Box sx={completedRouteStyles.stickyBottom}>
                    <PdfGenerator
                        completedRoutes={completedRouteData}
                        driverFullName={getDriverName(completedRouteData[0].deliveryDriver)}
                        currentLanguage={currentLanguage}
                    />
                  </Box>
              )}
            </>
        )}
      </>
  );

  return isModal ? (
      <Dialog open={openCompletedRoute} fullWidth={true} onClose={handleClose}>
        {renderContent()}
      </Dialog>
  ) : (
      <div>{renderContent()}</div>
  );
}

const CustomizedTitle = styled(DialogTitle)`
  color: blue;
  display: flex;
  justify-content: space-between;
`

const CustomisedDialogContent = styled(DialogContent)`
  color: green;
  border-width: 1px;
  border-color: red;
  border-width-color: blue;
`

const NameContainer = styled(DialogContentText)`
  font-size: 14px;
  color: blue;
`

const AddressContainer = styled(DialogContentText)`
  font-size: 14px;
`

const AllItemText = styled(DialogContentText)`
  font-size: 16px;
  margin-top: 12px;
`

export default CompletedRoute
