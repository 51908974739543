import React, { useContext } from 'react'
import {
  CometChatThemeContext,
  CometChatUsersWithMessages,
  WithMessagesStyle
} from '@cometchat/chat-uikit-react'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { CometChat } from '@cometchat/chat-sdk-javascript'
import Colors from '../../assets/Colors'
import {
  DetailsConfiguration,
  MessageComposerConfiguration,
  MessageHeaderConfiguration,
  MessageListConfiguration,
  MessagesConfiguration,
  MessagesStyle,
  ThreadedMessagesConfiguration
} from '@cometchat/uikit-shared'

const NSSAChat = () => {
  const { currentStore } = useContext(StoreContext)

  let usersRequestBuilder = new CometChat.UsersRequestBuilder().setLimit(30).setTags([currentStore]) // tag based on store number

  function onItemLongPress() {}

  // const ChatInput = (
  //   user: CometChat.User,
  //   group: CometChat.Group,
  //   parentMessage: CometChat.BaseMessage
  // ) => {
  //   const [message, setMessage] = useState<string>('')
  //   const sendMessage = async () => {
  //     if (!message.length) return
  //
  //     const cometChatMessage = new CometChat.TextMessage(user?.getUid(), message, 'user')
  //     const loggedInUser = await CometChatUIKit.getLoggedinUser()
  //
  //     if (!loggedInUser) throw new Error('Cannot send message without user logged in.')
  //
  //     cometChatMessage.setSender(loggedInUser)
  //     cometChatMessage.setMuid(Date.now().toString())
  //     await CometChat.sendMessage(cometChatMessage)
  //   }
  //
  //   return (
  //     <TextField
  //       sx={{ margin: '10px', backgroundColor: Colors.white }}
  //       onPaste={(event) => event.preventDefault()}
  //       InputProps={{
  //         endAdornment: (
  //           <InputAdornment position={'end'}>
  //             <IconButton onClick={sendMessage}>
  //               <Send />
  //             </IconButton>
  //           </InputAdornment>
  //         )
  //       }}></TextField>
  //   )
  // }

  // Custom styles for the chat
  const usersWithMessagesStyle = new WithMessagesStyle({
    background: Colors.napaGreyDisabled, // Light purple background for chat bubbles
    borderRadius: '19px', // Rounded chat bubbles
    messageTextFont: 'NAPA SANS',
    height: '80vh'
  })

  const msgConfig = new MessagesConfiguration({
    hideMessageComposer: false,
    disableTyping: false,
    messageHeaderConfiguration: new MessageHeaderConfiguration({}),
    messageListConfiguration: new MessageListConfiguration({}),
    messageComposerConfiguration: new MessageComposerConfiguration({
      hideVoiceRecording: true,
      attachmentOptions: () => [],
      secondaryButtonView: () => {
        return <div></div>
      },
      hideLiveReaction: true
    }),
    threadedMessageConfiguration: new ThreadedMessagesConfiguration({}),
    detailsConfiguration: new DetailsConfiguration({}),
    customSoundForIncomingMessages: '',
    customSoundForOutgoingMessages: '',
    disableSoundForMessages: false,
    messagesStyle: new MessagesStyle({}),
    messageHeaderView: null,
    messageComposerView: null,
    messageListView: null,
    hideMessageHeader: true,
    hideDetails: false
  })

  let { theme } = useContext(CometChatThemeContext)

  // Set custom colors for the theme
  theme.palette.setMode('light') // Use 'light' or 'dark' as per your app's mode
  theme.palette.setPrimary({ light: '#6851D6', dark: '#6851D6' })
  theme.palette.setAccent({ light: '#6851D6', dark: '#6851D6' })
  theme.typography.setFontFamily('NAPA SANS')

  //TODO: To improve UI in future, maybe look into CometChatConversationsWithMessages component - Abdul
  return (
    <div
      onPaste={(event) => {
        event.preventDefault()
      }}
      onCopy={(event) => {
        event.preventDefault()
      }}>
      <CometChatThemeContext.Provider value={{ theme }}>
        <CometChatUsersWithMessages
          usersWithMessagesStyle={usersWithMessagesStyle}
          messagesConfiguration={msgConfig}
          usersConfiguration={{
            usersRequestBuilder: usersRequestBuilder,
            searchRequestBuilder: usersRequestBuilder,
            subtitleView: null,
            disableUsersPresence: false,
            listItemView: null,
            menu: null,
            options: null,
            onItemClick: null,
            hideSeparator: false,
            selectionMode: 0,
            hideError: false,
            onSelect: onItemLongPress,
            emptyStateView: null,
            errorStateView: null,
            showSectionHeader: false,
            loadingStateView: null,
            loadingIconURL: '',
            searchIconURL: '',
            hideSearch: false,
            titleAlignment: 0,
            statusIndicatorStyle: { height: '100' },
            avatarStyle: { borderRadius: '1' },
            usersStyle: { titleTextFont: '1' },
            listItemStyle: { activeBackground: '100' }
          }}
        />
      </CometChatThemeContext.Provider>
    </div>
  )
}

export default NSSAChat
