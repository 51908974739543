import {
  createContext,
  useState,
  useEffect,
  ReactElement,
  useContext,
  // useRef
} from 'react'
import { AuthHelper, getPingUserType } from '../helpers/AuthHelper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUserAcceptedTerms } from '../../queries/Terms/useUserAcceptedTerms'
import { isApac } from '../helpers/RegionHelper'
import { getUserDetailsByUserName, UserDataTypes } from '../services/UserService/UserService'
import { SnackbarContext } from './SnackbarContext'
import { LanguageContext } from './LanguageContext'
import language from '../../language/language'
import { UserManagementService } from '../services/UserManagementService'
import { getAccessTokenWithRefreshToken, getPingTokenByCode } from '../services/PingAuthService/PingAuthService'
import { calculateExpireTime, unixToMiliSec, clearCookieAndRedirectHome, getCookie, getCookieBoolean, removeCookie, setCookie, secondToUnix, bufferTime } from '../helpers/CookieHelper'
import { CometChatUIKit } from '@cometchat/chat-uikit-react'

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  isInitializingCurrentUser: true,
  isInitializingTerms: true,
  signIn: () => {},
  signOut: () => {},
  termsAccepted: undefined,
  refetchTerms: () => {},
  isCOS: false
})

type AuthContextType = {
  currentUser: CurrentUser | null
  isInitializingCurrentUser: boolean
  signIn: (username: string, password: string) => void
  signOut: () => void
  termsAccepted: boolean | undefined
  isInitializingTerms: boolean
  refetchTerms: () => void
  isCOS: boolean
}

export interface CurrentUser {
  username: string
  givenName: string
  familyName: string
  email: string
  defaultStoreNumber: string
  storeList: string[]
  userType: string
}

export const AuthProvider = ({ children }: any): ReactElement => {
  let navigate = useNavigate()
  const [searchParams] = useSearchParams()
  // const callOnce = useRef(true)
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null)
  const [isInitializingCurrentUser, setIsInitializingCurrentUser] = useState<boolean>(true)
  const {
    data: termsAccepted,
    isLoading: isLoadingTerms,
    refetch: refetchTerms
  } = useUserAcceptedTerms(currentUser?.username as string)
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)
  const tokenExpiry = unixToMiliSec(Number(getCookie('expires_in')) - bufferTime)
  const [expireTime, setExpireTime] = useState<number>(tokenExpiry)
  const [refreshTokenLoading, setRefreshTokenLoading] = useState<boolean>(false)
  const [refreshTokenExpiry, setRefreshTokenExpiry] = useState<number>()
  const isCOS = getCookieBoolean('isCOS')
  const isAuthenticated = getCookieBoolean('authenticated')

  // Compare user details with ping and update the database accordingly
  const fetchAndCompareUserData = async (updateUser = true) => {
    try {
      const pingUserData = await AuthHelper.currentSignedInPingUser()
      const expiryRefreshToken = unixToMiliSec(pingUserData.refreshTokenExpiryTime)
      setRefreshTokenExpiry(expiryRefreshToken)

      try {
        const userFromDB: UserDataTypes = await getUserDetailsByUserName(pingUserData.username)
        const currentUser = {
          username: pingUserData.username,
          givenName: pingUserData.given_name,
          familyName: pingUserData.family_name,
          email: pingUserData.UserPrincipalName,
          defaultStoreNumber: userFromDB.defaultStoreNumber,
          userType: getPingUserType(pingUserData),
          storeList: userFromDB.storeList
        }
        // if user disabled then throw error otherwise set the user
        if (!userFromDB.enabled) {
          addSnack({
            severity: 'error',
            message: 'This account has been disabled. Please see store admin to re-enable it',
            duration: 3000
          })
          return Promise.reject()
        }
        setCookie('authenticated', 'true')
        setCurrentUser(currentUser)

        // It will call the user update endpoint if data mismatched with ping
        const { payload, isUpdate } = AuthHelper.comparePingAndDBUser(pingUserData, userFromDB)
        if (isUpdate && updateUser) {
          try {
            await UserManagementService.updateUser(payload, pingUserData.username, false)
          } catch (e) {
            console.error('Unable to update user', e)
          }
        }
      } catch (error: any) {
        addSnack({
          severity: 'error',
          message:
            error.response.status === 404
              ? (language as any)[currentLanguage].errorUserByUserName
              : error.response.data.message,
          duration: 3000
        })
      }
    } catch (e: any) {
      addSnack({
        severity: 'error',
        message: e.message,
        duration: 3000
      })
    } finally {
      setIsInitializingCurrentUser(false)
    }
  }

  // To fetch the ping token which will be added to the cookie from server
  const getPingToken = async (code: string) => {
    try {
      const response = await getPingTokenByCode(code)
      if (response.success === 'true') {
        fetchAndCompareUserData()
        const time = calculateExpireTime(response.expires_in)
        setExpireTime(time)
        // Here we are storing AccessToken expiry time in cookie 
        setCookie('expires_in', secondToUnix(response.expires_in))
      }
    } catch (e) {
      addSnack({
        severity: 'error',
        message: `Unable to get the token. ${e}`,
        duration: 3000
      })
    }
  }

  const refreshTheToken = async () => {
    setRefreshTokenLoading(true)
    try {
      const response = await getAccessTokenWithRefreshToken()
      if (response.success === 'true') {
        const time = calculateExpireTime(response.expires_in)
        setExpireTime(time)
        setCookie('expires_in', secondToUnix(response.expires_in))
      }
    } catch (e) {
      addSnack({
        severity: 'error',
        message: `Unable to refresh the token. ${e}`,
        duration: 3000
      })
    } finally {
      setRefreshTokenLoading(false)
    }
  }

  // get new access token using refresh token once it expired
  useEffect(() => {
    if (!refreshTokenLoading && expireTime && currentUser) {
      const timeoutId = setTimeout(() => {
        refreshTheToken()
      }, expireTime)
      return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTokenLoading, expireTime, currentUser])

  useEffect(() => {
    const code = searchParams.get('code')
     if (isAuthenticated || code) {
      if (isAuthenticated) {
        fetchAndCompareUserData(false)
      } else if (code) {
        getPingToken(code)
      }
      // Please enable this callOnce for local run as API call twice due to react strict
      // else if (callOnce.current && code) {
      //   callOnce.current = false
      //   getPingToken(code)
      // }
    } else {
      AuthHelper.currentSignedInUser()
        .then((user) => {
          setCurrentUser(user)
        })
        .catch((e) => console.error(e.message))
        .finally(() => setIsInitializingCurrentUser(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // logout the user after refresh token expiry
  useEffect(() => {
    if (refreshTokenExpiry) {
      const timeoutId = setTimeout(() => {
        signOut()
      }, refreshTokenExpiry)
      return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTokenExpiry])
  
  useEffect(() => {
    if (!currentUser) return
    const cometChatLogin = async () => {
      //TODO: maybe move this to sign-out?
      const user = await CometChatUIKit.getLoggedinUser()
      if (user) {
        await CometChatUIKit.logout()
      }
      const userInfo = await getUserDetailsByUserName(currentUser.username)

      await CometChatUIKit.login(userInfo.cometChatUUID)
      console.log('Successfully Logged in to CometChat.')
    }

    cometChatLogin()
      .catch((e) => {
        console.log(e)
      })
  }, [currentUser])

  const signIn = async (username: string, password: string) => {
    try {
      const currentUser: CurrentUser = await AuthHelper.signIn({ username, password })
      setCurrentUser(currentUser)
      navigate('/')
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }

  const signOut = async () => {
    if (isApac || isCOS) {
      setIsInitializingCurrentUser(true)
      try {
        await AuthHelper.signOutPingUser()
        setCurrentUser(null)
        isApac ? removeCookie('authenticated') : clearCookieAndRedirectHome()
      } catch (e: any) {
        addSnack({
          severity: 'error',
          message: e.message,
          duration: 3000
        })
      } finally {
        setIsInitializingCurrentUser(false)
      }
    } else {
      try {
        await AuthHelper.signOut()
      } catch (error: any) {
        console.error('Error signing out')
      } finally {
        setCurrentUser(null)
        navigate('/')
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isInitializingCurrentUser,
        isInitializingTerms: isLoadingTerms,
        signOut,
        signIn,
        termsAccepted,
        refetchTerms,
        isCOS
      }}>
      {children}
    </AuthContext.Provider>
  )
}
