import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
  Checkbox,
  Select,
  MenuItem,
  Alert,
  AlertTitle
} from '@mui/material'

import { Clear, Warning } from '@mui/icons-material'
import { Box } from '@mui/system'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import removeIcon from '../../../../assets/remove.png'
import deliveryTruck from '../../../../assets/delivery-truck.png'
import InvoicedFlag from '../../../../assets/invoiced.png'
import OnHoldFlag from '../../../../assets/onhold.png'
import doordash_modal from '../../../../assets/doordash_modal.png'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import {
  DeliveryRouteService
} from '../../../../utilities/services/DeliveryRouteService'
import { invoiceDetailStyles } from './InvoiceDetailStyles'
import language from '../../../../language/language'
import { InvoicesResponse } from '../../../../utilities/types/DeliveryRouteTypes'
import Colors from '../../../../assets/Colors'
import { AutoDispatchDetails } from '../AutoDisptachDetails/AutoDispatchDetails'
import { CustomerResponse } from '../../../../utilities/services/CustomerService/CustomerService'
import { UseQueryResult } from 'react-query'
import { isApac } from '../../../../utilities/helpers/RegionHelper'
import MuiPhoneNumber from 'material-ui-phone-number'
import addDriverStyles from '../../../UserManagement/components/AddDriver/addDriverStyles'

enum ActionType {
  remove = 'remove',
  markAsDelivered = 'markAsDelivered'
}

type BulkInvoices = {
  invoiceNumber: string
  invoiceDateTime: number
}

type InvoiceDetailProps = {
  setOpenEditInvoice: (value: boolean) => void
  setInvoiceDetails: (value: any) => void
  openEditInvoice: boolean
  invoiceDetails: InvoicesResponse | null
  refetchInvoices: () => {}
  setOpenInvoiceActions: (value: boolean) => void
  setInvoiceActionType: (value: ActionType) => void
  setDropdownARNumbers: (value: string[]) => void
  lookupResults: UseQueryResult<CustomerResponse, any>[]
  clearSelectedInvoiceData: () => void,
  setBulkInvoices: (value: BulkInvoices[]) => void
}

const InvoiceDetail = ({
  setOpenEditInvoice,
  setInvoiceDetails,
  openEditInvoice,
  invoiceDetails,
  refetchInvoices,
  setInvoiceActionType,
  setOpenInvoiceActions,
  setDropdownARNumbers,
  lookupResults,
  clearSelectedInvoiceData,
  setBulkInvoices
}: InvoiceDetailProps) => {
  const { currentLanguage } = useContext(LanguageContext)
  const { storeAddress, currentStore, autoDispatchEnabled, isCompanyOwned } = useContext(StoreContext)
  const { addSnack } = useContext(SnackbarContext)
  const [serviceLevel, setServiceLevel] = useState<string>('')
  const invoiceDateTime = invoiceDetails?.invoiceDateTime ?? 0
  const [stopAutoDispatch, setStopAutoDispatch] = useState<boolean>(false)
  const [autoError, setAutoError] = useState<boolean>(false)
  const [stopError, setStopError] = useState<boolean>(false)
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState<boolean>(false)
  const [reason, setReason] = useState<string>('selectReason')

  const [pickupInstructions, setPickupInstructions] = useState<string>('')
  const [dropoffInstructions, setDropoffInstructions] = useState<string>(invoiceDetails?.customerDeliveryNotes || '')
  const [dropoffAddress, setDropoffAddress] = useState<string>('')
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState<string>('')
  const [dropoffPhoneNumber, setDropoffPhoneNumber] = useState<string>(
    invoiceDetails?.deliveryAddress?.phoneNumber ?? ''
  )
  const orderValueInCents = '0'
  const dropoffBusinessName = invoiceDetails?.deliveryAddress.name || ''

  const autoDispatch = invoiceDetails?.autoDispatch
  const timeElapsed = Date.now() - invoiceDateTime

  useEffect(() => {
    setDropoffInstructions(invoiceDetails?.customerDeliveryNotes || '')
  }, [invoiceDetails?.customerDeliveryNotes])

  useEffect(() => {
    if (invoiceDetails) {
      const level = isCompanyOwned
        ? invoiceDetails.serviceLevel
        : invoiceDetails?.deliveryPriorityInMins && invoiceDetails?.serviceLevel
          ? `${invoiceDetails.serviceLevel} - ${invoiceDetails.deliveryPriorityInMins}`
          : invoiceDetails.serviceLevel
          ? `${invoiceDetails.serviceLevel}`
          : invoiceDetails.deliveryPriorityInMins
          ? `${invoiceDetails.deliveryPriorityInMins}`
          : 'BRONZE'
      setServiceLevel(level)
      const { addressLine1, addressLine2, city, state, zipCode } =
        invoiceDetails.deliveryAddress || {}
      setDropoffAddress(`${addressLine1} ${!!addressLine2 ? addressLine2 : ''} ${city}, ${state} ${zipCode}`)
      setDropoffPhoneNumber(invoiceDetails?.deliveryAddress?.phoneNumber ?? '');

      if(!isApac && isCompanyOwned && invoiceDetails?.multipleAddresses === true){
        setDropdownARNumbers([invoiceDetails.customerNumber])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetails])

  const handleClose = () => {
    setDropdownARNumbers([])
    setOpenEditInvoice(false)
    setInvoiceDetails(null)
    setServiceLevel('')
    setAutoError(false)
    setStopError(false)
    setStopAutoDispatch(false)
    setReason('selectReason')
    setBulkInvoices([])
  }

  const handleAutoDispatch = () => {
    if (stopAutoDispatch) {
      const request = {
        invoiceNumberDateTimes: [{
          invoiceNumber: invoiceDetails?.invoiceNumber,
          invoiceDateTime: invoiceDetails?.invoiceDateTime
        }],
        nssaInvoiceStatus: 'INVOICED',
        reason: reason,
        stopAutodispatch: true
      }
      setStopError(false)
      const successMessage = `${(language as any)[currentLanguage].stopAutoDispatched} ${
        invoiceDetails?.invoiceNumber
      }`
      stopAutodispatch(request, successMessage)
    } else {
      const successMessage = `${(language as any)[currentLanguage].autoDispatched
        .split('#id')
        .join(invoiceDetails?.invoiceNumber)}`
        createRouteAndDelivery(successMessage, false)

    }
  }

  const handleOnHold = async () => {
    const request = {
      invoiceNumberDateTimes: [{
        invoiceNumber: invoiceDetails?.invoiceNumber,
        invoiceDateTime: invoiceDetails?.invoiceDateTime
      }],
      nssaInvoiceStatus: invoiceDetails?.nssaInvoiceStatus === 'ON_HOLD' ? 'INVOICED' : 'ON_HOLD',
      stopAutodispatch: false
    }
    try {
      const response = await DeliveryRouteService.updateInvoiceStatus(
        request,
        currentStore,
      )
      handleClose()
      clearSelectedInvoiceData()
      if (response.status === 'Success') {
        addSnack({
          severity: 'success',
          message: `${(language as any)[currentLanguage].invoiceNumber}${
            invoiceDetails?.invoiceNumber
          } ${(language as any)[currentLanguage].hasUpdated}`,
          action: null,
          duration: 3000
        })
      } else {
        addSnack({
          severity: 'warning',
          message: response.message,
          action: null,
          duration: 3000
        })
      }
    } catch (error) {
      addSnack({
        severity: 'error',
        message: (language as any)[currentLanguage].errorMessage,
        action: null,
        duration: 3000
      })
    }
  }

  const createRouteAndDelivery = async (successMessage: string, closeOnError = true) => {
    let phoneNumber = dropoffPhoneNumber
    if(invalidPhoneNumber) {
      phoneNumber = updatedPhoneNumber
    }

    try {
      await DeliveryRouteService.createRouteAndDelivery({
        storeNumber: currentStore,
        invoiceNumber: invoiceDetails?.invoiceNumber ?? '',
        invoiceDateTime: invoiceDateTime.toString(),
        pickupInstructions,
        dropoffInstructions,
        pickupAddress: `${storeAddress.addressLine1} ${storeAddress.addressLine2} ${storeAddress.city}, ${storeAddress.state} ${storeAddress.zipCode}`,
        dropoffAddress,
        dropoffPhoneNumber: phoneNumber,
        orderValueInCents,
        dropoffBusinessName,
        isCOS: isCompanyOwned
      })
      refetchInvoices()
      setInvalidPhoneNumber(false)
      setUpdatedPhoneNumber('')
      handleClose()
      addSnack({
        severity: 'success',
        message: successMessage,
        action: null,
        duration: 3000
      })
    } catch (error: any) {
      if (closeOnError) {
        addSnack({
          severity: 'error',
          message: (language as any)[currentLanguage].errorMessage,
          action: null,
          duration: 3000
        })
      } else {
        setAutoError(true)
        if(error?.response?.data?.message.includes('dropoffPhoneNumber') || error?.response?.data?.message.includes('dropoff_phone_number')) {
          setInvalidPhoneNumber(true)
          if(!!!updatedPhoneNumber && !!dropoffPhoneNumber) {
            if(dropoffPhoneNumber.startsWith("+1")) {
              setUpdatedPhoneNumber(dropoffPhoneNumber)
            } else if(dropoffPhoneNumber.startsWith("1")) {
              setUpdatedPhoneNumber('+' + dropoffPhoneNumber)
            } else {
              setUpdatedPhoneNumber('+1' + dropoffPhoneNumber)
            }
          }
        }
      }
    }
  }

  const stopAutodispatch = async (
    request: any,
    successMessage: string,
    closeOnError = true
  ) => {
    try {
      const response = await DeliveryRouteService.updateInvoiceStatus(
        request,
        currentStore
      )
      refetchInvoices()
      handleClose()
      clearSelectedInvoiceData()
      if (response.status === 'Success') {
        addSnack({
          severity: 'success',
          message: successMessage,
          action: null,
          duration: 3000
        })
      } else {
        addSnack({
          severity: 'warning',
          message: response.message,
          action: null,
          duration: 3000
        })
      }
    } catch (error) {
      if (closeOnError) {
        addSnack({
          severity: 'error',
          message: (language as any)[currentLanguage].errorMessage,
          action: null,
          duration: 3000
        })
      } else {
        setStopError(true)
      }
    }
  }

  const handlePhoneChange = (value: any) => {
    let validPhone = value.replace(/[{()}-]/g, '')
    validPhone = validPhone.replaceAll(' ', '')
    setUpdatedPhoneNumber(validPhone)
  }

  const validatePhone = (phoneNumber: string): boolean => {
    if(isApac){
      return (phoneNumber && phoneNumber.length > 3) ? phoneNumber.length === 12 : true
    }
    if(phoneNumber.length !== 12) {
      return false
    }
    return true
  }

  return (
    <Dialog fullWidth open={openEditInvoice} onClose={handleClose}>
      <Box sx={invoiceDetailStyles.invoiceContent}>
        <Grid container>
          <Grid item xs={11}>
            <Button
              onClick={async () => {
                await handleOnHold()
                refetchInvoices()
              }}
              sx={invoiceDetailStyles.placeHold}>
              <img
                src={invoiceDetails?.nssaInvoiceStatus === 'ON_HOLD' ? OnHoldFlag : InvoicedFlag}
                alt={''}
                style={invoiceDetailStyles.icon}
              />
              {invoiceDetails?.nssaInvoiceStatus === 'ON_HOLD'
                ? (language as any)[currentLanguage].releaseHold
                : (language as any)[currentLanguage].placeHold}
            </Button>
            <Button
              onClick={() => {
                setOpenInvoiceActions(true)
                setInvoiceActionType(ActionType.markAsDelivered)
                setBulkInvoices([{
                  invoiceNumber: invoiceDetails?.invoiceNumber ?? '',
                  invoiceDateTime: invoiceDetails?.invoiceDateTime ?? 0
                }])
              }}
              sx={invoiceDetailStyles.markDelivered}>
              <CheckOutlinedIcon fontSize="medium" />
              {(language as any)[currentLanguage].markDelivered}
            </Button>
            <Button
              onClick={() => {
                setOpenInvoiceActions(true)
                setInvoiceActionType(ActionType.remove)
                setBulkInvoices([{
                  invoiceNumber: invoiceDetails?.invoiceNumber ?? '',
                  invoiceDateTime: invoiceDetails?.invoiceDateTime ?? 0
                }])
              }}
              sx={invoiceDetailStyles.removeButton}>
              <img src={removeIcon} alt={''} style={invoiceDetailStyles.icon} />
              {(language as any)[currentLanguage].remove}
            </Button>
          </Grid>
          <Grid item xs={1} textAlign={'right'}>
            <IconButton onClick={handleClose} sx={invoiceDetailStyles.closeButton}>
              <Clear fontSize="medium" />
            </IconButton>
          </Grid>
        </Grid>

        {autoDispatchEnabled && autoDispatch && stopError && (
          <Alert severity="error" icon={<Warning sx={{ color: Colors.errorBgColor }} />}>
            <AlertTitle sx={invoiceDetailStyles.alertTitle}>
              {(language as any)[currentLanguage].stopAutoDispatchedError}
            </AlertTitle>
            <Typography sx={invoiceDetailStyles.alertDescription}>
              {(language as any)[currentLanguage].pleaseClick}
              <strong>“{(language as any)[currentLanguage].stopDispatchNow}”</strong>{' '}
              {(language as any)[currentLanguage].toTryAgain}.
            </Typography>
          </Alert>
        )}

        {autoDispatchEnabled && autoDispatch && autoError && (
          <>
            <Alert severity="error" icon={<Warning sx={{ color: Colors.errorBgColor }} />}>
              <AlertTitle sx={invoiceDetailStyles.alertTitle}>
                {(language as any)[currentLanguage].autoDispatchedError}
              </AlertTitle>
              <Typography sx={invoiceDetailStyles.alertDescription}>
                {(language as any)[currentLanguage].pleaseClick}{' '}
                <strong>“{(language as any)[currentLanguage].autoDispatchNow}”</strong>{' '}
                {(language as any)[currentLanguage].toTryAgainOrStopAutoDispatchBelow}.
              </Typography>
            </Alert>
          </>
        )}

        {autoDispatchEnabled && autoDispatch && (!!invoiceDetails?.hold) && !autoError && !stopError &&
          <Alert severity="warning" icon={<Warning sx={{ color: Colors.napaYellow3 }} />}>
            <AlertTitle sx={invoiceDetailStyles.alertTitle}>
              {(language as any)[currentLanguage].heldAutodashWarning}
            </AlertTitle>
            <Typography sx={invoiceDetailStyles.alertDescription}>
              {(language as any)[currentLanguage].pleaseClick}
              <strong>“{(language as any)[currentLanguage].autoDispatchNow}”</strong>{' '}
              {(language as any)[currentLanguage].createDD}.
            </Typography>
          </Alert>
        }

        {autoDispatchEnabled && autoDispatch && (
          <Box style={invoiceDetailStyles.renderInvoiceBox}>
            <img src={doordash_modal} alt={''} />
          </Box>
        )}

        <AutoDispatchDetails
          timeElapsed={timeElapsed}
          invoiceDateTime={invoiceDateTime}
          serviceLevel={serviceLevel}
          invoiceDetails={invoiceDetails}
          autoDispatch={autoDispatch}
          lookupResults={lookupResults}
          refetchInvoices={refetchInvoices}
        />

        {autoDispatchEnabled && autoDispatch ? (
          <>
            <Box>
              <h4>Delivery Handling</h4>
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                style={{ width: '100%' }}
                label="Pick Up Note:"
                value={pickupInstructions}
                data-testid="pickUpNoteTextField"
                onChange={(note) => setPickupInstructions(note.target.value)}
                placeholder="Pick Up Note:"
                variant="outlined"
              />
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                style={{ width: '100%', marginTop: '16px' }}
                label="Delivery Note:"
                value={dropoffInstructions}
                data-testid="deliveryNoteTextField"
                onChange={(note) => setDropoffInstructions(note.target.value)}
                placeholder="Delivery Note:"
                variant="outlined"
              />
            </Box>
            {invalidPhoneNumber ?
              <>
              <Box>
                <MuiPhoneNumber
                  defaultCountry={isApac ? 'au' : 'us'}
                  onChange={handlePhoneChange}
                  inputProps={{ 'data-testid': 'phonenumber' }}
                  onlyCountries={isApac ? ['au'] : ['us', 'ca']}
                  error={!validatePhone(updatedPhoneNumber)}
                  type={'text'}
                  countryCodeEditable={false}
                  disableAreaCodes
                  InputProps={{ disableUnderline: true }}
                  value={updatedPhoneNumber}
                  sx={{
                    ...addDriverStyles.phoneNumberField,
                    borderColor: !validatePhone(updatedPhoneNumber) ? '#D32F2F' : '#bfbfbf',
                    marginRight: '5px'
                  }}
                />
                <Typography style={addDriverStyles.errorText}>Third party delivery error: Invalid dropoff phone number. Please enter a valid phone number</Typography>
              </Box>
                </>
              : null}
            <Box sx={invoiceDetailStyles.stopAutoDispatch}>
              <Checkbox
                defaultChecked={stopAutoDispatch}
                data-testid="autoDispatchCheckboxRef"
                style={{ marginTop: '-8px' }}
                onChange={() => setStopAutoDispatch(!stopAutoDispatch)}
                sx={invoiceDetailStyles.stopAutoDispatchCheckBox}
              />
              <img src={deliveryTruck} alt="deliveryTruck" />
              <Box>
                <Box sx={invoiceDetailStyles.stopAutoDispatchTitleText}>
                  {(language as any)[currentLanguage].stopDispatchNow}
                </Box>
                <Box sx={invoiceDetailStyles.stopAutoDispatchText}>
                  {(language as any)[currentLanguage].selectToRemoveTheAutoDispatch}
                </Box>
                {stopAutoDispatch && (
                  <>
                    <Box sx={invoiceDetailStyles.stopAutoDispatchTitleText}>
                      {(language as any)[currentLanguage].reasonRequired}
                    </Box>
                    <Select
                      sx={invoiceDetailStyles.stopAutoDispatchInput}
                      data-testid="autoDispatchSelectRef"
                      onChange={(e) => setReason(e.target.value)}
                      id="outlined-basic"
                      style={{ width: '100%', backgroundColor: 'white' }}
                      placeholder={(language as any)[currentLanguage].selectReason}
                      defaultValue={reason}
                      value={reason}
                      variant="outlined">
                      <MenuItem value={'selectReason'}>
                        {(language as any)[currentLanguage].selectReason}
                      </MenuItem>
                      <MenuItem value={'Customer address hard to find'}>
                        {(language as any)[currentLanguage].reasonCustomerAddress}
                      </MenuItem>
                      <MenuItem value={'Cash customer'}>
                        {(language as any)[currentLanguage].reasonCashCustomer}
                      </MenuItem>
                      <MenuItem value={'Hazardous materials'}>
                        {(language as any)[currentLanguage].reasonHazardMaterials}
                      </MenuItem>
                      <MenuItem value={'Transfer part'}>
                        {(language as any)[currentLanguage].reasonTransferPart}
                      </MenuItem>
                      <MenuItem value={'Part doesn’t meet size specifications'}>
                        {(language as any)[currentLanguage].reasonPartSpecs}
                      </MenuItem>
                      <MenuItem value={'System error'}>
                        {(language as any)[currentLanguage].reasonSystemError}
                      </MenuItem>
                    </Select>
                  </>
                )}
              </Box>
            </Box>
            <Grid container>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleClose()
                  }}
                  sx={invoiceDetailStyles.InvoiceDetailsCloseButton}>
                  {(language as any)[currentLanguage].close}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="primary"
                  data-testid="autoDispatchButtonRef"
                  disabled={stopAutoDispatch ? reason === 'selectReason' : false}
                  onClick={() => {
                    handleAutoDispatch()
                  }}
                  sx={invoiceDetailStyles.PrimaryButton}>
                  {!stopAutoDispatch
                    ? (language as any)[currentLanguage].autoDispatchNow
                    : (language as any)[currentLanguage].stopDispatchNow}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              handleClose()
            }}
            sx={invoiceDetailStyles.PrimaryButton}>
            {(language as any)[currentLanguage].close}
          </Button>
        )}
      </Box>
      {autoDispatchEnabled && autoDispatch && (
        <Box sx={invoiceDetailStyles.doordashContact}>
          {(language as any)[currentLanguage].doordashContact}{' '}
          <Box component="span" color={Colors.napaBlueLink}>
            drive-support@doordash.com.
          </Box>{' '}
          {(language as any)[currentLanguage].ddUrgentCall}
          <Box component="span" color={Colors.napaBlueLink}>
            (855)-973-1040.
          </Box>
        </Box>
      )}
    </Dialog>
  )
}

export default InvoiceDetail
