import { LegacyRef, MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { CreateRoutePreviewMapStyles } from './CreateRoutePreviewMapStyles'
import { DeliveryRoute } from '../../../../utilities/types/DeliveryRouteTypes'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { addStoreMarker, createMarkerElement } from '../../../../components/MapView/MapMarkers'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import MapLegend from '../MapLegend/MapLegend'
import { DeliveryRouteService } from "../../../../utilities/services/DeliveryRouteService";
import { RouteBuilderService } from "../../../../utilities/services/RouteBuilderService";

const getMarkerElem = () => {
  return createMarkerElement({ className: 'marker' })
}

const generateRouteCoordinates = (startCoords: any, endCoords1: any): (number | null)[][] => {
  if (!startCoords || !endCoords1 || endCoords1.length % 2 !== 0) {
    return []
  }
  const routeArray = [
    startCoords,
    ...Array.from({ length: endCoords1.length / 2 }, (_, i) => [
      endCoords1[i * 2],
      endCoords1[i * 2 + 1]
    ])
  ]
  return routeArray
}

const generateSuggestedRouteCoordinates = (suggestedRoute: [] | undefined): [] => {
  let routeArray: any = []
  if (suggestedRoute) {
    suggestedRoute.forEach((coordinate: any) => {
      const plotPoint: Number[] = []
      plotPoint.push(coordinate.longitude)
      plotPoint.push(coordinate.latitude)
      routeArray.push(plotPoint)
    })
  }
  return routeArray
}

interface CreateRoutePreviewMapProps {
  routeDetails?: DeliveryRoute | null
  getMapRouteCoordinates?: ((coordinates: string) => any) | null,
  customDetails?: any
}

const CreateRoutePreviewMap: React.FC<CreateRoutePreviewMapProps> = ({
  routeDetails,
  getMapRouteCoordinates,
  customDetails
}) => {
  const mapContainer = useRef() as MutableRefObject<HTMLElement>
  const map = useRef<mapboxgl.Map | undefined>(undefined)
  const [lng] = useState(-93.581543)
  const [lat] = useState(42.032974)
  const [zoom] = useState(3)
  const { storeAddress } = useContext(StoreContext)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { currentLanguage } = useContext(LanguageContext)

  const [mapHasActualRoute, setMapHasActualRoute] = useState<boolean>(false)
  const [mapHasSuggestedRoute, setMapHasSuggestedRoute] = useState<boolean>(false)
  // const [mapHasBackToStoreRoutes, setMapHasBackToStoreRoutes] = useState<boolean>(false);

  useEffect(() => {
    if (map.current) return
    map.current =
      new mapboxgl.Map({
        container: mapContainer.current as HTMLElement,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom
      }) || null
  }, [lat, lng, zoom])

  useEffect(() => {
    if (map.current) {
      const mapCanvas = map.current.getCanvas()
      if (mapCanvas) {
        mapCanvas.style.cursor = CreateRoutePreviewMapStyles.mapCanvas.cursor
      }
    }
  }, [lat, lng, zoom])

  useEffect(() => {
    if (routeDetails || customDetails) {
      map.current &&
        map.current.on('load', function () {
          // Fix for 'No style loaded'.
          showRoute()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeDetails, customDetails])

  const showRoute = async () => {
    let bounds = new mapboxgl.LngLatBounds()
    //Take single invoice from each stops.
    const uniqueInvoices = routeDetails?.stops
      ?.map((stop) => (stop.invoices ? stop.invoices[0] : ((stop?.stopType === 'custom' || stop?.stopType === "store_transfer") ? stop : null)))
      .filter(Boolean)

    if (routeDetails?.stops && routeDetails.stops[0].stopType === 'store_transfer')
      uniqueInvoices?.shift() // This is to remove first object and make it as Store/Source

    const coordinates = uniqueInvoices?.map((invoice) => {
      return { longitude: invoice?.longitude, latitude: invoice?.latitude }
    })
    //get Custom stop lat and long
    const customStop = customDetails?.map((stop: any) => {
      return { longitude: stop?.longitude, latitude: stop?.latitude }
    })
    //Add store location as starting point.
    const routeplayBackcoordinates = routeDetails?.routePlayback?.map((invoice) => {
      return { longitude: invoice?.longitude, latitude: invoice?.latitude }
    })
    const storeLongitude = routeDetails?.stops && routeDetails?.stops[0]?.stopType === 'store_transfer' ? routeDetails?.stops[0]?.longitude as number : storeAddress.longitude as number
    const storeLatitude = routeDetails?.stops && routeDetails?.stops[0]?.stopType === 'store_transfer' ? routeDetails?.stops[0]?.latitude as number : storeAddress.latitude as number
    let coordinatesString: string = `${storeLongitude},${storeLatitude}`
    bounds.extend([storeLongitude, storeLatitude])
    coordinates?.forEach((coordinate) => {
      coordinatesString += `;${coordinate.longitude},${coordinate.latitude}`
      bounds.extend([coordinate.longitude as number, coordinate.latitude as number])
    })
    customStop?.forEach((coordinate: any) => {
      coordinatesString += `;${coordinate.longitude},${coordinate.latitude}`
      bounds.extend([coordinate.longitude as number, coordinate.latitude as number])
    })
    setMapHasActualRoute(
      routeplayBackcoordinates && routeplayBackcoordinates?.length > 0 ? true : false
    )
    if (routeplayBackcoordinates !== undefined) {
      routeplayBackcoordinates?.forEach((coordinate) => {
        coordinatesString += `;${coordinate.longitude},${coordinate.latitude}`
        bounds.extend([coordinate.longitude as number, coordinate.latitude as number])
      })
      coordinatesString += `;${storeLongitude},${storeLatitude}`
    }
    if (customStop?.length === 1 && coordinates === undefined)
      coordinatesString += `;${storeLongitude},${storeLatitude}`

    if (map.current) map.current.fitBounds(bounds, { padding: 50 })

    try {
      let actualRouteGeoJson = {}
      let actualRouteCoordinates = {}
      const startCoords = [storeLongitude as Number, storeLatitude as Number]
      const endCoords = routeplayBackcoordinates?.flatMap((coord) => [
        coord.longitude,
        coord.latitude
      ])
      if (routeplayBackcoordinates) {
        actualRouteCoordinates = generateRouteCoordinates(startCoords, endCoords)
      }
      actualRouteGeoJson = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: actualRouteCoordinates
        }
      }
      let suggestedRouteGeoJson = {}
      let suggestedRouteCoordinates

      if (routeDetails || customStop) {
        let response = routeDetails && await DeliveryRouteService.getRouteByRouteId(routeDetails.routeId)
        if (response?.suggestedRoute && response?.suggestedRoute?.length > 0) {
          suggestedRouteCoordinates = generateSuggestedRouteCoordinates(response.suggestedRoute);
        } else {
          const response = await RouteBuilderService.getMapRouteCoordinates({ coordinates: coordinatesString })
          if (response) {
            suggestedRouteCoordinates = response.routes[0]?.geometry.coordinates
          }
        }
        setMapHasSuggestedRoute(true)

        suggestedRouteGeoJson = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: suggestedRouteCoordinates
          }
        }
      }

      if (map.current) {
        //plot markers
        uniqueInvoices?.forEach((invoice) => {
          const el = getMarkerElem()
          const coordinate: [number, number] = [
            invoice?.longitude as number,
            invoice?.latitude as number
          ]
          new mapboxgl.Marker(el).setLngLat(coordinate).addTo(map.current as mapboxgl.Map)
        })

        customStop?.forEach((invoice: any) => {
          const el = getMarkerElem()
          const coordinate: [number, number] = [
            invoice?.longitude as number,
            invoice?.latitude as number
          ]
          new mapboxgl.Marker(el).setLngLat(coordinate).addTo(map.current as mapboxgl.Map)
        })

        //Store marker
        addStoreMarker('30px').setLngLat([storeLongitude, storeLatitude]).addTo(map.current)
        //Plot routes
        platLinesActualRoute(actualRouteGeoJson)
        platLinesSuggestedRoute(suggestedRouteGeoJson)
      }
    } catch (error: any) {
      console.error(error.message)
      if (error.message.includes('maximum number of coordinates is 25')) {
        setErrorMessage((language as any)[currentLanguage].errorStops)
      } else {
        setErrorMessage(error.message)
      }
      setTimeout(() => {
        setErrorMessage(null)
      }, 7000)
      throw error
    }
  }

  const platLinesActualRoute = (geoJson: any) => {
    map.current?.addLayer({
      id: 'route',
      type: 'line',
      source: {
        type: 'geojson',
        data: geoJson as any
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#001489',
        'line-width': 2.5,
        'line-opacity': 0.75
      }
    })

    //route arrow direction
    map.current?.addLayer(
      {
        id: 'routearrows',
        type: 'symbol',
        source: 'route',
        layout: {
          'symbol-placement': 'line',
          'text-field': '▶',
          'text-size': ['interpolate', ['linear'], ['zoom'], 12, 24, 22, 60],
          'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 12, 30, 22, 160],
          'text-keep-upright': false
        },
        paint: {
          'text-color': '#3887be',
          'text-halo-color': 'hsl(55, 11%, 96%)',
          'text-halo-width': 3
        }
      },
      'waterway-label'
    )
  }
  const platLinesSuggestedRoute = (geoJson: any) => {
    map.current?.addLayer({
      id: 'suggested',
      type: 'line',
      source: {
        type: 'geojson',
        data: geoJson as any
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#949DD4',
        'line-width': 2.8,
        'line-opacity': .9
      }
    })
    map.current?.addLayer(
      {
        id: 'suggestedarrows',
        type: 'symbol',
        source: 'suggested',
        layout: {
          'symbol-placement': 'line',
          'text-field': '▶',
          'text-size': ['interpolate', ['linear'], ['zoom'], 12, 24, 22, 60],
          'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 12, 30, 22, 160],
          'text-keep-upright': false
        },
        paint: {
          'text-color': '#3887be',
          'text-halo-color': 'hsl(55, 11%, 96%)',
          'text-halo-width': 3
        }
      },
      'waterway-label'
    )
  }

  return (
    <div style={{ ...CreateRoutePreviewMapStyles.mapWithLegendContainer }}>
      <div
        style={{ ...CreateRoutePreviewMapStyles.mapContainer, position: 'relative' }}
        ref={mapContainer as LegacyRef<HTMLDivElement>}>
        {errorMessage && (
          <div style={{ ...CreateRoutePreviewMapStyles.errorContainer, position: 'absolute' }}>
            <div style={CreateRoutePreviewMapStyles.errorBox}>
              <ErrorIcon style={CreateRoutePreviewMapStyles.errorIcon} fontSize="small" />
              {errorMessage}
            </div>
          </div>
        )}
      </div>
      <div style={CreateRoutePreviewMapStyles.mapLegend}>
        <MapLegend
          showActualRouteLegend={mapHasActualRoute}
          showSuggestedRouteLegend={mapHasSuggestedRoute}
          showBackToStoreRoutesLegend={false}
          showRouteDisclaimer={mapHasActualRoute}
        />
      </div>
    </div>
  )
}
export { getMarkerElem, addStoreMarker, generateRouteCoordinates }
export default CreateRoutePreviewMap
