import { Box, Button, Checkbox, MenuItem, Select, TextField, Typography } from "@mui/material"
import { AddressAutofill } from '@mapbox/search-js-react'
import { AddressInfo, americaStateList, CustomerAddressPayload, toCustomerAddressPayload, CustomerInfo, formatLatLang } from "../../../../utilities/services/CustomerService/CustomerService"
import { ReactComponent as PinIcon } from '../../../../assets/pin.svg'
import { ReactComponent as DropOffPin } from '../../../../assets/drop_off_pin.svg'
import { ReactComponent as NotesIcon } from '../../../../assets/notes.svg'
import { customerDetailsStyles } from "../CustomerDetailsStyles"
import React, { useContext, useEffect, useState } from "react"
import { LanguageContext } from "../../../../utilities/contexts/LanguageContext"
import language from "../../../../language/language"
import { StoreContext } from "../../../../utilities/contexts/StoreContext";
import MuiPhoneNumber from "material-ui-phone-number"
import { CustomerAddressStyles } from "./CustomerAddressStyles"

interface InputProps {
    customerInfo: CustomerInfo,
    addressInfo: AddressInfo,
    onSave: (addressUpdate: CustomerAddressPayload) => void
    onCancel: () => void,
    lngLat: [number, number] | null
    setLngLat: (value: [number, number] | null) => void
    index: number
    pinLabel : string | null
    blackPinLngLat : [number, number] | null
    setBlackPinLngLat : (value: [number, number] | null) => void
}

const CustomerAddress: React.FC<InputProps>  = ({ customerInfo, addressInfo, onSave, onCancel, lngLat, setLngLat, index, pinLabel, blackPinLngLat, setBlackPinLngLat}) => {
    const { currentLanguage } = useContext(LanguageContext)
    const { currentStore } = useContext(StoreContext)

    const [formValues, setFormValues] = useState<CustomerAddressPayload>(toCustomerAddressPayload(
        customerInfo.customerId, addressInfo, currentStore
    ))
    const [isFormValid, setFormValid] = useState<boolean>();
    const [needsLineTwo, setNeedsLinetwo] = useState<boolean>(!!formValues.line2 && formValues.line2 !== "");

    useEffect(()=>{
        //reset pin label on drop pin change
        setFormValues(state => {
            return ({
            ...state,
            deliveryGeocodes: !!lngLat && !!state.deliveryGeocodes && state.deliveryGeocodes.length>0 ? 
            [{...state.deliveryGeocodes[0],
                latitude: `${lngLat[1]}`,
                longitude: `${lngLat[0]}`,
                isDefault: 'Y',
            }] : (!!lngLat ? [{
                    latitude: `${lngLat[1]}`,
                    longitude: `${lngLat[0]}`,
                    isDefault: 'Y',
                    pinLabel: '',
                    pinId: undefined
                }] : null)
        })
    })
        
    }, [lngLat])

    const handleChange = (event: {target: any}) => {
        let needsToNullifyGeocodes : boolean = false;
        const eventName = event.target?.name.includes('line1') ? 'line1' : (event.target?.name.includes('line2') ? 'line2' : event.target?.name)
        if(event.target?.name.includes('city') || event.target?.name.includes('zip') || event.target?.name.includes('state') || event.target?.name.includes('line1')) {
            needsToNullifyGeocodes = true
        }
        setFormValues({
            ...formValues,
            latitude: needsToNullifyGeocodes ? '' : formValues.latitude,
            longitude: needsToNullifyGeocodes ? '' : formValues.longitude,
            [eventName]: eventName === 'isPreferredAddress' ? (formValues.isPreferredAddress === 'Y' ? 'N' : 'Y') : event.target?.value
        })
    }

    const handleDeliveryChange = (event: {target: any}) => {
        if(!!formValues.deliveryGeocodes && formValues.deliveryGeocodes.length > 0){      
            setFormValues({
                ...formValues,
                deliveryGeocodes: [
                    {...formValues.deliveryGeocodes[0],
                        [event.target?.name]: event.target?.value
                    }
                ]
            })
        }
    }

    const handlePhoneChange = (value: any) => {
        let validPhone = value.replace(/[+{()}-]/g, '')
        validPhone = validPhone.replaceAll(' ', '')
        setFormValues({...formValues, phoneNumber: validPhone})
      }

    useEffect(()=>{
        setNeedsLinetwo(!!formValues.line2 && formValues.line2 !== "")
        const validateForm = () =>{
            const requiredFields = ['line1', 'city', 'state', 'zip', 'country', 'addressLabel']
            let isValid = true;
            Object.keys(formValues)
                    .filter(key => requiredFields.indexOf(key) !== -1)
                    .forEach((key) => {
                        if (isValid) isValid = !!(formValues as any)[key] && (formValues as any)[key] !== ''
                    })

            if(isValid && (!!!formValues.latitude || formValues.latitude === "" || !!!formValues.longitude || formValues.longitude === "")) isValid = false
            
            if (isValid && !!formValues.deliveryGeocodes && !!formValues.deliveryGeocodes[0] && (!!!formValues.deliveryGeocodes?.at(0)?.pinLabel || formValues.deliveryGeocodes?.[0]?.pinLabel === '')) isValid = false
            if (isValid && (formValues.isPreferredAddress === 'Y' ? formValues.phoneNumber?.length < 11 : formValues.phoneNumber?.length > 1 && formValues.phoneNumber?.length < 11)) isValid = false
            setFormValid(isValid)
        }
        validateForm()
    }, [formValues, lngLat])

    const handleAutofillRetrieve = (response: any) => {
        const addressLine1 = response.features[0]?.properties?.address_line1;
        const city = response.features[0]?.properties?.place;
        const stateCode = response.features[0]?.properties?.region_code;
        const zipcode = response.features[0]?.properties?.postcode;
        const coordinates = response.features[0]?.geometry.coordinates;
        // const street = response.features[0]?.properties?.street || 'none'
        setBlackPinLngLat([coordinates[0], coordinates[1]])
        setLngLat(null)
        setFormValues({
            ...formValues,
            latitude: coordinates[1],
            longitude: coordinates[0],
            line1: addressLine1,
            line2: null,
            city: city,
            state: stateCode,
            zip: zipcode,
            deliveryGeocodes: null,
            notes: null 
        })
    }

    return <Box sx={CustomerAddressStyles.container}>
        <Typography sx={customerDetailsStyles.typoHeader5}>
        {(language as any)[currentLanguage].location} {index + 1}: {addressInfo?.addressLabel} {!!pinLabel && `- ${pinLabel}`}
        </Typography>
        <Box sx={CustomerAddressStyles.containerRow}>
            <Typography sx={CustomerAddressStyles.addressHeader}>
                <PinIcon />
                <Typography sx={customerDetailsStyles.addressLabel}>{(language as any)[currentLanguage].address}</Typography>
                <Typography sx={customerDetailsStyles.defaultAddress}>
                    {addressInfo.isPreferredAddress !== 'Y' && <Checkbox 
                        name="isPreferredAddress"
                        checked={formValues.isPreferredAddress === 'Y'} 
                        disableRipple  
                        sx={CustomerAddressStyles.checkbox}
                        onChange={handleChange}
                    />}
                    Default Location
                </Typography>
            </Typography>
            <Box sx={CustomerAddressStyles.addressContainer}>
                <Box sx={CustomerAddressStyles.addressRow}>
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].locationName} (required)
                    </Typography>
                    <TextField placeholder="ex: business - site #2" size="medium" fullWidth
                        name="addressLabel"
                        value={formValues.addressLabel}
                        onChange={handleChange}
                        inputProps={{ maxLength: 50 }}
                        helperText={`${50 - (formValues.addressLabel?.length || 0)} characters remaining`}
                        FormHelperTextProps={{
                            sx: CustomerAddressStyles.helperText
                        }}/>
                </Box>
                <Box sx={CustomerAddressStyles.addressRow}>
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].addressLine} 1
                    </Typography>
                    <Box sx={CustomerAddressStyles.autofillContainer}>
                        <AddressAutofill
                        accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''}
                            onRetrieve={handleAutofillRetrieve}
                            
                        >
                        <TextField size="medium"
                            name="line1"
                            value={formValues.line1}
                            autoComplete="address-line1"
                            fullWidth
                            onChange={handleChange}></TextField>
                        </AddressAutofill>
                        </Box>
                    {!needsLineTwo && <Button sx={customerDetailsStyles.paragraph2Link} onClick={() => setNeedsLinetwo(true)} variant="text">
                        {(language as any)[currentLanguage].optionalAddress}
                    </Button>}
                </Box>
                {!!needsLineTwo && <Box sx={CustomerAddressStyles.addressRow}>
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].addressLine} 2
                    </Typography>
                    <TextField placeholder="ex: Shop #329" size="medium" fullWidth
                        name="line2"
                        value={formValues.line2}
                        inputProps={{ maxLength: 50 }}
                        onChange={handleChange}
                        helperText={`${50 - (formValues.line2?.length || 0)} characters remaining`}
                        FormHelperTextProps={{
                           sx: CustomerAddressStyles.helperText
                        }}
                ></TextField>
                </Box> }
                <Box sx={CustomerAddressStyles.specialAddressRow}>
                    <Box sx={CustomerAddressStyles.cityContainer}>
                        <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].city}
                        </Typography>
                        <TextField size="medium" fullWidth
                            name="city"
                            value={formValues.city}
                            onChange={handleChange}></TextField>
                    </Box>
                    <Box sx={CustomerAddressStyles.stateContainer}>
                        <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].state}
                        </Typography>
                        <Select sx={CustomerAddressStyles.stateSelector} size="small"
                            name={"state"}
                            value={formValues.state}
                            onChange={handleChange}>
                            {americaStateList.map((state)=>{
                                return <MenuItem value={state.value}>{state.value}</MenuItem>
                            })}
                        </Select>
                    </Box>
                    <Box sx={CustomerAddressStyles.zipContainer}>
                        <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].zipCode}
                        </Typography>
                        <TextField size="medium"
                            name={"zip"}
                            value={formValues.zip}
                            onChange={handleChange}></TextField>
                    </Box>
                </Box>
                <Box sx={CustomerAddressStyles.addressRow}>
                    <Typography sx={customerDetailsStyles.paragraph2}>
                            {(language as any)[currentLanguage].phoneNumber}
                            {formValues.isPreferredAddress === 'Y' ? ' (required)' : ''}
                    </Typography>
                    <MuiPhoneNumber
                        defaultCountry={'us'}
                        onChange={handlePhoneChange}
                        inputProps={{ 'data-testid': 'phonenumber' }}
                        error={formValues.isPreferredAddress === 'Y' ? formValues.phoneNumber?.length < 11 : formValues.phoneNumber?.length > 1 && formValues.phoneNumber?.length < 11}
                        type={'text'}
                        countryCodeEditable={false}
                        onlyCountries={['us']}
                        disableDropdown
                        InputProps={{ disableUnderline: true }}
                        value={formValues.phoneNumber}
                        sx={{
                            ...CustomerAddressStyles.phoneInput,
                            borderColor: (formValues.isPreferredAddress === 'Y' ? formValues.phoneNumber?.length < 11 : formValues.phoneNumber?.length > 1 && formValues.phoneNumber?.length < 11) ? '#D32F2F' : '#bfbfbf'
                        }}
                    />
                </Box>
            </Box>
        </Box>
        <Box sx={CustomerAddressStyles.containerRow}>
            <Box sx={CustomerAddressStyles.dropOffContainer}>
                <Checkbox sx={CustomerAddressStyles.checkbox} disabled={!!!blackPinLngLat} checked={!!!lngLat} onChange={(event)=>{
                    if(!!event.target.checked){
                        setLngLat(null)
                    }
                    else {
                        setLngLat(blackPinLngLat)
                    }
                }}></Checkbox>
                <Typography sx={customerDetailsStyles.paragraph2}>
                    {(language as any)[currentLanguage].dropOffSameLocation}
                </Typography>
            </Box>
            {!!lngLat && <Box sx={CustomerAddressStyles.dropOffEditor}>
                <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
                    <DropOffPin />
                    {(language as any)[currentLanguage].dropOffSpot}
                </Typography>
                <Box sx={CustomerAddressStyles.dropOffEditor}>
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {(language as any)[currentLanguage].dropPinMapDesc}
                    </Typography>
                    <Box sx={CustomerAddressStyles.coordinatesinfo}>
                        <Typography sx={customerDetailsStyles.paragraphBold2}>
                            {(language as any)[currentLanguage].coordinates} 
                        </Typography>
                        <Typography sx={customerDetailsStyles.paragraph2}>{formatLatLang(!!lngLat ? lngLat[1] : 0,!!lngLat ? lngLat[0] : 0)}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={customerDetailsStyles.paragraphBold2}>
                            Drop-off name (required)
                        </Typography>
                        <TextField fullWidth size="medium" placeholder="ex: back of store"
                            name={'pinLabel'}
                            value={formValues.deliveryGeocodes?.at(0)?.pinLabel}
                            onChange={handleDeliveryChange}
                            inputProps={{ maxLength: 50 }}
                            helperText={`${50 - (formValues.deliveryGeocodes?.at(0)?.pinLabel?.length || 0)} characters remaining`}
                            FormHelperTextProps={{
                               sx: CustomerAddressStyles.helperText
                            }}
                        />
                    </Box>
                </Box>
            </Box>}
        </Box>

        <Box sx={CustomerAddressStyles.notesContainer}>
            <Typography sx={customerDetailsStyles.paragraphBold2} display={"flex"} alignItems={"center"} gap={"6px"}>
                <NotesIcon />
                {(language as any)[currentLanguage].deliveryNotes}
            </Typography>
            <Box sx={CustomerAddressStyles.notesInput}>
                <TextField fullWidth size="small" placeholder="ex: all deliveries at back entrance"
                    multiline
                    name={'notes'}
                    value={formValues.notes}
                    onChange={handleChange}
                    inputProps={{ maxLength: 400 }}
                    helperText={`${400 - (formValues.notes?.length || 0)} characters remaining`}
                    FormHelperTextProps={{
                       sx: CustomerAddressStyles.helperText
                    }}
                />
            </Box>
        </Box>
        <Box sx={CustomerAddressStyles.buttonContainer}>
            {/*NOT FOR PILOT <Button size="small" variant="contained" sx={{ background: Colors.napaRed4 }}
                disabled={!addressInfo.addressId}>
                {(language as any)[currentLanguage].delete} {(language as any)[currentLanguage].location}
            </Button> */}
            <Button sx={{width: "70px"}} size="small" variant="outlined" onClick={onCancel}>
                {(language as any)[currentLanguage].cancel}
            </Button>
            <Button sx={{width: "70px"}} size="small" variant="primary" onClick={()=>{
                    onSave(formValues)
                }} disabled={!isFormValid}>
                {(language as any)[currentLanguage].save}
            </Button>
        </Box>
    </Box>
}

export default CustomerAddress