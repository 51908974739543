import Cookies from 'js-cookie'

export const getCookie = (name: string) => {
  return Cookies.get(name)
}

export const getCookieBoolean = (name: string): boolean => {
  return Cookies.get(name) === 'true'
}

export const setCookie = (name: string, value: string) => {
  Cookies.set(name, value, { sameSite: 'None', secure: true, expires: 1, domain: '.genpt.com' })
}

export const removeCookie = (name: string) => {
  Cookies.remove(name, { sameSite: 'None', secure: true, expires: 1, domain: '.genpt.com' })
}

// added buffer time to call the token before 5 minutes
export const bufferTime = 300

export const calculateExpireTime = (time: string): number => {
  return (Number(time) - bufferTime) * 1000
}

// Unix time stamp to mili second conversion both for seconds (10 chars) and mili seconds(13 chars)
export const unixToMiliSec = (time: number): number => {
  const length = time.toString().length
  const timeParam = length === 10 ? time * 1000 : time
  const currentTime = Math.floor(Date.now())
  return timeParam - currentTime
}

// Second to unix time stamp conversion
export const secondToUnix = (time: string): string => {
  const currentTime = Math.floor(Date.now() / 1000)
  return (Number(time) + currentTime).toString()
}

export const clearCustomCookies = () => {
  removeCookie('authenticated')
  removeCookie('isCOS')
  removeCookie('expires_in')
}

export const clearCookieAndRedirectHome = () => {
  clearCustomCookies()
  window.location.href = process.env.REACT_APP_USAG_HOME_URL ?? '/'
}
