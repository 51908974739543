import Colors from '../../../../assets/Colors'

export const externalDeliveryStyles = {
    renderInvoiceBox: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '12px'
    },
    invoiceDeliveryAddress: {
        color: Colors.napaBlue,
        fontWeight: '400',
        fontSize: '16px',
        fontFamily: 'NAPA SANS'
    },
    invoiceAddressLineType: {
        color: Colors.napaGrey,
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '15px',
        fontFamily: 'NAPA SANS'
    },
    invoiceNumber: {
        color: Colors.napaGrey2,
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '15px',
        fontFamily: 'NAPA SANS'
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    paddingZero: {
        paddingTop: 0
    },
    thirdPartyETA: {
        fontSize: '14px',
        lineHeight: '19.5px',
        fontFamily: 'NAPA SANS',
        paddingBottom: '8px',
    },
    greenText: {
        color: Colors.napaGreenDark,
        fontWeight: '700'
    },
    blackText: {
        color: Colors.black,
        fontWeight: '400',
    },
    routeReviewTypography: {
        color: Colors.napaBlue,
        fontWeight: '700',
        fontSize: '22px',
        textAlign: 'center',
        fontFamily: 'NAPA SANS'
    },
    renderInvoice: {
        border: '1px solid rgb(193,193,193)',
        paddingLeft: 10,
        marginBottom: '5px',
        borderRadius: '5px'
    },
    externalDialogbuttons: {
        padding: '16px 0px'
    },
    costText: {
        fontWeight: '700',
        fontSize: '14px',
        color: Colors.black
    },
    pickupInstructions: {
        width: '100%',
        height: '48px',
        marginBottom: '20px',
        marginTop: '0px'
    },
    deliveryInstructions: {
        width: '100%',
        height: '48px'
    },
    editButton: {
        color: Colors.napaBlueLink,
        textTransform: 'none'
    },
    signatureContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 10px 10px 0px',
        justifyContent: 'space-between',
        padding: '0px 10px'
    },
    printNameHeader: {
        color: Colors.napaBlack,
        fontSize: '14px',
        fontWeight: 600,
        width: '100%'
    },
    printName: {
        color: Colors.napaBlack,
        fontSize: '14px'
    },
    signatureHeader: { color: Colors.napaBlack, fontSize: '14px' },
    signature: { width: '100px', height: '80px'},
    signatureText: {color: Colors.napaBlack},
    storePadding: {
      marginLeft: '25px',
      padding: '0px 0px 8px 0px'
    },
    storeContainer: {
      padding: '8px 0px 0px'
    }
}