import Colors from '../../../../assets/Colors'

type ThirdPartyTransferModalStylesType = {
  modal: React.CSSProperties
  rootContainer: React.CSSProperties
  rootTitleContainer: React.CSSProperties
  cardRootContainer: React.CSSProperties
  cardTitleContainer: React.CSSProperties
  cardTitleSubGroupContainer: React.CSSProperties
  cardBodyContainer: React.CSSProperties
  buttonContainer: React.CSSProperties
  buttonStyle: React.CSSProperties
  selectButtonStyle: React.CSSProperties
  offsetText: React.CSSProperties
  helperText: React.CSSProperties
}

export const ThirdPartyTransferModalStyles: ThirdPartyTransferModalStylesType = {
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-between',
    padding: '16px',
    backgroundColor: Colors.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    width: '480px',
    height: '499px'
  },
  rootTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '35px'
  },
  cardRootContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    border: '1px',
    borderRadius: '5px',
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    paddingBlock: '8px',
    paddingInline: '16px',
    backgroundColor: Colors.napaGreyDisabled,
    margin: '5px'
  },
  cardTitleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  cardTitleSubGroupContainer: { display: 'flex', flexDirection: 'row' },
  cardBodyContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    border: '1px',
    borderRadius: '5px',
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    margin: '6px',
    paddingLeft: '68px'
  },
  buttonContainer: { display: 'flex', justifyContent: 'end' },
  buttonStyle: { width: 'auto', height: '40px', margin: '9px' },
  selectButtonStyle: { width: 'auto', height: 'auto' },
  offsetText: { marginLeft: '16px'},
  helperText: {color: Colors.napaRed}
}
