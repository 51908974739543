import axiosInstance from '../../helpers/AxiosHelper'

export interface UserDataTypes {
  givenName: string
  familyName: string
  username: string
  email: string
  defaultStoreNumber: string
  phoneNumber: string
  userType: string
  storeList: string[]
  enabled: boolean
  createdDate: string
  cometChatUUID: string
}

export interface pingUserDataTypes {
  given_name: string
  family_name: string
  username: string
  groups: string[]
  phone_number: string
  UserPrincipalName: string
  name: string
  refreshTokenExpiryTime: number
}

export async function getUserDetailsByUserName(userName: string): Promise<UserDataTypes> {
  try {
    const response = await axiosInstance.get(`/users/v2/username/${userName}`)
    if (response.status === 200) {
      return response.data
    }
    return Promise.reject(`[${response.status}]: ${response.data}`)
  } catch (e) {
    return Promise.reject(e)
  }
}
