import { Button, IconButton, Menu, MenuItem, Modal, Typography, FormControl, FormHelperText } from '@mui/material'
import React, { useContext, useState } from 'react'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import language from '../../../../language/language'
import { ThirdPartyTransferModalStyles } from './ThirdPartyTransferModalStylesType'
import { Address, DeliveryRoute, TransferStop } from '../../../../utilities/types/DeliveryRouteTypes'
import Colors from '../../../../assets/Colors'
import { KeyboardArrowDownSharp, StoreOutlined } from '@mui/icons-material'
import { invoiceListStyles } from '../InvoiceList/invoiceListStyles'
import CloseIcon from '@mui/icons-material/Close'
import TransferCustomerSearch from '../TransferCustomerSearch/TransferCustomerSearch'
import { DeliveryRouteService, StoreTransferRequest } from '../../../../utilities/services/DeliveryRouteService'
import TransferStoreSearch from '../TransferStoreSearch/TransferStoreSearch'
import { SnackbarContext } from '../../../../utilities/contexts/SnackbarContext'

type ThirdPartyTransferModalType = {
  isOpen: boolean
  closeModalCallback: () => void
  setEditedRouteData: (value: DeliveryRoute) => void
  setOpenEditRoute: (value: boolean) => void
  setRoute: (value: DeliveryRoute) => void
}

const ThirdPartyTransferModal = ({ isOpen, closeModalCallback, setEditedRouteData, setOpenEditRoute, setRoute }: ThirdPartyTransferModalType) => {
  const { storeAddress, currentStore } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)
  const { addSnack } = useContext(SnackbarContext)
  const [openStoreSearch, setOpenStoreSearch] = useState<boolean>(false)
  const [openCustomerSearch, setOpenCustomerSearch] = useState<boolean>(false)
  const [sourceAddress, setSourceAddress] = useState<TransferStop | null>(null)
  const [destinationAddress, setDestinationAddress] = useState<TransferStop | null>(null)
  const [isSourceChange, setIsSourceChange] = useState<boolean>(false)

  const closeModalHandler = () => {
    setOpenCustomerSearch(false)
    setOpenStoreSearch(false)
    setDestinationAddress(null)
    setSourceAddress(null)
    setIsSourceChange(false)
    closeModalCallback()
  }

  const buildTransferHandler = async () => {
      let source = {
        storeNumber: sourceAddress ? sourceAddress.storeNumber ?? '' : currentStore,
        stopName: sourceAddress ? sourceAddress.businessName ?? '' : storeAddress.name ?? '',
        addressLine1: sourceAddress ? sourceAddress.addressLine1 : storeAddress.addressLine1,
        zipCode: sourceAddress ? sourceAddress.zipCode : storeAddress.zipCode,
        city: sourceAddress ? sourceAddress.city : storeAddress.city,
        state: sourceAddress ? sourceAddress.state : storeAddress.state ,
        latitude: sourceAddress ? sourceAddress.latitude : storeAddress.latitude,
        longitude: sourceAddress ? sourceAddress.longitude : storeAddress.longitude,
        stopNotes: sourceAddress ? sourceAddress.stopNotes || '' : '',
        creationTimestamp: null,
        addressLine2: sourceAddress ? sourceAddress.addressLine2 : storeAddress.addressLine2,
        name: sourceAddress ? sourceAddress.businessName ?? '' : storeAddress.name ?? '',
        phoneNumber: sourceAddress ? sourceAddress.phoneNumber ?? '' : storeAddress.phoneNumber ?? ''
      }
     let destination = {
        storeNumber: destinationAddress?.storeNumber || currentStore,
        stopName: destinationAddress?.businessName ?? '',
        addressLine1: destinationAddress?.addressLine1 ?? '',
        zipCode: destinationAddress?.zipCode ?? '',
        city: destinationAddress?.city ?? '',
        state: destinationAddress?.state ?? '',
        latitude: destinationAddress?.latitude,
        longitude: destinationAddress?.longitude,
        stopNotes: destinationAddress?.stopNotes || 'null',
        creationTimestamp: null,
        addressLine2: destinationAddress?.addressLine2 ?? '',
        name: destinationAddress?.businessName ?? '',
        phoneNumber: destinationAddress?.phoneNumber ?? ''
      }
    if (destination && source) {
      try {
        const request = {
          storeNumber: currentStore,
          routeStatus: 'created',
          deliveryDriver: null,
          customStops: [source, destination]
        } as StoreTransferRequest
        const response = await DeliveryRouteService.dispatchStoreTransfer(request)
        setRoute(response)
        setEditedRouteData(response)
        closeModalHandler()
        setOpenEditRoute(true)
      }
      catch (error: any) {
        addSnack({ severity: 'error', message: error?.response?.message || error?.response?.data?.message, duration: 3000 })
      }
    }
  }

  const closeCustomerSearchCallback = () => {
    setOpenCustomerSearch(false)
  }

  return (
    <Modal open={isOpen} onClose={closeModalHandler} sx={ThirdPartyTransferModalStyles.modal}>
      <div style={ThirdPartyTransferModalStyles.rootContainer}>
        {openStoreSearch && <TransferStoreSearch setAddress={isSourceChange ? setSourceAddress : setDestinationAddress} closeSearchCallback={() => setOpenStoreSearch(false)} />}
        {openCustomerSearch && <TransferCustomerSearch setDestinationAddress={setDestinationAddress} closeSearchCallback={closeCustomerSearchCallback} />}
        {!openStoreSearch && !openCustomerSearch && <>
          <div style={ThirdPartyTransferModalStyles.rootTitleContainer}>
            <Typography fontSize={'22px'} color={Colors.napaBlue}>
              {language[currentLanguage].thirdPartyTransfer}
            </Typography>
            <IconButton aria-label="close" onClick={closeModalHandler}>
              <CloseIcon sx={invoiceListStyles.closeIconImg} />
            </IconButton>
          </div>
          <SourceCard storeAddress={storeAddress} sourceAddress={sourceAddress} setOpenStoreSearch={setOpenStoreSearch} setIsSourceChange={setIsSourceChange} />
          <DestinationCard setOpenStoreSearch={setOpenStoreSearch} setOpenCustomerSearch={setOpenCustomerSearch} destinationAddress={destinationAddress} setIsSourceChange={setIsSourceChange} />
          <div style={ThirdPartyTransferModalStyles.buttonContainer}>
            <Button
              variant="secondary"
              sx={ThirdPartyTransferModalStyles.buttonStyle}
              onClick={closeModalHandler}>
              {language[currentLanguage].cancel}
            </Button>
            <FormControl>
              <Button
                variant="primary"
                onClick={buildTransferHandler}
                sx={ThirdPartyTransferModalStyles.buttonStyle}
                disabled={(!storeAddress && !sourceAddress)
                  || !destinationAddress
                  || (!sourceAddress ? currentStore === destinationAddress?.storeNumber : sourceAddress?.storeNumber === destinationAddress?.storeNumber)}
                >
                {language[currentLanguage].buildTransfer}
              </Button>
              {(!!storeAddress || !!sourceAddress) && !!destinationAddress && (!sourceAddress ? currentStore === destinationAddress?.storeNumber : sourceAddress?.storeNumber === destinationAddress?.storeNumber)
                && <FormHelperText sx={ThirdPartyTransferModalStyles.helperText} id="submit-helper-text">source and destination is same</FormHelperText>}
            </FormControl>
          </div>
        </>}
      </div>
    </Modal>
  )
}

const SourceCard = ({ storeAddress, sourceAddress, setIsSourceChange, setOpenStoreSearch }: { storeAddress: Address, sourceAddress: TransferStop | null, setIsSourceChange: (value: boolean) => void, setOpenStoreSearch: (value: boolean) => void }) => {
  const editSourceHandler = () => {
    setIsSourceChange(true)
    setOpenStoreSearch(true)
  }

  const { currentLanguage } = useContext(LanguageContext)
  return (
    <div style={ThirdPartyTransferModalStyles.cardRootContainer}>
      <div style={ThirdPartyTransferModalStyles.cardTitleContainer}>
        <div style={ThirdPartyTransferModalStyles.cardTitleSubGroupContainer}>
          <StoreOutlined />
          <Typography>{language[currentLanguage].source}</Typography>
        </div>
        <Button onClick={editSourceHandler}>{language[currentLanguage].edit}</Button>
      </div>
      {!sourceAddress && <div style={ThirdPartyTransferModalStyles.cardBodyContainer}>
        {/*TODO: Gotta fix this to be the businessName once we populate that somehow in the response object, either need to get the store location through our API or save the business name*/}
        <div style={ThirdPartyTransferModalStyles.offsetText}>
          <Typography>{storeAddress.addressLine1}</Typography>
          {storeAddress.addressLine2 ? <Typography>{storeAddress.addressLine2}</Typography> : null}
          <Typography>{`${storeAddress.city}, ${storeAddress.state}, ${storeAddress.zipCode}`}</Typography>
        </div>
      </div>}
      {sourceAddress && <div style={ThirdPartyTransferModalStyles.cardBodyContainer}>
        <Typography fontWeight="bold">{sourceAddress.businessName}</Typography>
        <div style={ThirdPartyTransferModalStyles.offsetText}>
          <Typography>{sourceAddress.addressLine1}</Typography>
          {sourceAddress.addressLine2 ? <Typography>{sourceAddress.addressLine2}</Typography> : null}
          <Typography>{`${sourceAddress.city}, ${sourceAddress.state}, ${sourceAddress.zipCode}`}</Typography>
        </div>
      </div>}
    </div>
  )
}

const DestinationCard = ({ setOpenStoreSearch, setOpenCustomerSearch, destinationAddress, setIsSourceChange }: { setOpenStoreSearch: (value: boolean) => void, setOpenCustomerSearch: (value: boolean) => void, destinationAddress: TransferStop | null, setIsSourceChange: (value: boolean) => void}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const editDestinationHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const { currentLanguage } = useContext(LanguageContext)
  return (
    <div style={ThirdPartyTransferModalStyles.cardRootContainer}>
      <div style={ThirdPartyTransferModalStyles.cardTitleContainer}>
        <div style={ThirdPartyTransferModalStyles.cardTitleSubGroupContainer}>
          <StoreOutlined />
          <Typography>{language[currentLanguage].destination}</Typography>
        </div>
        <Button
          onClick={editDestinationHandler}
          variant="primary"
          sx={ThirdPartyTransferModalStyles.selectButtonStyle}>
          {language[currentLanguage].select}
          <KeyboardArrowDownSharp />
        </Button>
      </div>
      <div style={ThirdPartyTransferModalStyles.cardBodyContainer}>
        {!!destinationAddress &&
          <>
            <Typography fontWeight="bold">{destinationAddress.businessName}</Typography>
            <div style={ThirdPartyTransferModalStyles.offsetText}>
              <Typography>{destinationAddress.addressLine1}</Typography>
              {destinationAddress.addressLine2 ? <Typography>{destinationAddress.addressLine2}</Typography> : null}
              <Typography>{`${destinationAddress.city}, ${destinationAddress.state}, ${destinationAddress.zipCode}`}</Typography>
            </div>
          </>
        }
      </div>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => { setIsSourceChange(false); setOpenStoreSearch(true) }}>{language[currentLanguage].storeString}</MenuItem>
        <MenuItem onClick={() => { setOpenCustomerSearch(true) }}>{language[currentLanguage].customer}</MenuItem>
      </Menu>
    </div>
  )
}

export default ThirdPartyTransferModal