import { Button, Card, CardContent, Grid, IconButton, Modal, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Box } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { editAddInvoicesStyles } from './editAddInvoicesStyles'
import { getFormattedDate, msToTime } from '../../../../utilities/helpers/DateTimeFormatters'
import { invoiceListStyles } from '../InvoiceList/invoiceListStyles'
import { InvoicesResponse } from '../../../../utilities/types/DeliveryRouteTypes'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import colors from '../../../../assets/Colors'
import { useInvoicesByStore } from '../../../../queries/Invoices/useInvoicesByStore'
import { RouteBuilderContext } from '../../RouteBuilder'
import { isApac } from '../../../../utilities/helpers/RegionHelper'


type EditAddInvoicesProps = {
  openEditAddInvoices: boolean
  setOpenAddInvoices: (openAddInvoices: boolean) => void
  setEditAddInvoices: Dispatch<SetStateAction<any[]>>
  setEditAddInvoiceData: Dispatch<SetStateAction<InvoicesResponse[]>>
  editAddInvoices: string[]
}
const EditAddInvoices = ({
  openEditAddInvoices,
  setOpenAddInvoices,
  setEditAddInvoices,
  setEditAddInvoiceData,
  editAddInvoices,
}: EditAddInvoicesProps) => {


  const { startDate, endDate } =
    useContext(RouteBuilderContext)
  const { data: invoices, isError: isErrorInvoices } = useInvoicesByStore(startDate, endDate)
  const { currentLanguage } = useContext(LanguageContext)
  const columns = [
    {
      field: 'invoice',
      headerName: (language as any)[currentLanguage].invoice.toUpperCase(),
      minWidth: 150,
      editable: false
    },
    {
      field: 'customer',
      headerName: (language as any)[currentLanguage].customerText,
      minWidth: 150,
      editable: false
    },
    {
      field: 'orderedAt',
      headerName: (language as any)[currentLanguage].orderedAt.toUpperCase(),
      type: 'date',
      minWidth: 150,
      editable: false
    },
    {
      field: 'timeElapsed',
      headerName: (language as any)[currentLanguage].timeElapsed.toUpperCase(),
      minWidth: 150,
      editable: false
    },
    {
      field: 'status',
      headerName: 'STATUS',
      minWidth: 150,
      editable: false
    }
  ]

  const NoInvoicesCard = () => {
    return (
      <Card sx={invoiceListStyles.noInvoicesCard}>
        <CardContent>
          <div style={invoiceListStyles.noInvoicesCardContent}>
            <Typography sx={invoiceListStyles.noInvoices}>
              {(language as any)[currentLanguage].noInvoices}
            </Typography>
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
      <Modal
        open={openEditAddInvoices}
        onClose={() => setOpenAddInvoices(false)}
        sx={editAddInvoicesStyles.addInvoiceModal}>
        <Grid
          container
          sx={[editAddInvoicesStyles.container, editAddInvoicesStyles.addInvoiceContainer]}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={editAddInvoicesStyles.addInvoiceHeader}>
              {(language as any)[currentLanguage].addInvoices}
            </Typography>
            <IconButton
              onClick={() => {
                setOpenAddInvoices(false)
                setEditAddInvoices([])
                setEditAddInvoiceData([])
              }}>
              <ClearIcon fontSize="medium" />
            </IconButton>
          </Grid>
          <Box sx={{ width: '100%' }}>
            {invoices && invoices?.length > 0 && !isErrorInvoices ? (
              <DataGrid
                components={{
                  Footer: () => {
                    return (
                      <Typography
                        align={'center'}
                        sx={{
                          color: colors.napaGrey4,
                          fontWeight: '400',
                          fontSize: '12px',
                          padding: '16px'
                        }}>
                      </Typography>
                    )
                  }
                }}
                disableColumnSelector={true}
                style={{ height: `500px`, width: '100%' }}
                rows={
                  invoices
                    ?.filter((invoice: InvoicesResponse) =>  invoice.requiresAddressUpdate !== true)
                    .map((invoice: any) => {
                      return {
                        id: isApac ? invoice.invoiceNumber : Number(invoice.invoiceNumber),
                        invoice: invoice.invoiceNumber,
                        customer: invoice.deliveryAddress.name,
                        orderedAt: getFormattedDate(invoice.invoiceDateTime),
                        timeElapsed: msToTime(Date.now() - invoice.invoiceDateTime),
                        status: invoice.nssaInvoiceStatus === 'ON_HOLD' ? 'ON HOLD' : invoice.nssaInvoiceStatus
                      }
                    }) || []
                }
                columns={columns}
                checkboxSelection
                disableSelectionOnClick
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'orderedAt', sort: 'asc' }]
                  }
                }}
                hideFooterPagination={true}
                hideFooterSelectedRowCount={true}
                disableVirtualization={process.env.NODE_ENV === 'test'}
                onSelectionModelChange={(newSelectionModel) => {
                  setEditAddInvoices(newSelectionModel)
                }}
                selectionModel={editAddInvoices}
                sx={editAddInvoicesStyles.gridSize}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 }
                  }
                }}
              />
            ) : (
              <Box>
                <NoInvoicesCard />
              </Box>
            )}
          </Box>
          <Box sx={editAddInvoicesStyles.saveCancelButtonBox}>
            <Button
              variant={'secondary'}
              onClick={() => setOpenAddInvoices(false)}
              sx={{ width: '45%', marginRight: '10px' }}>
              {(language as any)[currentLanguage].cancel}
            </Button>
            <Button
              disabled={!invoices || invoices.length === 0|| isErrorInvoices}
              onClick={() => {
                setEditAddInvoiceData([])
                setOpenAddInvoices(false)
                if (invoices) {
                  if (editAddInvoices.length > 0) {
                    editAddInvoices?.map((editAddInvoice: string) => {
                      const data = invoices?.filter(
                        (invoice: InvoicesResponse) =>
                          invoice.invoiceNumber === editAddInvoice.toString()
                      )
                      setEditAddInvoiceData((editAddInvoiceData: InvoicesResponse[]) => [
                        ...editAddInvoiceData,
                        data[0]
                      ])
                      return true
                    })
                  }
                }
              }}
              variant={'primary'}
              sx={{ width: '45%' }}>
              {(language as any)[currentLanguage].saveInvoices}
            </Button>
          </Box>
        </Grid>
      </Modal>
    </Grid>
  )
}
export default EditAddInvoices
