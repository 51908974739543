import { Box, Typography, Link, Stack, AvatarGroup, Avatar } from '@mui/material'
import mapboxgl from 'mapbox-gl'
import Colors from '../../assets/Colors'
import { useNavigate } from 'react-router-dom'
import { LegacyRef, MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client';
import { styles } from './MapViewStyles'
import { StoreContext } from '../../utilities/contexts/StoreContext'
import { addStoreMarker, createMarkerElement } from '../../components/MapView/MapMarkers'
import * as geolib from 'geolib';
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'
import { centerLngLat } from '../../utilities/helpers/RegionHelper'

type Props = {
  activeUsers?: any[]
};

const availableColors = ['#8E5863', '#BB4800', '#3343A1', '#D0044A', '#CB0084', '#B400C8', '#700050', '#28009A', '#007396', '#06745B', '#2D1432'];

const getMarkerElem = (markerColor: string, isDelivered: Boolean) => {
  const option = {
    className: 'marker',
    backgroundColor: markerColor,
    opacity: isDelivered ? '0.5' : '1'
  };
  return createMarkerElement(option);
}

const generateRandomColor = () => {
  let randomColor;
  do {
    randomColor = `#${Math.floor(Math.random() * 16777215).toString(16).toUpperCase()}`;
  } while (isExcludedColor(randomColor));
  return randomColor;
}

function isExcludedColor(hexColor: string) {
  // Convert the hex color to RGB
  const red = parseInt(hexColor.slice(1, 3), 16);
  const green = parseInt(hexColor.slice(3, 5), 16);
  const blue = parseInt(hexColor.slice(5, 7), 16);
  // Calculate the saturation level of the color
  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);
  const delta = max - min;
  const saturation = delta / max;

  if (saturation < 0.5 && (red >= 100 || green >= 100 || blue >= 100) && !availableColors.includes(hexColor)) // Exclude less saturated, primary colors(red,green,blue) and their variants
  {
    return true;
  }
  return false;
}

const driverInitialsElement = (user: any, selectedColor: string) => {
  const initials = user.username === "DOORDASH" ? 'DD' : `${user.givenName[0]}${user.familyName[0]}`.toUpperCase();
  const elem = document.createElement('div');
  const root = createRoot(elem);
  root.render(
    <div style={{ ...styles.driverInitialMarker, backgroundColor: selectedColor }}>
      {initials}
    </div>
  );
  return elem
}

const getCoordinates = (stop: any): [number, number] => {
  if (stop.invoices) {
    const firstInovice = stop.invoices[0]
    return [firstInovice.longitude, firstInovice.latitude]
  } else {
    return [stop.longitude, stop.latitude]
  }
}

const MapView = ({ activeUsers }: Props) => {
  const mapContainer = useRef() as MutableRefObject<HTMLElement>
  const map = useRef<mapboxgl.Map>()
  const [plottedDriverKeys, setPlottedDriverKeys] = useState(new Set());
  const { storeAddress } = useContext(StoreContext)
  const { currentLanguage } = useContext(LanguageContext)
  const navigate = useNavigate()
  const storeLocation: [number, number] = [storeAddress.longitude as number, storeAddress.latitude as number]
  const plottedMarkers = useRef<mapboxgl.Marker[]>([]);

  useEffect(() => {
    if (map.current) return
    const [usCenterLng, usCenterLat] = centerLngLat
    map.current = new mapboxgl.Map({
      container: mapContainer.current as HTMLElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: (storeLocation) ? storeLocation : [usCenterLng, usCenterLat],
      zoom: 10
    })
    const navControl = new mapboxgl.NavigationControl()
    map.current.addControl(navControl, "top-right")
  })

  useEffect(() => {
    plotMapAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUsers, storeAddress]);

  const plotMapAttributes = () => {
    try {
      const storeMarker = addStoreMarker('50px');
      // Clear existing markers when activeUsers change
      plottedMarkers.current.forEach(marker => marker.remove());
      plottedMarkers.current.length = 0;

      const bounds = new mapboxgl.LngLatBounds();
      activeUsers?.forEach((user, index) => {
        const selectedColor = (availableColors.length > index) ? availableColors[index] : generateRandomColor();
        const activeRoutes = user.deliveryRoutes.filter((route: any) => route.routeStatus === 'in-progress')
          // Plot driver initials
          if (user.userLocation) {
            const driverKey = user.username === "DOORDASH" ? 'DD' : `${user.givenName}_${user.familyName}_${index}`;
            const driverLocationTimestamp = user.userLocation?.timestamp;
            const currentTime = new Date().getTime();
            const driverLocations = [user.userLocation.longitude, user.userLocation.latitude] as [number, number];
            if(driverLocations[0] !== 0 || driverLocations[1] !== 0) {
              // if the last updated timestamp is less than 3 minutes ago
              if(currentTime - driverLocationTimestamp < 180000) {
                if (!plottedDriverKeys.has(driverKey)) {
                  const driverInitials = driverInitialsElement(user, selectedColor);
                  if (driverLocations) {
                    const marker = new mapboxgl.Marker(driverInitials).setLngLat(driverLocations).addTo(map.current!);
                    plottedMarkers.current.push(marker);
                    bounds.extend(driverLocations); // Extend bounds with driver locations
                    setPlottedDriverKeys(plottedDriverKeys);
                  }
                }
              }
            }

          // Plot stop markers
          activeRoutes.forEach((route: any) => {
            route.stops.forEach((stop: any) => {
              if (stop.invoices || stop.stopType === 'custom') {
                const coordinate: [number, number] = getCoordinates(stop)
                const isDelivered = stop.deliveryTimestamp != null
                const el = getMarkerElem(selectedColor, isDelivered)
                const marker = new mapboxgl.Marker(el).setLngLat(coordinate).addTo(map.current!)
                plottedMarkers.current.push(marker)
                bounds.extend(coordinate) // Extend bounds with stop coordinates
              }
            })
          })
        }
      });

      // Plot store marker
      if (map.current) {
        storeMarker.setLngLat(storeLocation).addTo(map.current);
        plottedMarkers.current.push(storeMarker);
        bounds.extend(storeLocation); // Extend bounds with store coordinates
      }

      // Fit the map to the bounds
      if (map.current) {
        map.current?.fitBounds(bounds, { padding: 70, duration: 0 });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const DriverIcons = () => {
    // Get today's date
    const today = new Date().toDateString();

    // Filter drivers to only include those with pending routes (assigned drivers only) and valid timestamp matching today's date
    const filteredDriversForToday = activeUsers?.filter(({ deliveryRoutes, username, userLocation }) =>
      deliveryRoutes.some(({ routeStatus }: any) => routeStatus === 'pending') &&
      username !== 'DOORDASH' &&
      userLocation && userLocation.timestamp &&
      new Date(userLocation.timestamp).toDateString() === today
    );

    // Find drivers who are within or outside the pre-defined distance from the store, only for drivers with pending routes
    const MAX_DISTANCE_FROM_STORE = 0.25; // in miles (1/4th)
    const driversWithinDistance = filteredDriversForToday?.filter(user => user.userLocation !== null && user.userLocation !== undefined && geolib.getDistance(user.userLocation, storeLocation) <= MAX_DISTANCE_FROM_STORE);
    const driversOutsideDistance = filteredDriversForToday?.filter(user => user.userLocation !== null && user.userLocation !== undefined && geolib.getDistance(user.userLocation, storeLocation) > MAX_DISTANCE_FROM_STORE);

    // Group drivers by distance
    const driversByDistance = {
      Within: driversWithinDistance,
      Outside: driversOutsideDistance
    };


    return (
      <Box position="absolute" top={0} left={0} padding={2} zIndex={2}>
        <Stack direction="row" spacing={-1}>
          {Object.entries(driversByDistance).map(([distance, drivers]: any) => (
            <AvatarGroup key={distance} max={drivers.length}>
              {drivers.map((user: any, idx: any) => {
                const selectedColor = (distance === 'Within') ? '#001489' : '#565656'; // blue for within distance, grey for outside distance
                const initials = user.username === "DOORDASH" ? 'DD' : `${user.givenName[0]}${user.familyName[0]}`.toUpperCase();
                return (
                  <Avatar
                    key={idx}
                    sx={{ ...styles.driverAvatar, bgcolor: selectedColor }}
                  >
                    {initials}
                  </Avatar>
                );
              })}
            </AvatarGroup>
          ))}
        </Stack>
      </Box>
    );
  };

  return (
    <>
      <div style={styles.mapContainer} ref={mapContainer as LegacyRef<HTMLDivElement>}>
        {activeUsers &&
          <DriverIcons />
        }
      </div>
      {activeUsers && activeUsers.length === 0 &&
        <Box sx={styles.mapOverlay}>
          <Typography color={Colors.white} style={styles.noActiveRoutesText1}>{(language as any)[currentLanguage].noRoutes}</Typography>
          <Typography color={Colors.white} style={styles.noActiveRoutesText2}>Click&nbsp;
            <Link color={Colors.napaBlueLink} href="#" onClick={() => navigate('/RouteBuilder')}>
            {(language as any)[currentLanguage].routeBuilder}
            </Link>&nbsp;{(language as any)[currentLanguage].buildRoute}</Typography>
        </Box>
      }
    </>
  )
}

export { getMarkerElem, generateRandomColor }
export default MapView
