import React, { useContext } from 'react';
import language from '../../../../language/language';
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext';
import { AutoDispatchInfoStyles } from './AutoDispatchInfoStyles';
import { Box, CircularProgress } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/WarningAmber';
import Colors from '../../../../assets/Colors';
import Countdown, {zeroPad} from 'react-countdown'

type AutoDispatchInfoProps = {
  timeElapsed: number;
  isBackGround?: boolean;
  invoiceDateTime: number;
  wasHeld: boolean;
  autodashCancelled: boolean;
};
    
    
type CountdownObject = {
  minutes: number
  seconds: number
  completed: boolean
}

const getColoredBackGround = (isBackGround: boolean, isError: boolean, content: JSX.Element, errorAndIcon = true, wasHeld: boolean, autodashCancelled: boolean) => {
  return isBackGround ? (
    <Box
      sx={
        !autodashCancelled && wasHeld ? AutoDispatchInfoStyles.autoDispatchTimeWarning
          : isError && errorAndIcon
            ? AutoDispatchInfoStyles.autoDispatchTimeError
            : AutoDispatchInfoStyles.autoDispatchTime
      }>
      {errorAndIcon && <FiberManualRecord
        sx={
          !autodashCancelled && wasHeld ? AutoDispatchInfoStyles.autoDispatchInfoIconWarning
            : isError
              ? AutoDispatchInfoStyles.autoDispatchInfoIconError
              : AutoDispatchInfoStyles.autoDispatchInfoIcon
        }
      />}
      {content}
    </Box>
  ) : (
    content
  );
};

export const AutoDispatchInfo = ({ timeElapsed, isBackGround = false, invoiceDateTime, wasHeld, autodashCancelled}: AutoDispatchInfoProps) => {
  const { currentLanguage } = useContext(LanguageContext);
  const oneMin = 60000;
  const sevenMin = 7 * oneMin;
  const timeElapsedInMin = (Date.now() - invoiceDateTime) / oneMin;
  const TimeElapsedInBlue = 6;
  const TimeElapsedInRed = 7;
  const TimeElapsedError = 7.5;
  const deadline = sevenMin + invoiceDateTime;

  const countdownRenderer = ({ minutes, seconds, completed} : CountdownObject) => {
    if (completed) {
      return !isBackGround ? (
        <Box sx={AutoDispatchInfoStyles.startAutoDispatchButton}>
          <CircularProgress size='1rem'/> &nbsp;
          {language[currentLanguage].dispatchingNow}...
        </Box>
      ) : (
        <Box sx={AutoDispatchInfoStyles.startAutoDispatchButton}>
          {language[currentLanguage].dispatchingNow}
        </Box>
      );
    } else {
      return <>
        <Box component="span" marginRight={0}>
          {language[currentLanguage].autoDispatchIn} &nbsp;
        </Box>
        <Box component="span" fontWeight="bold">
          <span style = {{color : (!isBackGround && minutes < 1 ? Colors.errorBgColor : Colors.black)}}>{zeroPad(minutes)}:{zeroPad(seconds)} min</span>
        </Box>
      </>
      
    }
  };

  const getContent = () => {
    if (timeElapsedInMin < TimeElapsedError && !wasHeld && !autodashCancelled) {
      return (
        <Countdown date={deadline} renderer={countdownRenderer}/>
      );
    }

    return (
      <Box
        component="span"
        sx={AutoDispatchInfoStyles.autoDispatchInfoContainer}
        color={isBackGround ? Colors.black : !autodashCancelled && wasHeld ? '#9E6C00' : Colors.errorBgColor}>
        {!isBackGround && <WarningIcon sx={{color: !autodashCancelled && wasHeld ? '#9E6C00' : Colors.errorBgColor}}/>} &nbsp;
        {autodashCancelled
          ? language[currentLanguage].autodashCancelled
          : wasHeld ? language[currentLanguage].actionNeeded : language[currentLanguage].dispatchError}
      </Box>
    );
  };

  const isError = timeElapsedInMin >= TimeElapsedInBlue;
  return getColoredBackGround(isBackGround, isError, getContent(), !(!isBackGround && timeElapsedInMin >= TimeElapsedInRed && timeElapsedInMin <= TimeElapsedError), wasHeld, autodashCancelled);
};
