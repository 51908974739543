import { useContext, useEffect, useRef, useState } from "react"
import language from "../../../language/language"
import { LanguageContext } from "../../../utilities/contexts/LanguageContext"
import { DataGrid } from "@mui/x-data-grid"
import { Box, Chip, Stack, Typography } from "@mui/material"
import { AddressInfo, CustomerDetails, CustomerResponse, formatARNumber, formatPhoneNumber } from "../../../utilities/services/CustomerService/CustomerService"
import { invoiceListStyles } from "../../RouteBuilder/components/InvoiceList/invoiceListStyles"
import Colors from "../../../assets/Colors"
import React from "react"

type CustomerDetailsListProp = {
    page: number
    pageSize: number
    customerDetails: CustomerResponse
    handlePageChange: (event: any) => void,
    handleRowsPerPageChange: (event: any) => void,
    onCustomerDetailsClick: (event: CustomerDetails) => void
}

const CustomerDetailsList = ({page, pageSize, customerDetails, handlePageChange, handleRowsPerPageChange, onCustomerDetailsClick}: CustomerDetailsListProp) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight])
    const { currentLanguage } = useContext(LanguageContext)
    const [tableData, setTableData] = useState<any[]>([]);

    const getColorBasedOnServiceLevel = (serviceLevel: any) => {
        let colorCode = '#ADADAD'
        switch (true) {
          case serviceLevel === 'PLATINUM':
            colorCode = '#D6D6D6'
            break
          case serviceLevel === 'GOLD':
            colorCode = '#FFE395'
            break
          case serviceLevel === 'SILVER':
            colorCode = '#ADADAD'
            break
          case serviceLevel === 'BRONZE':
            colorCode = '#E49600'
            break
          case serviceLevel === 'AUTODASH':
            colorCode = '#fe5600'
            break
        }
        return colorCode
      }

    const columns = [
        {
            field: 'name',
            headerName: `${(language as any)[currentLanguage].nameText}`,
            flex: 1,
            editable: false
        },
        {
            field: 'arNumber',
            headerName: `${(language as any)[currentLanguage].ar}`,
            flex: 1,
            editable: false,
        },
        {
            field: 'phone',
            headerName: `${(language as any)[currentLanguage].phone}`,
            flex: 1,
            editable: false,
        },
        {
            field: 'serviceLevel',
            headerName: `${(language as any)[currentLanguage].priorityInMins}`,
            flex: 1,
            editable: false,
            //no property present in the customer details response to map service level column, populating dummy for now.
            renderCell: (params: any) => {
                return <Chip
                style={{
                  height: '24px',
                  width: '100px',
                  borderRadius: '3px',
                  backgroundColor: getColorBasedOnServiceLevel(params.row.serviceLevel?.toUpperCase()),
                  fontSize: '12px',
                  color: params.row.serviceLevel !== "AUTODASH" ? Colors.napaGrey2 : Colors.white
                }}
                label={
                    params.row.serviceLevel || 'SILVER'
                }
              />
            }
        },
        {
            field: 'address',
            headerName: `${(language as any)[currentLanguage].address}`,
            flex: 1,
            editable: false,
            renderCell: (params: any) => {
                if(!!params.row.address){
                  if (params.row.address.length === 1) {
                    const address: AddressInfo = params.row.address[0] as AddressInfo
                    return <Box display="flex" flexDirection="column" textAlign="left"> 
                      <Typography>{address.addressline1}</Typography> 
                      <Typography>{address.city}, {address.state} {address.zip}</Typography>
                    </Box>
                  }
                  if (params.row.address.length > 1) {
                      return <Typography sx={{color:'blue'}}>Multiple Locations</Typography>
                  }
                  return <Typography sx={{color:'black'}}>Add Location</Typography>
                }
                
                
            }
        }
    ]

    const onRowClick = (event: any) => {
        const data = customerDetails?.commercialCustomerListInfo?.find(customerDetail=>{
            return customerDetail != null && customerDetail?.customerInfo?.customerId === event?.id
        });
        if (data) onCustomerDetailsClick(data)
    }

    useEffect(() => {
        if (!customerDetails) return;
        const _tableData = customerDetails?.commercialCustomerListInfo?.filter(customerDetail=>{
            return customerDetail != null
        }).map(customerDetail=>{
            return {
                id: customerDetail?.customerInfo?.customerId,
                name: customerDetail?.customerInfo?.name,
                arNumber: formatARNumber(customerDetail?.customerInfo?.accountInfo?.[0]?.accountReceivableNumber?.[0]),
                phone: formatPhoneNumber(customerDetail?.customerInfo?.contactInfo?.[0]?.phoneNumber?.[0]+""),
                serviceLevel: customerDetail?.customerInfo?.priority,
                address: customerDetail?.addressInfo
            }
        })
        setTableData(_tableData || []);
    }, [customerDetails]) 

    const rowCountRef = React.useRef(customerDetails?.pageDetailsInfo?.totalRecords || 0);

    const rowCount = React.useMemo(() => {
      if (!!customerDetails?.pageDetailsInfo?.totalRecords) {
        rowCountRef.current = customerDetails.pageDetailsInfo.totalRecords;
      }
      return rowCountRef.current;
    }, [customerDetails?.pageDetailsInfo?.totalRecords]);

    return <DataGrid 
      style={{ height: `${windowSize.current[1] - 200}px` }}
      columns={columns} 
      rows={tableData}
      page={page-1}
      pageSize={pageSize}
      paginationMode="server"
      rowCount={rowCount}
      hideFooterPagination={tableData.length === 0}
      sx={invoiceListStyles.gridSize}
      components={{
          NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {(language as any)[currentLanguage].noResultsCustomerView}
              </Stack>
            )
      }}
      onPageChange={handlePageChange}
      onPageSizeChange={handleRowsPerPageChange}
      onRowClick={onRowClick}
      ></DataGrid>
}

export default CustomerDetailsList