import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { AuthProvider } from './utilities/contexts/AuthContext'
import theme from './theme'
import { ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from './utilities/contexts/SnackbarContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { LanguageProvider } from './utilities/contexts/LanguageContext'
// import { initializeApp } from 'firebase/app';
// import { getMessaging } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";
import { isApac } from './utilities/helpers/RegionHelper'
import ApacNavigator from './components/Navigator/ApacNavigator'
import Navigator from './components/Navigator/Navigator'
import Favicon from './Favicon'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''

const queryClient = new QueryClient()

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_GCP_PROJECT,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSENGER_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
// };

// Initialize Firebase

// const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);
// const messaging = getMessaging(app);

function App() {
  !isApac && Amplify.configure({
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    identityPoolRegion: process.env.REACT_APP_COGNITO_USER_POOL_ID
  })

  return (
    <div className="App">
      <Favicon
        icon={isApac ? '/favicon-gpc.ico' : '/favicon.ico'}
        title={`${isApac ? 'Genuine' : 'PROTracker'} Delivery Dispatcher`}
      />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider>
              <LanguageProvider>
                <AuthProvider>{isApac ? <ApacNavigator /> : <Navigator />}</AuthProvider>
              </LanguageProvider>
            </SnackbarProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
