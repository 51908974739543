import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import Colors from '../../../../assets/Colors'
import { routeListStyles } from './routeListStyles'
import { DeliveryRoute } from '../../../../utilities/types/DeliveryRouteTypes'
import { Box } from '@mui/system'
import { useUsersByStore } from '../../../../queries/Users/useUsersByStore'
import { invoiceListStyles } from '../InvoiceList/invoiceListStyles'
import { DataGrid, GridToolbarQuickFilter, gridVisibleSortedRowIdsSelector } from '@mui/x-data-grid'
import { getFormattedDate } from '../../../../utilities/helpers/DateTimeFormatters'
import { RouteBuilderContext, RouteBuilderTab } from '../../RouteBuilder'
import doordash_image from '../../../../assets/doordash_modal.png'
import { customQuickSort } from '../../../../utilities/helpers/customQuickSort'
import language from '../../../../language/language'
import { LanguageContext } from '../../../../utilities/contexts/LanguageContext'
import { DeliveryRouteService } from '../../../../utilities/services/DeliveryRouteService'
import { StoreContext } from '../../../../utilities/contexts/StoreContext'
import CachedIcon from '@mui/icons-material/Cached'
import DateFilter from '../DateFilter/DateFilter'
import {makeEndDate, makeStartDate} from "../DateFilter/Dates";

type RouteListProps = {
  routes: DeliveryRoute[] | undefined
  isLoadingRoutes: boolean
  setEditedRouteData: Dispatch<SetStateAction<DeliveryRoute | null>>
  setSelectedDriverId: (selectedDriver: string) => void
  setRoute: Dispatch<SetStateAction<DeliveryRoute | null>>
  setCompletedRouteData: (completedRouteData: DeliveryRoute[], routeStatus: string) => void
  setOpenEditRoute: (openEditRoute: boolean) => void
  setOpenCompletedRoute: (openCompletedRoute: boolean) => void
  startDate: number
  endDate: any
  setStartAndEndDates: any
  setStartDateFilter: (value: number) => void
  setEndDateFilter: (value: number) => void
  routesToggle: boolean
  currentTab: RouteBuilderTab | null
}

export const RouteList = ({
  routes,
  isLoadingRoutes,
  setEditedRouteData,
  setSelectedDriverId,
  setCompletedRouteData,
  setRoute,
  setOpenEditRoute,
  setOpenCompletedRoute,
  startDate,
  endDate,
  setStartAndEndDates,
  routesToggle,
  setStartDateFilter,
  setEndDateFilter,
  currentTab
}: RouteListProps) => {
  const [filteredRows, setFilteredRows] = useState<DeliveryRoute[]>([])
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const { setFilteredRoutes } = useContext(RouteBuilderContext)
  const { data: drivers } = useUsersByStore()
  const { currentLanguage } = useContext(LanguageContext)
  const { autoDispatchEnabled } = useContext(StoreContext)
  const isAutoDispatch = (route: DeliveryRoute) => {
    return (
      !!route.externalDelivery && route.stops?.filter(
        (stop) => stop.invoices?.filter((invoice) => invoice.autoDispatch).length > 0
      ).length > 0
    )
  }

  useEffect(() => {
    if (routes) {
      const sortedArray = customQuickSort(routes)

      if (startDate && endDate) setFilteredRows(sortedArray)
      else setFilteredRows([])
    }
  }, [routes, startDate, endDate])

  const AllRoutesTable: ReactElement = useMemo(() => {
    const onSearch = () => {
      if (startDate && endDate) {
        // startDate is 12:00 AM, endDate is 11:59 PM
        const start = new Date(startDate)
        start.setHours(0, 0, 0, 0)
        const end = new Date(endDate)
        setStartAndEndDates(makeStartDate(start.getTime()), makeEndDate(end.getTime()))
      }
    }

    const resetAll = () => {
      const currentDate = new Date()
      const startDateTimestamp = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000)
      startDateTimestamp.setHours(0, 0, 0, 0)
      setStartAndEndDates(startDateTimestamp.getTime(), makeEndDate(currentDate.getTime()))
    }

    const QuickSearchToolbar = () => {
      const [searchFilter, setSearchFilter] = useState<string>('')
      return (
        <Box sx={{ p: 0.5, pb: 0 }}>
          <Grid container spacing={2}>
            <Grid style={invoiceListStyles.searchBarBox} item md={5}>
              <GridToolbarQuickFilter
                sx={invoiceListStyles.searchBar}
                placeholder={(language as any)[currentLanguage].searchRoute}
                quickFilterParser={(searchInput: string) => {
                  setSearchFilter(searchInput)
                  return searchInput
                    .split(',')
                    .map((value) => value.trim())
                    .filter((value) => value !== '')
                }}
              />
            </Grid>
            <Grid item style={invoiceListStyles.searchBarBox}>
              <DateFilter
                startDate={startDate}
                setStartDateFilter={setStartDateFilter}
                endDate={endDate}
                setEndDateFilter={setEndDateFilter}
                onSearch={onSearch}
                resetAll={resetAll}
              />
            </Grid>
          </Grid>
          <Divider />
          <Divider />
          {searchFilter ? (
            <Box style={invoiceListStyles.searchResultsBox}>
              <Typography style={invoiceListStyles.searchResultsFont}>
                {(language as any)[currentLanguage].result} "{searchFilter}"
              </Typography>
            </Box>
          ) : null}
        </Box>
      )
    }

    let columns = [
      {
        field: 'routeId',
        headerName: (language as any)[currentLanguage].routeNumber.toUpperCase(),
        minWidth: 250,
        editable: false,
        valueFormatter: (params: any) => {
          return `#${params.value}`
        }
      },
      {
        field: 'driver',
        headerName: (language as any)[currentLanguage].driver.toUpperCase(),
        minWidth: 250,
        editable: false,
        renderCell: (params: any) => {
          if (params.row.driver.includes('DOORDASH'))
            return <img src={doordash_image} alt="" width="124px" height="24px" />
          else return params.row.driver
        }
      },
      {
        field: 'lastUpdated',
        headerName: (language as any)[currentLanguage].lastUpdatedHeader,
        type: 'date',
        filterable: false,
        valueFormatter: (params: any) => {
          return getFormattedDate(params.value)
        },
        minWidth: 250,
        editable: false
      },
      {
        field: 'status',
        headerName: (language as any)[currentLanguage].status.toUpperCase(),
        valueFormatter: (params: any) => {
          return params.value?.includes('Delayed') ? 'Delayed' : params.value?.slice(2)
        },
        minWidth: 250,
        editable: false
      },
      {
        field: 'autoDispatchTime',
        headerName: (language as any)[currentLanguage].autoDispatching,
        renderCell: (params: any) => {
          return params.row.autoDispatch ? renderAutoDispatchedColumn() : ''
        },
        filterable: false,
        minWidth: 250,
        editable: false
      },
      {
        field: 'date',
        headerName: (language as any)[currentLanguage].dateText,
        minWidth: 250,
        filterable: false,
        editable: false
      },
      {
        field: 'customer',
        headerName: (language as any)[currentLanguage].customerText,
        minWidth: 250,
        editable: false
      },
      {
        field: 'invoiceNumber',
        headerName: 'INVOICE NUMBER',
        minWidth: 250,
        editable: false
      },
      {
        field: 'partNumber',
        headerName: 'PART NUMBER',
        minWidth: 250,
        editable: false
      },
      {
        field: 'edit',
        headerName: '',
        minWidth: 250,
        editable: false,
        filterable: false,
        renderCell: (params: any) => {
          return params.row.status === 'B-Pending' || params.row.status === 'D-Created'? (
            <Button
              sx={routeListStyles.editRouteButton}
              onClick={async () => {
                setOpenEditRoute(true)
                const routeData = routes?.filter(
                  (route: any) => route.routeId === params.row.routeId
                )
                if (routeData) {
                  if (routeData[0].deliveryDriver === null) {
                    setSelectedDriverId('None')
                  } else {
                    setSelectedDriverId(routeData[0].deliveryDriver)
                  }
                  setEditedRouteData(routeData[0])
                  setRoute(routeData[0])
                }
              }}>
              {(language as any)[currentLanguage].editRoute}
            </Button>
          ) : params.row.status === 'C-Delivered' ||
            params.row.status === 'Delayed' || params.row.status === 'A-En Route' ? (
            <Button
              sx={{ textTransform: 'none', color: Colors.napaBlueLink }}
              onClick={async () => {
                const routeData = await DeliveryRouteService.getRouteByRouteId(params.row.routeId)
                // Moved complete Modal to open after the route data is set
                if (routeData) {
                  setCompletedRouteData([routeData], params.row.status)
                  setOpenCompletedRoute(true)
                }
              }}>
              {(language as any)[currentLanguage].viewDetails}
            </Button>
          ) : null
        }
      }
    ]

    if (!autoDispatchEnabled || !routesToggle) {
      columns = columns.filter((col) => col.field !== 'autoDispatchTime')
    }

    const getDriverName = (deliveryDriver: any) => {
      const driver = drivers?.filter((driver) => driver.username === deliveryDriver)
      if (driver && driver.length > 0) {
        if (driver[0].givenName && driver[0].familyName) {
          const fullName = driver[0].givenName + ' ' + driver[0].familyName
          return fullName
        }
      }
      if (deliveryDriver === 'DOORDASH') return deliveryDriver
      return ''
    }

    const orderStatus = (status: string) => {
      switch (status) {
        case 'pending':
          return 'B-Pending'
        case 'in-progress':
          return 'A-En Route'
        case 'complete':
          return 'C-Delivered'
        case 'cancelled':
          return 'E-Cancelled'
        case 'created':
          return 'D-Created'
      }
    }

    const searchAllStops = (routeStops: any) => {
      let concat = ''
      routeStops?.map((stop: any) => {
        let deliveryAddress = stop.invoices && stop?.invoices[0]?.deliveryAddress?.name
        return (concat = concat + ' ' + deliveryAddress)
      })
      return concat
    }

    const getAllInvoices = (route: any) => {
      return route
        .map((item: any) => item.invoices?.map((invoice: any) => invoice.invoiceNumber + ' '))
        .join('')
    }

    const getAllParts = (route: any) => {
      return route
        .map((item: any) =>
          item.invoices?.map((invoice: any) =>
            invoice.parts.map((part: any) => part.partNumber + ' ')
          )
        )
        .join('')
    }

    const renderRouteTable = () => {
      return (
        <DataGrid
          style={{ height: `${windowSize.current[1] - 100}px` }}
          components={{
            Toolbar: QuickSearchToolbar,
            NoRowsOverlay: () => (
              <Grid md={12} marginTop={2} item>
                <Card sx={routeListStyles.noRoutesCard}>
                  <CardContent>
                    <div style={routeListStyles.noRoutesCardContent}>
                      <Typography sx={routeListStyles.noRoutesBold}>
                        {(language as any)[currentLanguage].selectRange1}
                      </Typography>
                      <Typography sx={routeListStyles.noRoutes}>
                        {(language as any)[currentLanguage].selectRange2}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {(language as any)[currentLanguage].noResults}
              </Stack>
            )
          }}
          disableColumnSelector={true}
          onStateChange={(state) => {
            const newRows = gridVisibleSortedRowIdsSelector(state)
            setFilteredRoutes(newRows)
          }}
          rows={
            filteredRows
              // Filters Routes that have stops with invoices or external deliveries
              ?.filter(
                (route) =>
                  route.routeStatus !== 'cancelled' &&
                  (route.externalDelivery ||
                    (route.stops?.length && route.stops?.some(stop => stop?.invoices?.length > 0)) || 
                    (route.stops?.length && route.stops?.some(stop => stop?.stopType === 'custom')) ||
                    (route.stops?.length && route.stops?.some(stop => stop?.stopType === 'store_transfer')))
              )
              .map((route) => {
                const row = {
                  id: route.routeId,
                  routeId: route.routeId,
                  driver: getDriverName(route.deliveryDriver),
                  lastUpdated:
                    route.routeStatus === 'complete'
                      ? route.routeCompletionTimestamp
                      : route.routeCreationTimestamp,
                  status:
                    route.routeStatus === 'in-progress' && route.stops.some((stop) => stop.delay)
                      ? 'Delayed'
                      : orderStatus(route.routeStatus),
                  edit: '',
                  date: getFormattedDate(route.routeCreationTimestamp),
                  customer: searchAllStops(route.stops),
                  invoiceNumber: getAllInvoices(route.stops),
                  partNumber: getAllParts(route.stops),
                  autoDispatch: isAutoDispatch(route)
                }
                return row
              }) || []
          }
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                date: false,
                customer: false,
                invoiceNumber: false,
                partNumber: false
              }
            }
          }}
          hideFooterSelectedRowCount={true}
          disableVirtualization={process.env.NODE_ENV === 'test'}
          sx={invoiceListStyles.gridSize}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            }
          }}
        />
      )
    }
    return renderRouteTable()
    // eslint-disable-next-line
  }, [
    filteredRows,
    setFilteredRoutes,
    drivers,
    routes,
    setEditedRouteData,
    setSelectedDriverId,
    setCompletedRouteData,
    setOpenCompletedRoute,
    setOpenEditRoute,
    currentLanguage,
    startDate,
    endDate
  ])

  const renderAutoDispatchedColumn = () => {
    return (
      <Typography sx={invoiceListStyles.autoDispatch}>
        {currentTab === 'routes' && (
          <Typography data-testid="autodash" sx={invoiceListStyles.autoDispatchText}>
            <CachedIcon data-testid="CachedIcon" />
            <Typography sx={invoiceListStyles.autoDispatchColumnText}>
              {(language as any)[currentLanguage].autoDispatchColumn}
            </Typography>
          </Typography>
        )}
      </Typography>
    )
  }

  const RoutesSkeleton = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {[...Array(3)].map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant="text" animation="wave" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(3)].map((_, index) => (
              <TableRow key={index}>
                {[...Array(3)].map((_, idx) => (
                  <TableCell key={idx}>
                    <Skeleton variant="text" animation="wave" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Grid item container>
      {isLoadingRoutes ? <RoutesSkeleton /> : AllRoutesTable}
    </Grid>
  )
}
