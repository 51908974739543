import Colors from '../../../../assets/Colors'

export const editRouteStyles = {
  routeReviewTypography: {
    color: Colors.napaBlue,
    fontWeight: '700',
    fontSize: '22px',
    textAlign: 'center',
    fontFamily: 'NAPA SANS',
    display: 'flex',
    padding: '0px 10px 10px'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-10px',
    padding: '16px 10px 0px'
  },
  totalStops: {
    color: Colors.napaBlack,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'NAPA SANS',
    marginTop: '-30px'
  },
  invoiceDeliveryAddress: {
    color: Colors.napaBlue,
    fontWeight: '400',
    fontSize: '16px',
    fontFamily: 'NAPA SANS'
  },
  renderInvoiceBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '12px'
  },
  removeInvoiceType: {
    color: Colors.napaBlueLink,
    fontSize: '14px',
    paddingRight: '3px',
    marginTop: '2px',
    textTransform: 'none'
  },
  driversFormGrid: {
    display: 'flex'
  },
  driversForm: {
    m: 1,
    width: {
      xs: '120px',
      sm: '160px',
      md: '370px'
    },
    top: '-8px'
  },
  driverType: {
    color: Colors.black,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'NAPA SANS',
    paddingBottom: '5px'
  },
  headerTitle: {
    color: Colors.black,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  invoiceAddressLineType: {
    color: Colors.napaGrey,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  renderInvoice: {
    border: '1px solid rgb(193,193,193)',
    paddingLeft: 10,
    marginBottom: '25px',
    padding: '6px',
    marginLeft: '-14px',
    marginRight: '-14px',
    marginTop: '-18px'
  },
  returnStore: {
    background: Colors.napaGreyDisabled,
    padding: '6px',
    border: '1px solid rgb(193,193,193)',
    marginLeft: '-14px',
    marginRight: '-14px',
    marginTop: '-24px'
  },
  travelTime: {
    color: Colors.napaBlack,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'NAPA SANS'
  },
  dialogActions: {
    padding: '16px',
    marginTop: '-20px'
  },
  launchButton: { 
    width: '45%', 
    marginRight: '8px', 
    '&:disabled': {
      borderColor: '#f4f4f4',
    },
  },
  editButton: {
    color: Colors.napaBlueLink,
    textTransform: 'none'
  },
  removeButtonBox: {
    display: 'flex',
    justifyContent: 'row',
    paddingRight: '10px'
  },
  invoiceNumber: {
    color: Colors.napaGrey2,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  RouteMapPreview: {
    top: '0px',
    display: 'flex',
    padding: '10px'
  },
  notesTitle: {
    color: Colors.black,
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'NAPA SANS'
  },
  stickyBottom: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    padding: '10px'
  },
  renderAddressBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  accordionSummary: {
    height: '45px',
    padding: '0 8px'
  },
  downIcon: {
    color: Colors.white
  },
  invoiceText: {
    color: Colors.napaBlue,
    fontSize: '14px',
    fontWeight: '400'
  },
  invoiceMenu:
  {
    width: '20%',
    height: 'auto',
    padding: '3px 0',
    
    marginTop: '-35px'
  },
  marginR: {
    marginRight: '5px'
  },
  locationInfo: {
    paddingTop: '8px'
  },
  locationSection: {
    paddingBottom: '8px'
  },
  locationSectionHeader: {
    fontSize: '12px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    paddingBottom: '4px'
  },
  locationSectionText: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#707070',
    paddingLeft: '8px'
  },
  redPin: {
    width: '18px',
    height: '18px'
  },
  storePadding: {
    marginLeft: '25px',
    padding: '0px 0px 8px 0px'
  },
  storeContainer: {
    padding: '8px 0px 0px'
  }
}
